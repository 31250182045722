import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
// import founderimage from '../assets/img/found-image.png';
import founderimage from '../assets/img/ivd-foundar.webp';
// import facebookimg from '../assets/img/facebook-icon.png';
// import twitterimg from '../assets/img/twitter-icon.png';
import calenclock from '../assets/img/calen-icon.png';
import linkedinimg from '../assets/img/linkedin-img.png';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
// import founderimagess from '../assets/img/team-person.png';
import ankitaImg from '../assets/img/ankita-img_nn.webp';
// import teampersonone from '../assets/img/team-person-one.png';
import archanaImg from '../assets/img/archana-img_fin.webp';
import tanishkaImg from '../assets/img/tanishka-img.webp';
import abhishekImg from '../assets/img/abhishek-img.webp';
import samratImg from '../assets/img/samrat.webp';
import dhanjitImg from '../assets/img/dhanjit-img.webp';
import prakashImg from '../assets/img/prakash.webp';
import akshatImg from '../assets/img/akshat.webp';
import teamvideo from '../assets/video/team_video.mp4';
import timelineone from '../assets/img/timeline-img-icon/2013.png'
import timelinetwo from '../assets/img/timeline-img-icon/2014.png'
import timelinethree from '../assets/img/timeline-img-icon/2015.png'
import timelinefour from '../assets/img/timeline-img-icon/2016.png'
import timelinefive from '../assets/img/timeline-img-icon/2017.png'
import timelinesix from '../assets/img/timeline-img-icon/2018.png'
import timelineseven from '../assets/img/timeline-img-icon/2019.png'

const About = () => {
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div class="about-main">
          {/* <div class="about-sec-banner" title="About Us Banner" ></div>
       */}

          <div className="video-container">
            <video autoPlay loop muted className="background-video">
              <source src={teamvideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="container">
            <div className="abut-banner-cont">
              <h1>About Us</h1>
              <p className="head-para">
                With the prompt transition over 12 strong years, we focused on our
                original methodologies from the genesis to deliver simpler yet
                impactful digital solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* timeless innovation section start here */}
      <div className="home-service-sec about-innov-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head about-innov-sec main-difeer-about">
                <p className="short-head-line">Timeless Innovation</p>
                <h2>A Decade in the Making of Ivdisplays</h2>
                <p className="about-innov-para">
                  We evolve continuously and are driven by digital initiatives; with
                  our collaborative team, we ensure
                  <span className="schol-name">school success</span> is distinctive
                  in the digital realm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* timeless innovation end  */}

      {/* founder section start here */}
      <div className="founder-sec">
        <div className="bg-icon-belowservice founsd-bg">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="founder-img">
                <img src={founderimage} alt="Founder" />

                {/* <div className="soco-icon">
                  <ul class="social-icon social-icon-found">
                    <li>
                      <a href="#">
                        <img src={facebookimg} />
                      </a>
                    </li>

                    <li>
                      <a href="#">
                        <img src={twitterimg} className="twit-sec-foun" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-md-7">
              <div class="vision-content founder-sec-cont">
                <h2>Introducing Our Director & Founder</h2>
                <p>
                  An entrepreneurial powerhouse who brings 3 decades of experience.
                  He has one successful exit to his name, he is a transformational
                  force driving the unprecedented growth of our current business.
                  His visionary leadership places employees and customers at the
                  center of his strategy.
                </p>
                <h3>VINAY MENON</h3>
                <h4>
                  <span>Explore his</span>
                  <a href="#" className="found-link">
                    <img src={linkedinimg} />
                  </a>
                </h4>
                <p className="book-sec">
                  Better yet, connect with him directly!
                  <span>
                    <img src={calenclock} />
                    <span className="book-label">Book an Appointment</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* founder section end here */}

      {/* our team section form here */}
      <div className="home-service-sec team-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head team-sec-content team-newssss">
                <p className="short-head-line">Our Team</p>
                <h2>A Person Behind Every Product</h2>
                <p className="meet-pata meta-patahhh">
                  Meet the collaborative, diversified talents inspired by the power
                  of software, who are ultimately propelling Ivdisplay’s vision
                  consistently.
                </p>
              </div>
            </div>
          </div>
          <div className="team-category-sec">
            <div className="row">
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={ankitaImg} />
                      </a>
                      <h3>Ankita Das</h3>
                      <h4>B.Tech</h4>
                      <p>The Web Virtuoso</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>3</h5>
                    <h6>Years Strong</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={archanaImg} />
                      </a>
                      <h3>Archana Kumari</h3>
                      <h4>B.Tech</h4>
                      <p>The Design Innovator</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>4</h5>
                    <h6>Years Brilliance</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={tanishkaImg} />
                      </a>
                      <h3>Tanishka Menon</h3>
                      <h4>B.Sc</h4>
                      <p>The Tech Explorer</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>4</h5>
                    <h6>Years Discovery</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={abhishekImg} />
                      </a>
                      <h3>Abhishek Mitra</h3>
                      <h4>M.Tech</h4>
                      <p>The Cloud Explorer</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>1</h5>
                    <h6>Year & Counting</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={samratImg} />
                      </a>
                      <h3>Samrat Paul</h3>
                      <h4>B.Com</h4>
                      <p>The Financial Architect</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>2</h5>
                    <h6>Years & Counting</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={dhanjitImg} />
                      </a>
                      <h3>Dhanjit Kumar</h3>
                      <h4>B.Tech</h4>
                      <p>The Creative Coder</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>1</h5>
                    <h6>Year & Counting</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={prakashImg} />
                      </a>
                      <h3>Prakash Raj</h3>
                      <h4>Mass Comm</h4>
                      <p>The Digital Storyteller</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>1</h5>
                    <h6>Year & Counting</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={akshatImg} />
                      </a>
                      <h3>Akshat Kurup</h3>
                      <h4>BBA</h4>
                      <p>Jr BI Engineer</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>1</h5>
                    <h6>Year & Counting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* our team section end */}

      {/* timeline section start */}
      <div className="home-service-sec timeline-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">
                <p className="short-head-line">Our Timeline</p>
                <h2>Over a decade of experience in technology innovation </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <section class="ps-timeline-sec">
                <div class="container">
                  <ol class="ps-timeline">
                    <li>
                      <div class="ps-bot">
                        <p>The inception of our Vision. </p>
                      </div>
                      <div className="img-time img-time-first ps-sp-bot">
                        <img src={timelineone} />
                        <p className="timedata">2013</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>Our Cloud Evolution </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinetwo} />
                        <p className="timedata">2015</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>Our Education Partnership</p>
                      </div>
                      <div className="img-time  ps-sp-bot">
                        <img src={timelinethree} />
                        <p className="timedata">2016</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>Our Recognized Excellence </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinefour} />
                        <p className="timedata">2019</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>Our Ten Projects with One Vision</p>
                      </div>
                      <div className="img-time ps-sp-bot">
                        <img src={timelinefive} />
                        <p className="timedata">2021</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>A Decade of Excellence </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinesix} />
                        <p className="timedata">2023</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>
                          Charting New Horizons: With Digital Evolution for Schools
                        </p>
                      </div>
                      <div className="img-time img-last ps-sp-bot">
                        <img src={timelineseven} />
                        <p className="timedata">2024</p>
                      </div>
                    </li>
                  </ol>
                </div>
              </section>
            </div>
          </div>

        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* timeline section end */}
      <Footer />
    </>
  )
}

export default About;