import React, {useState} from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import prductimg from '../assets/img/eform-img.jpg';
import EastIcon from '@mui/icons-material/East';
import VideocamIcon from '@mui/icons-material/Videocam';
import workshopimg from '../assets/img/workshop-img.png';
import absenceimg from  '../assets/img/absence-reporting-img.png';
import inventoryimg from '../assets/img/inventory-img.png';
import { Link } from "react-router-dom";

import { useTransition, animated } from '@react-spring/web'
import { styled } from '@stitches/react'
import * as Dialog from '@radix-ui/react-dialog';
import CloseIcon from '@mui/icons-material/Close';
import bannerimg from '../assets/img/new-img-ivd/product-img.png';




const TriggerPart = styled('span', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 8,
})

const TriggerShadow = styled(TriggerPart, {
  background: 'hsl(0deg 0% 0% / 0.1)',
  transform: 'translateY(2px)',
  transition: 'transform 250ms ease-out',
})

const TriggerEdge = styled(TriggerPart, {
  background: `linear-gradient(
      to left,
      hsl(0deg 0% 69%) 0%,
      hsl(0deg 0% 85%) 8%,
      hsl(0deg 0% 85%) 92%,
      hsl(0deg 0% 69%) 100%
    )`,
})

const TriggerLabel = styled('span', {
  display: 'block',
  position: 'relative',
  borderRadius: 8,
  color: '#569AFF',
  fontSize: '14px',
  padding: '16px 24px',
  background: '#fafafa',
  transform: 'translateY(-4px)',
  width: '100%',
  userSelect: 'none',
  transition: 'transform 250ms ease-out',
})

const Trigger = styled(Dialog.Trigger, {
  border: 'none',
  fontWeight: 600,
  cursor: 'pointer',
  background: 'transparent',
  position: 'relative',
  padding: 0,
  cursor: 'pointer',
  transition: 'filter 250ms ease-out',

  '&:hover': {
    filter: 'brightness(110%)',

    [`& ${TriggerLabel}`]: {
      transform: 'translateY(-6px)',
    },

    [`& ${TriggerShadow}`]: {
      transform: 'translateY(4px)',
    },
  },

  '&:active': {
    [`& ${TriggerLabel}`]: {
      transform: 'translateY(-2px)',
      transition: 'transform 34ms',
    },

    [`& ${TriggerShadow}`]: {
      transform: 'translateY(1px)',
      transition: 'transform 34ms',
    },
  },
})

const OverlayBackground = styled(animated(Dialog.Overlay), {
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  pointerEvents: 'all',
  position: 'fixed',
  inset: 0,
})

const Content = styled(animated(Dialog.Content), {
 position: 'absolute',
    /* height: auto; */
    backgroundColor:'#fafafa',
    borderRadius: 8,
    padding: '24px 24px 32px',
    top: '20%',
    width: '100%',
    maxWidth: '740px',
    margin: '0 auto',
    textAlign: 'center',
    transform: 'translate(-50%, -50%) !important',
    left: '50%',
})

const DialogHeader = styled('header', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 16,
})

const CloseButton = styled(Dialog.Close, {
  backgroundColor: 'transparent',
  border: 'none',
  position: 'absolute',
  top: 16,
  right: 16,
  cursor: 'pointer',
  color: '#1B1A22',
})

const Title = styled(Dialog.Title, {
  fontSize: 20,
})


const Products = () => {

  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen)
}


  const transition = useTransition(isOpen, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  })


  return (
    <>
      <div className="main-bg-sec">
    <Header />
      <div className="main-sec main-innerbanner innerbannerimg">
        <div className="container">
          {/* <div className="col-lg-12">
            <div className="banner-sec-content main-banner-sec">
                <h1>Our Products </h1>
                <p>Everything you want in a digital adoption platform for your institution's needs</p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-6">
            <div className="banner-sec-content main-banner-sec new-img-baner ">
                <h1>Our Products  </h1>
                <p>Everything you want in a digital adoption platform for your institution's needs</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="banner-inner-img new-banr-img">
            <img src={bannerimg} alt="homeimg" />
            </div>
            </div>
          </div>
         
        </div>
      </div>
    
   </div>

    {/* product section start */}
        <div className="home-service-sec home-projt-sec product-sec">
          <div className="bg-icon-service">
            <img src={bgimagedots} alt="bg-icon" />
          </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="service-short-head product-head">
                      <p className="short-head-line"> Diversified Digital Innovation</p>
                      <p className="prod-para">Incorporate flexibility in your school processes with our adaptable digital assets</p>
                    </div>
                  </div>
                </div>
                <div className="produt-category">
                  <div className="product-main">
                  <div className="prodct-left">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={absenceimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>AI-Powered Chatbot </h2>
                              <p>Interact efficiently across your school community with our high-quality AI chatbot.

                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                              <Link to="/productdetails/ai-powered-chatbot" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="prodct-right">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={prductimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Enterprise Resource Planning </h2>
                              <p>Automate your primary processes in schools with our ERP System

                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                              <Link to="/productdetails/enterprise-resource-planning" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>
                              {/* <Dialog.Root open={isOpen} onOpenChange={handleDialogChange} >
                                                            <Trigger className="dialogfilter">
                                                            <TriggerLabel>  */}
                              <Dialog.Root open={isOpen} onOpenChange={handleDialogChange} >
                              <Trigger className="dialogmain">
                                  <div className="button-sec btn-align btn-video"  >
                                    <a href="#" class="btn-inner-sec btn-inner-vido" >
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                              </Trigger>
                              </Dialog.Root>
                              {/* </TriggerLabel>
                              </Trigger>
                                                              </Dialog.Root> */}
                            </div>
                            </div>
                    </div>
                    </div>
                   
                  </div>

                  <div className="product-main prodct-margin">
                  <div className="prodct-left ">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={workshopimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Workshop Management System</h2>
                              <p>Optimize all your training tasks in your school community through one centralized platform in no time.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <Link to="/productdetails/workshop-management-system" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="prodct-right">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={absenceimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Absence Reporting Portal</h2>
                              <p>Streamline all leave processes for the 
                                school staff in one digital portal.

                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                              <Link to="/productdetails/absence-reporting-portal" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                 
                  </div>

                  <div className="product-main prodct-margin">
                  <div className="prodct-left">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={inventoryimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Inventory Management System</h2>
                              <p>Reduce the complexity of misplaced assets in the school setup with one solution.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                              <Link to="/productdetails/inventory-management-system" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    </div>


                  
                </div>
              </div>
            <div className="bg-icon-belowservice">
              <img src={bgimagedots} alt="bg-icon" />
            </div>
        </div>
    {/* product section end */}

      <Footer />


       {/* modal section start */}
<Dialog.Root open={isOpen} onOpenChange={handleDialogChange}>
      <Dialog.Portal forceMount>
        {transition((style, isOpen) => (
          <>
            {isOpen ? (
              <OverlayBackground style={{ opacity: style.opacity }} />
            ) : null}
            {isOpen ? (
              <Content forceMount style={style}>
                <DialogHeader>
                  <CloseButton>
                    <CloseIcon />
                  </CloseButton>
                </DialogHeader>
                <Title>Watch Video</Title>
              </Content>
            ) : null}
          </>
        ))}
      </Dialog.Portal>
      </Dialog.Root>
      {/* modal section end */}
    </>
  )
}

export default Products;
