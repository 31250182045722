import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import facebookimg from '../assets/img/facebook.png';
import twitterimg from '../assets/img/twitter.png';
import linkedinimg from '../assets/img/linkedin.png';

import imgsec from '../assets/img/img.png';
import EastIcon from '@mui/icons-material/East';

import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import { useSelector } from "react-redux";


import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';


import CloseIcon from '@mui/icons-material/Close';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addContact,contactpupdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Contact = () => {
  const { contactData } = useSelector((state) => state.pageContents);
  console.log(contactData);
  const [otpid, setotpid] = React.useState(null);
  const recaptchaRef = React.useRef();
  const formFields = {
    name: '',
    email: '',
    phone: '',
    // subject: '',
    message: '',
    city: '',
    recaptcha: ''
  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [pageselect, setPageselect] = React.useState('');
  let navigate = useNavigate();
    const [openotp, setOpenotp] = React.useState(false);
  
    const handleClickOpenotp = () => {
      setOpenotp(true);
    };
    const handleCloseotp = () => {
      setOpenotp(false);
    };
  
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == 'message' && value.length > 500) {
      return false;

    } else {
      setFormValues({ ...formValues, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      addContact(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            setotpid(response.data.lastid);
            recaptchaRef.current.reset();
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setOpenotp(true);
            // setTimeout(() => {
            //   setOpen(false);
            // }, 3000);
            // setOpen(true);
            // setTimeout(() => {
            //     setOpen(false);
            // }, 2000);

          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'city') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      }
    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    setFormErrors(errors);
    return isValid;
  }

  // modal section start

  const [open, setOpen] = React.useState(false);



  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // modal section end
  const handleregis = () => {
    navigate(`/register`);
  }

   const [otpIn, setOtpin] = useState({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
    const inputEventOtpIn = (event) => {
      console.log(event);
      setOtpin((preValue) => {
  
        if (event.target.name === 'otpIn_first') {
  
          return {
            otpIn_first: event.target.value,
            otpIn_secon: preValue.otpIn_secon,
            otpIn_thir: preValue.otpIn_thir,
            otpIn_forth: preValue.otpIn_forth,
            otpIn_fifth: preValue.otpIn_fifth,
            otpIn_six: preValue.otpIn_six,
            error_first: 0,
            error_secon: preValue.error_secon,
            error_thir: preValue.error_thir,
            error_forth: preValue.error_forth,
            error_fifth: preValue.error_fifth,
            error_six: preValue.error_six,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
  
        }
  
        if (event.target.name === 'otpIn_secon') {
  
          return {
            otpIn_first: preValue.otpIn_first,
            otpIn_secon: event.target.value,
            otpIn_thir: preValue.otpIn_thir,
            otpIn_forth: preValue.otpIn_forth,
            otpIn_fifth: preValue.otpIn_fifth,
            otpIn_six: preValue.otpIn_six,
            error_first: preValue.error_first,
            error_secon: 0,
            error_thir: preValue.error_thir,
            error_forth: preValue.error_forth,
            error_fifth: preValue.error_fifth,
            error_six: preValue.error_six,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
  
        }
        if (event.target.name === 'otpIn_thir') {
  
          return {
            otpIn_first: preValue.otpIn_first,
            otpIn_secon: preValue.otpIn_secon,
            otpIn_thir: event.target.value,
            otpIn_forth: preValue.otpIn_forth,
            otpIn_fifth: preValue.otpIn_fifth,
            otpIn_six: preValue.otpIn_six,
            error_first: preValue.error_first,
            error_secon: preValue.error_secon,
            error_thir: 0,
            error_forth: preValue.error_forth,
            error_fifth: preValue.error_fifth,
            error_six: preValue.error_six,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
  
        }
  
        if (event.target.name === 'otpIn_forth') {
  
          return {
            otpIn_first: preValue.otpIn_first,
            otpIn_secon: preValue.otpIn_secon,
            otpIn_thir: preValue.otpIn_thir,
            otpIn_forth: event.target.value,
            otpIn_fifth: preValue.otpIn_fifth,
            otpIn_six: preValue.otpIn_six,
            error_first: preValue.error_first,
            error_secon: preValue.error_secon,
            error_thir: preValue.error_thir,
            error_forth: 0,
            error_fifth: preValue.error_fifth,
            error_six: preValue.error_six,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
  
        }
  
  
  
        if (event.target.name === 'otpIn_fifth') {
  
          return {
            otpIn_first: preValue.otpIn_first,
            otpIn_secon: preValue.otpIn_secon,
            otpIn_thir: preValue.otpIn_thir,
            otpIn_forth: preValue.otpIn_forth,
            otpIn_fifth: event.target.value,
            otpIn_six: preValue.otpIn_six,
            error_first: preValue.error_first,
            error_secon: preValue.error_secon,
            error_thir: preValue.error_thir,
            error_forth: preValue.error_forth,
            error_fifth: 0,
            error_six: preValue.error_six,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
  
        }
        if (event.target.name === 'otpIn_six') {
  
          return {
            otpIn_first: preValue.otpIn_first,
            otpIn_secon: preValue.otpIn_secon,
            otpIn_thir: preValue.otpIn_thir,
            otpIn_forth: preValue.otpIn_forth,
            otpIn_fifth: preValue.otpIn_fifth,
            otpIn_six: event.target.value,
            error_first: preValue.error_first,
            error_secon: preValue.error_secon,
            error_thir: preValue.error_thir,
            error_forth: preValue.error_forth,
            error_fifth: preValue.error_fifth,
            error_six: 0,
            signIn_err: preValue.signIn_err,
            signIn_mesage: '',
            status: 'fail',
            id: preValue.id,
          };
        }
      });
      if (event.target.name === 'otpIn_first') {
  
        document.getElementById('otpIn_secon').focus();
      }
  
      if (event.target.name === 'otpIn_secon') {
  
        document.getElementById('otpIn_thir').focus();
      }
      if (event.target.name === 'otpIn_thir') {
  
  
        document.getElementById('otpIn_forth').focus();
      }
  
      if (event.target.name === 'otpIn_forth') {
  
  
        //   document.getElementById('otpIn_fifth').focus();
      }
  
  
  
      //      if(event.target.name==='otpIn_fifth'){
  
  
      //           document.getElementById('otpIn_six').focus();
      //  }
      console.log(otpIn);
  
    }
  
    React.useEffect(() => {
      if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
        && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
        handleSubmitotp();
      }
  
    }, [otpIn]);
    const handleSubmitotp = (event) => {
  
      setErrorMessage('');
      setSuccessMessage('');
      if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
        || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
        setErrorMessage('Please Enter Valid otp');
        return false;
      }
      var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;
  
      setSubmitting(true);
      contactpupdate({ id: otpid, otp: otp })
        .then(res => {
          console.log(res.data);
  
          if (res.data.status == true) {
            // setOpen(true);
            setOpenotp(false);
            setSubmitting(false);
            // setOpen(true);
            navigate('/thankyou/contact');
            setTimeout(() => {
             
              // setOpen(false);
            }, 3000);
  
  
            if (pageselect == 'getintouch') {
              //    navigate('/thankyou/bookademoproject');
            }
  
  
  
          }
          else {
  
            setErrorMessage(res.data.message);
            setOtpin((preValue) => {
  
              return {
                otpIn_first: '',
                otpIn_secon: '',
                otpIn_thir: '',
                otpIn_forth: '',
                otpIn_fifth: '',
                otpIn_six: '',
                error_first: 0,
                error_secon: 0,
                error_thir: 0,
                error_forth: 0,
                error_fifth: 0,
                error_six: 0,
  
              };
            })
            setSubmitting(false);
          }
        });
    }
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  {
                    contactData?.contactbanner &&
                    <>
                      <h1>{contactData.contactbanner.title} </h1>
                      <p>{contactData.contactbanner.subtitle}</p>
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  {
                    contactData?.contactbanner &&
                    <>
                      <img src={process.env.REACT_APP_IMAGE_URL + contactData.contactbanner.banner_image} alt="homeimg" />
                    </>
                  }

                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  contactData?.contactbanner &&
                  <>
                    <h1>{contactData.contactbanner.title} </h1>
                    <p>{contactData.contactbanner.subtitle}</p>
                  </>
                }
              </div>
            </div> */}

          </div>
        </div>
      </div>

      {/* contact form section start */}
      <div className="contact-sec about-innov-main">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

        <div className="container">
          <div className="contact-inner">
            <div className="row">

              <div className="col-md-5 ">

                <div className="contact-info">
                  <h2>Contact Us</h2>
                  {
                    contactData?.contactDetails &&
                    <>

                      <div className="contact-info-inner">
                        <p>

                          <PlaceIcon />
                          <span>
                            {contactData.contactDetails.address}
                          </span>
                          {/* <span>{contactData.contactDetails.address_sub}</span> */}
                        </p>

                        <p>
                          <CallIcon /> <span>   {contactData.contactDetails.phone} </span>
                        </p>

                        <p>
                          <EmailIcon /> <span>   {contactData.contactDetails.email}</span>
                        </p>
                      </div>
                      <div className="social-icons-contact">
                        <ul className='social-icon'>
                          <li><a href={contactData.contactDetails.facebook} target="_blank"><img src={facebookimg} className='fb-sec' /></a></li>
                          <li><a href={contactData.contactDetails.twitter} target="_blank"><img src={twitterimg} className='twit-sec' /></a></li>
                          <li><a href={contactData.contactDetails.linkdin} target="_blank"><img src={linkedinimg} /></a></li>
                        </ul>
                      </div>
                    </>
                  }
                </div>

              </div>


              <div className="col-md-7">
                <div className="contact-form">
                  <h2>Get In Touch   
                 <p class="content-tol new-cont-secss">
                    <span class="tooltipss home-tool toolcontent" 
                    data-content="Your email id or mobile number will be verified to know you are a human" tabindex="0">
                      <i class="fa-solid fa-info-circle"></i>
                    </span>
                    </p>
              </h2>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Name" variant="outlined"
                              name="name"
                              className={classNames("custom-textfield form-control", { 'is-invalid': formErrors.name })}
                              onChange={handleInputChange}
                              value={formValues.name}

                            />
                            {
                              formErrors.name &&
                              <div className="invalid-feedback">
                                <div className='error-msg'>
                                  {formErrors.name}
                                </div>
                              </div>
                            }
                          </Box>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Phone Number" variant="outlined"
                              name="phone"
                              className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                              onChange={handleInputChange}
                              style={{ backgroundImage: 'none' }}
                              value={formValues.phone}

                            />

                            {
                              formErrors.phone &&
                              <div className="invalid-feedback">
                                <div className='error-msg eror-contact-new'>
                                  {formErrors.phone}
                                </div>
                              </div>
                            }
                                                
              <div className="email-verify-sec cont-new-very cont-newss">
                              <p>This number will be verified</p>
                            </div>
                           
                          </Box>
              
              
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-sec form-msg-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Email Id" variant="outlined"

                              name="email"
                              className={classNames("form-control", { 'is-invalid': formErrors.email })}
                              onChange={handleInputChange}
                              style={{ backgroundImage: 'none' }}
                              value={formValues.email}


                            />
                            {
                              formErrors.email &&
                              <div className="invalid-feedback">
                                <div className='error-msg eror-contact-new'>
                                  {formErrors.email}
                                </div>
                              </div>
                            }
                            <div className="email-verify-sec cont-new-very cont-newss ">
                            <p>This email will be verified</p>
                          </div>
                          </Box>
                        
                          


                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-sec form-msg-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="City" variant="outlined"
                              name="city"
                              className={classNames("form-control", { 'is-invalid': formErrors.city })}
                              onChange={handleInputChange}
                              value={formValues.city}

                            />


                            {
                              formErrors.city &&
                              <div className="invalid-feedback">
                                <div className='error-msg'>
                                  {formErrors.city}
                                </div>
                              </div>
                            }
                          </Box>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-sec form-msg-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="standard-textarea" label="Message" multiline variant="outlined"
                              name="message"
                              className={classNames("form-control", { 'is-invalid': formErrors.message })}
                              onChange={handleInputChange}
                              style={{ backgroundImage: 'none' }}
                              value={formValues.message}
                              rows={4} cols={10}

                            />
                            {
                              formErrors.message &&
                              <div className="invalid-feedback">
                                <div className='error-msg'>
                                  {formErrors.message}

                                </div>
                              </div>
                            }

                            <p className="charcter-sec">
                              {formValues.message.length == 0 && '500 characters remaining'}
                              {formValues.message.length > 0 &&
                                <>

                                  {500 - formValues.message.length} characters remaining
                                </>
                              }
                            </p>
                          </Box>

                        </div>
                      </div>
                    </div>


                    <div class="img-sec">
                      {/* <img src={imgsec} /> */}
                      {/* <img src={captcha_new} alt="captcha" style={{ height: '65px' }} /> */}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                      />
                      {
                        formErrors.recaptcha &&
                        <div className="invalid-feedback" style={{ display: 'block', position: 'relative', top: '5px' }}>
                          <div className='error-msg'>
                            {formErrors.recaptcha}
                          </div>
                        </div>
                      }
                    </div>
                    <div className="button-sec btn-align btn-blog-blue">
                      <a href="#" class="btn-inner-sec" onClick={handleSubmit}>
                        <span className="btn-name btn-sub" >Submit</span>
                        <span className="btn-icon"><EastIcon /></span>
                        {(submitting) && <div className="spinner-border spinner-border-sm margin-load" role="status">

                          <span className="visually-hidden">Loading...</span>
                        </div>}
                      </a>
                    </div>
                  </form>
                  {
                    successMessage &&
                    <div className="success-msg-sec success-left">
                      <p className="succ-msg">{successMessage}
                      </p>
                    </div>
                  }
                  {
                    errorMessage &&
                    <div className="error-msg-sec error-left">
                      <p className="error-msg">{errorMessage}
                      </p>
                    </div>
                  }



                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>



      {/* contact form section end */}

      {/* location section start */}
      <div className="location-sec">
        <h2>Our Location</h2>
        {
          contactData?.contactDetails &&
          <>

            <iframe
              src={contactData.contactDetails.map}
              width="100%"
              height="580"

              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              frameBorder="0"
              title="Google Map"
            ></iframe>

          </>
        }
      </div>
      {/* location section end */}
      <Footer />
      

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
           className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="otp-content-sec">
          <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
          </p>

          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>



            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="newotp-sec thanksuou"
      >

        <DialogContent dividers>



          <div class="success-animation">
            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>

          {/* for animation end */}
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


        </DialogContent >

      </BootstrapDialog >
    </>
  )
}

export default Contact;
