import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import founderimage from '../assets/img/found-image.png';
import facebookimg from '../assets/img/facebook-icon.png';
import twitterimg from '../assets/img/twitter-icon.png';
import calenclock from '../assets/img/calen-icon.png';
import linkedinimg from '../assets/img/linkedin-img-blue.webp';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import founderimagess from '../assets/img/team-person.png';
import teampersonone from '../assets/img/team-person-one.png';
import teamvideo from '../assets/video/team_video.mp4';
import timelineone from '../assets/img/timeline-img-icon/2013.png'
import timelinetwo from '../assets/img/timeline-img-icon/2014.png'
import timelinethree from '../assets/img/timeline-img-icon/2015.png'
import timelinefour from '../assets/img/timeline-img-icon/2016.png'
import timelinefive from '../assets/img/timeline-img-icon/2017.png'
import timelinesix from '../assets/img/timeline-img-icon/2018.png'
import timelineseven from '../assets/img/timeline-img-icon/2019.png'
import { useSelector } from "react-redux";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';



import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import classNames from "classnames";
import TextField from '@mui/material/TextField';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import EastIcon from '@mui/icons-material/East';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import foundimg from '../assets/img/ivd-foundar.webp';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function addZero(i) {
  if (i < 10) { i = "0" + i }
  return i;
}

const About = () => {
  const { aboutData } = useSelector((state) => state.pageContents);
  console.log(aboutData);
  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen)
  }





  const handleSubmitdate = (e) => {
    e.preventDefault();

    let isValid = true;
    let errors = {};

    Object.keys(formValues).forEach((key) => {
      if (formValues.sh_date == '' && key == 'sh_date') {
        isValid = false;
        errors[key] = 'This field is required';
      }

      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    if (isValid == true) {
      setOpendatecl(false);
      setOpendateclock(true);
      // setIsOpen(true);

    }

    setFormErrors(errors);

  }

  const handleSubmitclock = (e) => {
    e.preventDefault();

    let isValid = true;
    let errors = {};
     console.log(formValues);
    Object.keys(formValues).forEach((key) => {
      if (formValues.sh_time == '' && key == 'sh_time') {
        isValid = false;
        errors[key] = 'This field is required';
      }if(formValues.sh_time!='' && key == 'sh_time'){
        var d = new Date(formValues.sh_time_new);
        var hour;
        var hours;
        var mintues;
        hours = d.getHours();
        hour = d.getHours();
        mintues = d.getMinutes();
        console.log(hour);

        hour = hour % 12;
        hour = hour ? hour : 12;
        var reshr=hours >= 12 ? 'PM' : 'AM';
         console.log(hours );
         console.log(mintues );
         console.log(reshr);
          if(reshr=='AM' && hours<10){
            isValid = false;
            errors[key] = 'Please select time between 10 AM to 12 PM and 3 PM to 5 PM';
          }
          else if(reshr=='PM' && hours>17 && mintues>0){
            isValid = false;
            errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
          }
          else if(reshr=='PM' && hours<15 && hours!=12){
            isValid = false;
            errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
          } else if(reshr=='PM' && hours==22){
            isValid = false;
            errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
          } else if(reshr=='PM' && hours==23){
            isValid = false;
            errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
          }
         
         
      }

      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
 
    // return false;
    if (isValid == true) {
      setOpendateclock(false);
      setIsOpen(true);

    }
    console.log(errors);
    setFormErrors(errors);

  }


  const recaptchaRef = React.useRef();
  let navigate = useNavigate();
  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    business_name: '',
    system: '',
    message: '',
    pagename: 'leader',

    sh_date: '',
    sh_time: '',

    sh_date_new: null,
    sh_time_new: null


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);


  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }

  const [opendate, setOpenDate] = useState(false); // State to control the open/close
  const handleIconClick = () => {
    setOpenDate(true); // Open the picker when the icon is clicked
  };

  const handleDate = (e) => {
    let temp = { ...formValues }

    var d = new Date(e);
    var date;
    var month;
    if (d.getDate() < 10) {
      date = "0" + d.getDate();
    }
    else {
      date = d.getDate();
    }

    if ((d.getMonth() + 1) < 10) {
      month = "0" + (d.getMonth() + 1);
    }
    else {
      month = (d.getMonth() + 1);
    }
    var datestring = d.getFullYear() + "-" + month + "-" + date;
    temp.sh_date = datestring;
    temp.sh_date_new = e;

    console.log(datestring);
    setFormErrors({ ...formErrors, ['sh_date']: null });
    setFormValues(temp);
    setOpenDate(false);
  }



  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours = d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);

    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

    console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.message = '';
    casedeat.contact_us = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'leader';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOffChange = (isOpen) => {
    setIsOpen(false)
  }


  const [opendatecl, setOpendatecl] = useState(false)

  const handleOpendateChange = (isOpen) => {
    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));

    setOpendatecl(isOpen)
  }
  const handleDialogOpendateOFFChange = (isOpen) => {
    setOpendatecl(false)
  }
  const [opendateclock, setOpendateclock] = useState(false)

  const handleOpenclockChange = (isOpen) => {
    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));

    setOpendateclock(isOpen)
  }
  const handleDialogOpenclockOFFChange = (isOpen) => {
    setOpendateclock(false)
  }
 // Disable all times except 10-11 AM and 3-5 PM
 const shouldDisableTime = (value, view) => {

  const hour = dayjs(value).hour();
  const minute = dayjs(value).minute();

  if (view === "hours") {
    // Allow only 10 AM - 11 AM, 12 PM (only 12:00 PM), 3 PM - 4 PM, and 5 PM (only 5:00 PM)
    return ![10, 11, 12, 15, 16, 17].includes(hour);
  }

  if (view === "minutes") {
    if (hour === 12 || hour === 17) {
      // Allow only 12:00 PM and 5:00 PM (disable all other minutes for 12 PM and 5 PM)
      return minute !== 0;
    }
  }
  return false;
};
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'sh_date_new' && key != 'sh_time_new' && key != 'contact_us' && key !== 'casestudyname' && key !== 'phone'    && key !== 'business_name' && key !== 'system') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          //  setOpen(true);
          navigate('/thankyou/about');
           setTimeout(() => {
          
            // setOpen(false);
          }, 3000);

          if (pageselect == 'leader') {
            // navigate('/thankyou/talktoleadership');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

  const todaynew = dayjs();


  const minDate = todaynew.add(4, "day");



  return (
    <>

      <div className="main-bg-sec">
        <Header />
        {
          aboutData?.aboutbanner &&
          <div class="about-main">
            {/* <div class="about-sec-banner" title="About Us Banner" ></div>
    */}

            <div className="video-container">
              <video autoPlay loop muted className="background-video">
                <source src={process.env.REACT_APP_IMAGE_URL + aboutData.aboutbanner.banner_image} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>


            <div className="container">
              <div className="abut-banner-cont">
                <h1>{aboutData.aboutbanner.banner_title}</h1>
                <p className="head-para">{aboutData.aboutbanner.banner_title_short}</p>
              </div>
            </div>
          </div>
        }

      </div>

      {/* timeless innovation section start here */}
      {
        aboutData?.aboutintro &&
        <div className="home-service-sec about-innov-main">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="service-short-head about-innov-sec main-difeer-about">
                  <p className="short-head-line">{aboutData.aboutintro.title}</p>
                  <h2>{aboutData.aboutintro.short_des}</h2>
                  <p className="about-innov-para"> {aboutData.aboutintro.des}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      }


      {/* timeless innovation end  */}

      {/* founder section start here */}
      {
        aboutData?.aboutdiintro &&
        <div className="founder-sec">
          <div className="bg-icon-belowservice founsd-bg">
            <img src={bgimagedots} alt="bg-icon" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="founder-img">
                  <img src={process.env.REACT_APP_IMAGE_URL + aboutData.aboutdiintro.image} alt="Founder" />
                  
                  <div className="soco-icon">
                    <ul class="social-icon social-icon-found">
                      {aboutData.aboutdiintro.facebook != '' &&
                        <li><a href={aboutData.aboutdiintro.facebook}><img src={facebookimg} /></a></li>
                      }

                      {aboutData.aboutdiintro.twitter != '' &&
                        <li><a href={aboutData.aboutdiintro.twitter}><img src={twitterimg} className='twit-sec-foun' /></a></li>
                      }

                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div class="vision-content founder-sec-cont">
                  <h2>{aboutData.aboutdiintro.title}</h2>
                  <p>{aboutData.aboutdiintro.short_des}
                  </p>
                  <h3>{aboutData.aboutdiintro.name}</h3>
                  <h4><span>{aboutData.aboutdiintro.explore}</span>
                    {aboutData.aboutdiintro.linkdin != '' &&
                      <a href={aboutData.aboutdiintro.linkdin} className="found-link"><img src={linkedinimg} /></a>
                    }
                  </h4>
                  <p className="book-sec">{aboutData.aboutdiintro.tag}
                    <span onClick={handleOpendateChange} style={{ cursor: 'pointer' }}><img src={calenclock} /><span className="book-label">Book an Appointment</span> </span>


                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>

      }
      {/* founder section end here */}

      {/* our team section form here */}
      <div className="home-service-sec team-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head team-sec-content team-newssss">
                {
                  aboutData?.aboutteaminto &&
                  <>
                    <p className="short-head-line">{aboutData.aboutteaminto.title}</p>
                    <h2>{aboutData.aboutteaminto.sub_title}</h2>
                    <p className="meet-pata meta-patahhh"> {aboutData.aboutteaminto.short_des} </p>
                  </>

                }

              </div>
            </div>
          </div>
          <div className="team-category-sec">
            <div className="row">

              {
                aboutData?.team.length &&
                <>
                  {aboutData.team.map((team, index) => (
                    <div className="col-md-3 team-img-widh">
                      <div className="team-box">
                        <div className="team-sec-back ">
                          <div className="teamimg-sec-main">
                            <a className="teamimg-sec">
                              <img src={process.env.REACT_APP_IMAGE_URL + team.image} /></a>
                            <h3>{team.name}</h3>
                            <h4>{team.qualification}</h4>
                            <p>{team.designation}</p>
                          </div>
                        </div>
                        <div className="teamming-sec-hover">
                          <h5>{team.experinece}</h5>
                          <h6>{team.experinece == 1 ? 'Year' : 'Years'} {team.exptitle}</h6>
                        </div>

                      </div>
                    </div>

                  ))}
                </>

              }



            </div>

            {/* <div className="row">

              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={founderimagess} /></a>
                      <h3>Ankita Das</h3>
                      <h4>B.Tech</h4>
                      <p>The Web Virtuoso</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>3</h5>
                    <h6>Years Strong</h6>
                  </div>

                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={founderimagess} /></a>
                      <h3>Ankita Das</h3>
                      <h4>B.Tech</h4>
                      <p>The Web Virtuoso</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>3</h5>
                    <h6>Years Strong</h6>
                  </div>

                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={founderimagess} /></a>
                      <h3>Ankita Das</h3>
                      <h4>B.Tech</h4>
                      <p>The Web Virtuoso</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>3</h5>
                    <h6>Years Strong</h6>
                  </div>

                </div>
              </div>
              <div className="col-md-3">
                <div className="team-box">
                  <div className="team-sec-back ">
                    <div className="teamimg-sec-main">
                      <a className="teamimg-sec">
                        <img src={founderimagess} /></a>
                      <h3>Ankita Das</h3>
                      <h4>B.Tech</h4>
                      <p>The Web Virtuoso</p>
                    </div>
                  </div>
                  <div className="teamming-sec-hover">
                    <h5>3</h5>
                    <h6>Years Strong</h6>
                  </div>

                </div>
              </div>

            </div> */}


          </div>

        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* our team section end */}



      {/* timeline section start */}
      <div className="home-service-sec timeline-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">
                {
                  aboutData?.abouttimelinintro &&
                  <>
                    <p className="short-head-line">{aboutData.abouttimelinintro.title}</p>
                    <h2>{aboutData.abouttimelinintro.short_des} </h2>
                  </>

                }


              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <section class="ps-timeline-sec">
                <div class="container">
                  <ol class="ps-timeline">
                    {
                      aboutData?.abouttimeline.length &&
                      <>
                        {aboutData.abouttimeline.map((line, index) => (
                          <li>
                            <div class={`${index % 2 == 0 ? 'ps-bot' : 'ps-top'} `}>
                              <p>{line.title} </p>
                            </div>
                            <div className={`img-time ${index == 0 && 'img-time-first'}  ${index == (aboutData.abouttimeline.length - 1) && 'img-last'}   ${index % 2 == 0 ? 'ps-sp-bot' : 'ps-sp-top'} `}>
                              <img src={process.env.REACT_APP_IMAGE_URL + line.icon} />
                              <p className="timedata">{line.year}</p>
                            </div>
                          </li>

                        ))}
                      </>

                    }

                    {/* <li>
                      <div class="ps-bot">
                        <p>The inception of our Vision. </p>
                      </div>
                      <div className="img-time img-time-first ps-sp-bot">
                        <img src={timelineone} />
                        <p className="timedata">2013</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>Our Cloud Evolution </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinetwo} />
                        <p className="timedata">2015</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>Our Education Partnership</p>
                      </div>
                      <div className="img-time  ps-sp-bot">
                        <img src={timelinethree} />
                        <p className="timedata">2016</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>Our Recognized Excellence </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinefour} />
                        <p className="timedata">2019</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>Our Ten Projects with
                          One Vision</p>
                      </div>
                      <div className="img-time ps-sp-bot">
                        <img src={timelinefive} />
                        <p className="timedata">2021</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-top">
                        <p>A Decade of Excellence </p>
                      </div>
                      <div className="img-time ps-sp-top">
                        <img src={timelinesix} />
                        <p className="timedata">2023</p>
                      </div>
                    </li>
                    <li>
                      <div class="ps-bot">
                        <p>Charting New Horizons:
                          With Digital Evolution for All</p>
                      </div>
                      <div className="img-time img-last ps-sp-bot">
                        <img src={timelineseven} />
                        <p className="timedata">2024</p>
                      </div>
                    </li> */}
                  </ol>
                </div>
              </section>
            </div>
          </div>


        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

      </div>

      {/* timeline section end */}



      <Footer />
    
   
      {/* modal section start */}

      <BootstrapDialog
        onClose={handleDialogOpendateOFFChange}
        aria-labelledby="customized-dialog-title"
        open={opendatecl} className="prodt-modl-news about-calen-sec"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOpendateOFFChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="form-sec-main">
            <h2>Select the date</h2>
            <div className='form-sec form-modal date-pick-sec-modl new-modl-calen' >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'MobileDatePicker',
                  ]}
                >

                  <DemoItem >
                    <MobileDatePicker
                      label=" Date"
                      inputFormat="MM/dd/yyyy"
                      value={formValues.sh_date_new}
                      open={opendate} // Control the open state of the picker
                      onClose={() => setOpenDate(false)}
                      onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field

                      onChange={handleDate}
                      minDate={minDate} />
                    <span className="new-calen-icons"><CalendarMonthIcon /></span>
                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
              {
                formErrors.sh_date &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.sh_date}
                  </div>
                </div>
              }
            </div>







          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitdate}>
              <span className="btn-name btn-sub">Next   </span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>


        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleDialogOpenclockOFFChange}
        aria-labelledby="customized-dialog-title"
        open={opendateclock} className="prodt-modl-news about-time-sec"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOpenclockOFFChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="form-sec-main">
            <h2>Select the time slot</h2>
            <div className='form-sec form-modal time-pick-sec-modl new-modl-time' >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'MobileDatePicker',
                  ]}
                >



                  <DemoItem >
                    <MobileTimePicker label="Time Slot"
                      onChange={handleTime}
                   
                      value={formValues.sh_time_new}
                      views={["hours", "minutes"]} // Ensure both hours and minutes are selectable

                      //defaultValue={dayjs('2022-04-17T15:30')}
                      ampm={true}
                      inputFormat="hh:mm A"
                      shouldDisableTime={shouldDisableTime} // Apply time restrictions
                      renderInput={(params) => <TextField {...params} />}
                      

                    />
                    <span className="abut-clock-icon"><AccessTimeIcon /></span>


                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
              {
                formErrors.sh_time &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.sh_time}
                  </div>
                </div>
              }
            </div>







          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitclock}>
              <span className="btn-name btn-sub">Next   </span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>


        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleDialogOffChange}
        aria-labelledby="customized-dialog-title"
        open={isOpen} className="prodt-modl-news about-detail-sec"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOffChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="form-sec-main">
            <h2>Happy to help!</h2>
            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                  name="name"
                  className={classNames("form-control", { 'is-invalid': formErrors.name })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.name}


                />
                {
                  formErrors.name &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.name}
                    </div>
                  </div>
                }
              </Box>
            </div>

            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                  name="email"
                  className={classNames("form-control", { 'is-invalid': formErrors.email })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.email}

                />
                {
                  formErrors.email &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.email}
                    </div>
                  </div>
                }
              </Box>
              <div className="email-verify-sec">
                <p>This email will be verified</p>
              </div>


            </div>

            <div className='form-sec form-modal'>
            <Box
              component="form"
              sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
              noValidate
              autoComplete="off"

            
            >

            <TextField
              id="outlined-multiline-static"
              label="What would you like to discuss?"
              multiline
              rows={4}
              placeholder=" Brief Reason for the appointment"
              name="message"
              className={classNames("form-control", { 'is-invalid': formErrors.message })}
              onChange={handleInputChange}
           
              value={formValues.message}
            />
             {
                  formErrors.message &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.message}
                    </div>
                  </div>
                }
              </Box>
            </div>

            {/* <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                  name="phone"
                  className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.phone}
                />
                {
                  formErrors.phone &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.phone}
                    </div>
                  </div>
                }
                 <div className="email-verify-sec contact-veri">
                <p>This contact will be verified</p>
              </div>
              </Box>



              <div>
                <p class="content-tol abut-inform"><span class="tooltipss" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
              </div>
            </div> */}
          


            {/* <div className='form-sec form-modal form-radio-sec'>



            {formValues.pagename == 'bookdemoproject' &&
                <>
                    <div className='form-sec form-modal date-modal-case data-scroll-modal'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'DatePicker',
                                                        'MobileDatePicker',
                                                        'DesktopDatePicker',
                                                        'StaticDatePicker',
                                                    ]}
                                                >

                                                    <MobileDatePicker
                                                        label="Select Date"
                                                        variant="standard"
                                                        inputFormat="MM/dd/yyyy"
                                                        placeholder="MM/dd/yyyy"
                                                        value={formValues.sh_date_new}
                                                        open={opendate} 
                                                        onClose={() => setOpenDate(false)}
                                                        onOpen={() => setOpenDate(true)} 

                                                        onChange={handleDate}
                                                        minDate={dayjs(formdateto)}
                                                        renderInput={(params) => <TextField {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={handleIconClick}>
                                                                            <CalendarMonthIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}

                                                        />}

                                                    />
                                                    <span className="calend-icon" onClick={handleIconClick}>
                                                        <CalendarMonthIcon /></span>


                                                </DemoContainer>
                                            </LocalizationProvider>

                        {
                            formErrors.sh_date &&
                            <div className="invalid-feedbackcontact">
                                <div className='error-msg'>
                                    {formErrors.sh_date}
                                </div>
                            </div>
                        }

                    </div>

                </>

            }


        </div> */}


            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrors.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrors.recaptcha}
                  </div>
                </div>
              }
            </div>



          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
              <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>

          <div className='validate-sec validate-sec-mode'>
            {
              successMessage &&
              <div className="success-msg-sec">
                <p className="succ-msg">{successMessage}
                </p>
              </div>
            }
            {
              errorMessage &&
              <div className="error-msg-sec">
                <p className="error-msg">{errorMessage}
                </p>
              </div>
            }
          </div>
        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!
          

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
          className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

        <div className="otp-content-sec">
          <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
          </p>
          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>
            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >


      
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="newotp-sec thanksuou"
      >
  
      
        <DialogContent dividers>
        <div class="success-animation">
<svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
</div>
<h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


        </DialogContent >

      </BootstrapDialog >
      {/* modal section end */}

    </>
  )
}

export default About;
