import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import facebookimg from '../assets/img/facebook.png';
import twitterimg from '../assets/img/twitter.png';
import linkedinimg from '../assets/img/linkedin.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import imgsec from '../assets/img/img.png';
import EastIcon from '@mui/icons-material/East';
import bannerimg from '../assets/img/new-img-ivd/contact-final.webp';





const Contact = () => {


  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  <h1>Contact Us</h1>
                  <p>We're Here to Address Your Queries</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">

                  <img src={bannerimg} alt="homeimg" />

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {/* contact form section start */}
      <div className="contact-sec about-innov-main">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

        <div className="container">
          <div className="contact-inner">
            <div className="row">

              <div className="col-md-5 ">

                <div className="contact-info">
                  <h2>Contact Us</h2>


                  <div className="contact-info-inner">
                    <p>
                      <PlaceIcon />
                      <span>
                        Tower 1, 15th Floor,Module - 5B,
                        Bengal Eco Intelligent Park, Block EM, Plot No 3,Sector V, Salt Lake,
                        Kolkata 700091.
                      </span>
                    </p>

                    <p>
                      <CallIcon /> <span> 033-40033398 </span>
                    </p>

                    <p>
                      <EmailIcon /> <span> hr@ivdisplays.com </span>
                    </p>
                  </div>
                  <div className="social-icons-contact">
                    <ul className='social-icon'>
                      <li><img src={facebookimg} className='fb-sec' /></li>
                      <li><img src={twitterimg} className='twit-sec' /></li>
                      <li><img src={linkedinimg} /></li>
                    </ul>
                  </div>

                </div>

              </div>


              <div className="col-md-7">
                <div className="contact-form">
                  <h2>Get In Touch</h2>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Name" variant="outlined" className="custom-textfield" />
                          </Box>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Phone Number" variant="outlined" />
                          </Box>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="Email Id" variant="outlined" />
                          </Box>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="outlined-basic" label="City" variant="outlined" />
                          </Box>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-sec">
                          <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 1, width: '100%' } }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField id="standard-textarea" label="Message" multiline variant="outlined" />
                          </Box>
                        </div>
                      </div>
                    </div>

                  </form>
                  <div class="img-sec">
                    <img src={imgsec} />
                  </div>
                  <div className="button-sec btn-align btn-blog-blue">
                    <a href="#" class="btn-inner-sec">
                      <span className="btn-name btn-sub">Submit</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>



      {/* contact form section end */}

      {/* location section start */}
      <div className="location-sec">
        <h2>Our Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.1014898156172!2d88.42477721495963!3d22.57530718518144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0270fc57601777%3A0x63fc156a6c4526c9!2sIvdisplays%20Digital%20Services%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1638340115687!5m2!1sen!2sin"
          width="100%"
          height="580"

          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          frameBorder="0"
          title="Google Map"
        ></iframe>
      </div>
      {/* location section end */}
      <Footer />
    </>
  )
}

export default Contact;
