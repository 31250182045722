import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import EastIcon from '@mui/icons-material/East';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import { useSelector } from "react-redux";
function dateformat(datwene) {
  const inputDate =datwene.split("-");;

  const date = new Date(Date.UTC(inputDate[0], (parseInt(inputDate[1])-1), inputDate[2]));

  const formatter = new Intl.DateTimeFormat('en-GB', {
     
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata', // Ensure UTC formatting
  });
 
   console.log(formatter.format(date));
   const formatteNr =formatter.format(date).split(" ");;
  const formattedDate =formatteNr[0] + ' ' + formatteNr[1] + ', ' + formatteNr[2];
  return formattedDate;

}
function cahngenumber(number) {

  const twoDigitNumber = number.toString().padStart(2, '0');
  return twoDigitNumber;

}
const Career = () => {
  const { careerData } = useSelector((state) => state.pageContents);
  console.log(careerData);
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
          <div className="row">
            <div className="col-md-6">
            <div className="banner-sec-content main-banner-sec new-img-baner">
            {
                  careerData?.careerbanner &&
                  <>
                    <h1>{careerData.careerbanner.title} </h1>
                    <p>{careerData.careerbanner.subtitle}</p>
                  </>
                }
            </div>
          </div>
          <div className="col-md-6">
            <div className="banner-inner-img new-banr-img">
            {
                  careerData?.careerbanner &&
                  <> 
                    <img src={process.env.REACT_APP_IMAGE_URL + careerData.careerbanner.banner_image} alt="homeimg" />
                  </>
                }
           
            </div>
            </div>
          </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  careerData?.careerbanner &&
                  <>
                    <h1>{careerData.careerbanner.title} </h1>
                    <p>{careerData.careerbanner.subtitle}</p>
                  </>
                }
              </div>
            </div> */}

          </div>
        </div>
      </div>
      {/* post job opening details here start */}
      <div className="job-sec about-innov-main">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Open Job Position</h2>
            </div>
          </div>
          {careerData.career.reduce((rows, item, index) => {
            // Create a new row every 2 items
            if (index % 2 === 0) {
              rows.push([]); // Add a new row every two items
            }
            rows[rows.length - 1].push(item);
            return rows;
          }, []).map((rowItems, rowIndex) => (
            // Apply the 'margin cat top' class to every second row
            <div className="row">
              <div className="col-md-12">
                <div className="job-inner-sec">
                  {rowItems.map((item, indexc) => (
                    <div className={`${indexc == 0 && 'job-inner-left'} ${indexc == 1 && 'job-inner-right'}`}>
                      <div className="job-desc-new">
                        <h3>{item.title}</h3>
                        <div className="jon-loc">
                          <LocationOnIcon />
                          <span>{item.location}</span>
                        </div>
                        <div className="job-desc">
                          <div className="job-desc-left">
                            <CalendarMonthIcon />
                            <span>{dateformat(item.date)}</span>
                          </div>
                          <div className="job-desc-right">
                            <PersonIcon />
                            <span>No of Vacancies {cahngenumber(item.no_vac)}</span>
                          </div>
                        </div>
                        <div className="apply-sec">

                          <Link to={`/jobdetails/${item.slug}`} className="apply-btn-cont">Apply Now<span className="apply-btn"><EastIcon /></span></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}



        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* post job opening details here end */}
      <Footer />
    </>
  )
}

export default Career;
