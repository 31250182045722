import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import digitalicon from '../assets/img/digital_product_development_icon_red.png';
import businessicon from '../assets/img/business_process_optimization_icon_blue.png';
import aiicon from '../assets/img/ai_&_automation_red.png';
import EastIcon from '@mui/icons-material/East';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import visionimg from '../assets/img/vision-img.png';
import Footer from "./footer";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';



import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import classNames from "classnames";
import TextField from '@mui/material/TextField';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function dateformat(datwene) {
  const inputDate = datwene;
  const date = new Date(inputDate);

  // Format the date as "Month DD, YYYY"
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC' // Ensures consistent output in UTC
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;

}


function addZero(i) {
  if (i < 10) { i = "0" + i }
  return i;
}

const Home = () => {
  const { homeData } = useSelector((state) => state.pageContents);
  console.log(homeData);
  let navigate = useNavigate();
  const [formdateto, setFormdateto] = useState(null);
  const recaptchaRef = React.useRef();
  const [pageselect, setPageselect] = React.useState('');
  const datePickerRef = useRef(null);
  useEffect(() => {
    console.log('hi');;

    var date = new Date();
    setFormdateto(date);

  }, []);
  const openDatePicker = () => {
    alert('hi');
    if (datePickerRef.current) {
      datePickerRef.current.focus();
    }
  };
  const [opencase, setOpenCase] = React.useState(false);

  const handleClickOpenCasestudies = () => {
    setOpenCase(true);
  };
  const handleClosecase = () => {
    setOpenCase(false);
  };

  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen,index) => {

    setIsOpen(true);
    var casedeat = { ...formValues };
  
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = index;
    casedeat.pagename = 'getintouch';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    console.log(casedeat);
    setFormValues(casedeat);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');


  }

  const handleDialogOffChange = (isOpen) => {
    setIsOpen(false)
  }


  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const [opencaseno, setOpenCaseno] = React.useState(false);

  const handleClickOpenCaseno = () => {
    setOpenCaseno(true);
    setOpenCase(false);
  };
  const handleClosecaseno = () => {
    setOpenCaseyes(false);
    setOpenCaseno(false);
  };




  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    message: '',
    pagename: 'getintouch',

    sh_date: '',
    sh_time: '',
    business_name: '',
    system: '',

    sh_date_new: null,
    sh_time_new: null

  }
  const [opendate, setOpenDate] = useState(false); // State to control the open/close
  const handleIconClick = () => {
    setOpenDate(true); // Open the picker when the icon is clicked
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDate = (e) => {
    let temp = { ...formValues }

    var d = new Date(e);
    var date;
    var month;
    if (d.getDate() < 10) {
      date = "0" + d.getDate();
    }
    else {
      date = d.getDate();
    }

    if ((d.getMonth() + 1) < 10) {
      month = "0" + (d.getMonth() + 1);
    }
    else {
      month = (d.getMonth() + 1);
    }
    var datestring = d.getFullYear() + "-" + month + "-" + date;
    temp.sh_date = datestring;
    temp.sh_date_new = e;

    console.log(datestring);
    setFormErrors({ ...formErrors, ['sh_date']: null });
    setFormValues(temp);
    setOpenDate(false);
  }



  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours = d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);

    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

    console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = (title) => {
    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.pagename = 'getintouch';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    casedeat.business_name = '';
    casedeat.system = '';

    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (title) => {


    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setIsOpen(isOpen);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.pagename = 'getintouch';
    casedeat.sh_date = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.sh_time = '01:30 PM';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            ;
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }

  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'sh_date_new' && key !== 'sh_date' && key !== 'contact_us' && key != 'message' && key !== 'casestudyname'
        && key !== 'business_name' && key !== 'system'

      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }

  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);
  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          // setOpen(true);
          navigate('/thankyou/home');
          setTimeout(() => {
           
          }, 3000);


          if (pageselect == 'getintouch') {
            //    navigate('/thankyou/bookademoproject');
          }



        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

  const todaynew = dayjs();


  const minDate = todaynew.add(3, "day");
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec homebanner">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="banner-sec-content">
                  {
                    homeData?.sliders.length &&
                    <>
                      <p>
                        {homeData.sliders[0].banner_title}
                      </p>

                      <h1 dangerouslySetInnerHTML={{ __html: homeData.sliders[0].banner_title_short_one }}></h1>
                      <h2 dangerouslySetInnerHTML={{ __html: homeData.sliders[0].banner_title_short_two }}></h2>
                    </>

                  }
                </div>
              </div>
              <div className="col-md-5">
                {
                  homeData?.sliders.length &&
                  <>
                    <div className="mainbaner-img">

                    <img src={process.env.REACT_APP_IMAGE_URL + homeData.sliders[0].banner_image} alt="homeimg" />
                    </div>
                  </>

                }

              </div>
            </div>

          </div>
        </div>

      </div>

      {/* service section start */}
      <div className="home-service-sec ">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">

                {
                  homeData?.homeintro &&


                  <>
                    <p className="short-head-line">{homeData.homeintro.page_title}</p>
                    <h2>{homeData.homeintro.page_sub_title}</h2>
                    <p className="homeserv"> {homeData.homeintro.page_short_des} </p>

                  </>
                }
              </div>
            </div>
          </div>
          <div className="service-category">
            <div className="row">
              {
                homeData?.homeservice.length &&

                <>
                  {
                    homeData.homeservice.map((item, index) => (

                      <div className="col-md-4 service-home-widg">
                        <div className={`service-box  ${index == 1 && 'blue-service-box'}`}>
                          <div className="service-inner">
                            <div className="service-icon">
                              <div className="icon-sec">
                                <div className="icon-circle">
                                  <img src={process.env.REACT_APP_IMAGE_URL + item.icon} alt="service-icon" />
                                </div>
                              </div>
                              <div className="service-para-main">
                                <h3>{item.title}  </h3>
                              </div>
                            </div>
                            <div className="service-content ">
                              <p>{item.short_descirption}          </p>
                            </div>
                            <div className="button-sec btn-align">
                              <Link to={`/services#getservice`} class="btn-inner-sec">
                                <span className="btn-name">Go to Services</span>
                                <span className="btn-icon"><EastIcon /></span>
                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    ))
                  }
                </>

              }


            </div>
          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* service section end */}

      {/* Home our project section start */}
      <div className="home-service-sec home-projt-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">
                {
                  homeData?.homeprojectintro &&

                  <>
                    <p className="short-head-line">{homeData.homeprojectintro.page_title}</p>
                    <h2 dangerouslySetInnerHTML={{ __html: homeData.homeprojectintro.page_sub_title }}></h2>
                    <p> {homeData.homeprojectintro.page_short_des}</p>
                  </>

                }
              </div>
            </div>
          </div>
          <div className="projet-category-sec">
            <div className="row">

              {
                homeData?.homeproject.length &&

                <>
                  {
                    homeData.homeproject.map((item, index) => (
                      <div className="col-md-6">

                        <div className={`project-box  ${index == 1 && 'project-box-blue'} ${index == 0 && 'project-bg'}`}>
                          <p>
                            {item.title}
                          </p>
                          <div className="button-sec btn-align btn-white">
                            <a class="btn-inner-sec"  onClick={() => handleDialogChange(isOpen,index)}>
                              <span className="btn-name"> {index == 0 && 'Get In Touch'} {index == 1 && 'Reach Us'}</span>
                              <span className="btn-icon"><EastIcon /></span>
                            </a>


                          </div>
                        </div>
                      </div>
                    ))
                  }
                </>

              }

            </div>

          </div>
        </div>
        <div className="bg-icon-belowprojt">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* Home our project section end */}

      {/* vision section start */}
      {
        homeData?.homevision &&


        <div className="home-service-sec vision-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="vision-content">
                  <h2>{homeData.homevision.title}</h2>
                  <p>{homeData.homevision.short_des}</p>
                </div>

              </div>
              <div className="col-md-6">
                <div className="vision-image-bg">
                  <div className="bg-icon-belowservicess">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>

                <div className="vision-image">
                  <img src={process.env.REACT_APP_IMAGE_URL + homeData.homevision.image} alt={`Vision - Icon`} />
                </div>

              </div>
            </div>
          </div>
          <div className="bg-icon-belowservice">
            <img src={bgimagedots} alt="bg-icon" />
          </div>
        </div>
      }

      {/* vision section end */}

      <Footer />
      <BootstrapDialog
        onClose={handleDialogOffChange}
        aria-labelledby="customized-dialog-title"
        open={isOpen} className="prodt-modl-news"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOffChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="form-sec-main">
            <h2>Happy to help!</h2>
            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                  name="name"
                  className={classNames("form-control", { 'is-invalid': formErrors.name })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.name}


                />
                {
                  formErrors.name &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.name}
                    </div>
                  </div>
                }
              </Box>
            </div>

            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                  name="email"
                  className={classNames("form-control", { 'is-invalid': formErrors.email })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.email}

                />
                {
                  formErrors.email &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.email}
                    </div>
                  </div>
                }
              </Box>
              <div className="email-verify-sec">
                <p>This email will be verified</p>
              </div>


            </div>

            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                  name="phone"
                  className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.phone}
                />
                {
                  formErrors.phone &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.phone}
                    </div>
                  </div>
                }
                {/* <div className="email-verify-sec contact-veri">
                  <p>This number will be verified</p>
                </div> */}
              </Box>



              <div>
                <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
              </div>
            </div>
            <div className='form-sec form-modal date-pick-sec-modl new-modl-calen' >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'MobileDatePicker',
                  ]}
                >

                  <DemoItem >
                    <MobileTimePicker label="Suggest your preferred time to take our call"
                      onChange={handleTime}
                      value={formValues.sh_time_new}
                    />
 <span className="abut-clock-icon"><AccessTimeIcon /></span>

                  </DemoItem>

                </DemoContainer>
              </LocalizationProvider>
              {
                formErrors.sh_time &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.sh_time}
                  </div>
                </div>
              }
            </div>


           


            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrors.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrors.recaptcha}
                  </div>
                </div>
              }
            </div>



          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
              <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>




        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!

        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
           className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="otp-content-sec">
          <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
          </p>

          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>



            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="newotp-sec thanksuou"
      >
    
        <DialogContent dividers>

           

<div class="success-animation">
<svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
</div>

          {/* for animation end */}
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


        </DialogContent >

      </BootstrapDialog >
    </>
  )
}

export default Home;
