import axios from './axios';

export const getHomePageContent = () => {
    return axios.get('/home');
}

export const getAboutPageContent = () => {
    return axios.get('/about');
}

export const getSerCasCarConPageContent = () => {
    return axios.get('/service');
}

export const getCasestudy = () => {
    return axios.get('/casestudy');
}
export const addContact = (data) => {
    return axios.post('/contactform', data);
}
export const addConnect = (data) => {
    return axios.post('/happycontform', data);
}
export const otpUpdate = (data) => {
    return axios.post('/connectotpupdate', data);
}
export const checkIdco = (data) => {
    return axios.post('/checkidco', data);
}
export const bookDemo = (data) => {
    return axios.post('/bookdemofrom', data);
}

export const addApplication = (data) => {
    return axios.post('/applyform', data);
}
export const contactpupdate = (data) => {
    return axios.post('/contactpupdate', data);
}

export const blogupdate = (data) => {
    return axios.post('/blogupdate', data);
}


export const newslet = (data) => {
    return axios.post('/newslet', data);
}

export const verify = (data) => {
    return axios.post('/verify', data);
}

