import React, { useState } from "react";
import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./header";
import Footer from "./footer";
import EastIcon from '@mui/icons-material/East';
import errorimage from '../assets/img/error-removebg-preview.png';

import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";


const Termscondition = () => {



  return (
   <>
      <div className="main-bg-sec">
        <Header />
          <div className="main-sec main-innerbanner policy-banner error-page-banner">
            <div className="container">
              <div className="row">
              <div className="col-md-12">
                <div className="terms-sec privacy-policy-content error-page">
                  <img src={errorimage} alt="error" className="error-image" />
                  <p className="error-page-head">Oh my god,</p>
                  <p className="error-page-para"> it seems your lost your way !!!
                </p>
     <div className="prev-next-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {/* <div className="pre-btnss"> 
                                      <Link to={`/`}  className="next-main-sec" >

                                        <span className="btn-name" > Back to Home</span>
                                        <div class="arrows-container">
                                            <div class="arrownewss arrow-one">
                                            </div>
                                            <div class="arrownew arrow-two">
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}
                               <div className="button-sec btn-align btn-aror-algn back-btnn">
                              <Link to={`/`} class="btn-inner-sec">
                                <span className="btn-name">Back To Home</span>
                                <span className="btn-icon"><EastIcon /></span>
                              </Link>
                            </div>
                                </div>
                                </div>
                                </div></div>
    
      </div>
      </div>
      </div>
            
            </div>
          </div>
      </div>


      {/* terms and condition page start */}






      {/* terms and condition page end  */}

      {/* <Footer /> */}
   </>
  )
}

export default Termscondition;
