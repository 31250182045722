import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import visionimg from '../assets/img/timeline-img-icon/service-img-sec.png';
import serviceimg from '../assets/img/image-pic.png';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import serveimgicon from '../assets/img/ai_vision_icon_blue.png';
import imgstrategic from '../assets/img/strategy_icon_blue.png';
import imgoptimize from '../assets/img/execution_and_optimization_icon_blue.png';
import imgai from '../assets/img/ongoing_ai_support_and_maintainance_icon_blue.png';
import customservimgone from '../assets/img/Strategic_safeguards_in_every_step.png';
import customservimgtwo from '../assets/img/robust-developmen-process.png';
import customservimgthree from '../assets/img/measuring_icon.png';
import customservimgfour from '../assets/img/dedicated_support_blue_icon.png';
import mobilityimgone from '../assets/img/digital_centric_icon_blue.png';
import mobilityimgtwo from '../assets/img/testing_sec_blue.png';
import mobilityimgthree from '../assets/img/Go_live_and_optimizing_iconss.png';
import mobilityimgfour from '../assets/img/comprehensive_mobility_support_blue_icon.png';

import supportimgone from '../assets/img/Deployment_icon_new_blue.png';
import supportimgtwo from '../assets/img/agile_work_modal_blue_icon.png';
import supportimgthree from '../assets/img/maintaince_upgrade_img.png';
import supportimgfour from '../assets/img/our_exclusive_support_icon_blue.png';
import teamimage from '../assets/img/service-team-image.png';
import EastIcon from '@mui/icons-material/East';

import { useSelector } from "react-redux";


import { useTransition, animated } from '@react-spring/web'



import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import {
  Link, Navigate,
  useNavigate
} from 'react-router-dom';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";


import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



function addZero(i) {
  if (i < 10) { i = "0" + i }
  return i;
}

const Services = () => {
  const { serviceData } = useSelector((state) => state.pageContents);
  console.log(serviceData);
  React.useEffect(() => {


    const hash = window.location.hash;
    const sectionId = hash.substring(1);
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        scrollToSection(element);
      }
    }
  }, []);
  const scrollToSection = (elem) => {
    window.scrollTo({
      top: elem.offsetTop - 50,
      behavior: "smooth",
    });
  };

  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen)
  }

  const handleDialogOffChange = (isOpen) => {
    setIsOpen(false)
  }
  const transition = useTransition(isOpen, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  });

  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours = d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);

    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

    console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }


  const recaptchaRef = React.useRef();
  let navigate = useNavigate();
  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'servicepage',
    message: '',
    business_name: '',
    system: '',
    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);
  const transitionnew = useTransition(openotp, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  });

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'servicepage';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);
  const transitionthank = useTransition(open, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  })
  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname'
        && key !== 'business_name' && key !== 'system'
      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      } else if (formValues.contact_us == 'enterprise' && key === 'business_name' && !formValues[key]) {
        isValid = false;
        errors[key] = 'This field is required';
      }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          // setOpen(true);
          if (pageselect == 'servicepage') {
            // navigate('/thankyou/talktoservicepageship');
          }
          navigate('/thankyou/services');
          setTimeout(() => {
           
            // setOpen(false);
          }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }
  return (
    <>

      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  {
                    serviceData?.servicebanner &&
                    <>
                      <h1>{serviceData.servicebanner.title} </h1>
                      <p>{serviceData.servicebanner.subtitle}</p>
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  {
                    serviceData?.servicebanner &&
                    <>
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.servicebanner.banner_image} alt="homeimg" />
                    </>
                  }

                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  serviceData?.servicebanner &&
                  <>
                    <h1>{serviceData.servicebanner.title} </h1>
                    <p>{serviceData.servicebanner.subtitle}</p>
                  </>
                }
              </div>
            </div> */}

          </div>
        </div>

      </div>

      {/* secured develop section start here */}
      <div className="home-service-sec about-innov-main secure-main" id={`getservice`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head about-innov-sec secure-sec">
                {
                  serviceData?.serviceintro &&
                  <>
                    <p className="short-head-line">{serviceData.serviceintro.title}</p>

                    <p className="about-innov-para secure-para serice-nee-fix">
                      {serviceData.serviceintro.short_des}</p>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* secured develop end  */}

      {/* service category section start here */}

      <div className="home-service-sec differ-service-sec" >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">
                {
                  serviceData?.serviceaiintro &&
                  <>
                    <h2>{serviceData.serviceaiintro.title}</h2>
                    <p> {serviceData.serviceaiintro.short_des}
                    </p>
                  </>
                }

              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-right">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>
                {
                  serviceData?.serviceaiintro &&
                  <>
                    <div className="serv-img-inner">
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.serviceaiintro.image} />
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          {/* <div className="service-inner-category "> */}
          <div className="service-inner-category ">
            {serviceData.serviceaidata.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([item]); // Start a new row with the current item
              } else {
                rows[rows.length - 1].push(item); // Add the item to the last row
              }
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              <div key={rowIndex} className={`row ${rowIndex > 0 ? 'margin-catg-top' : ''}`}>
                {rowItems.map((item, indexc) => (
                  <div key={item._id} className="col-md-6">
                    <div className="serv-categ-box">
                      <div className="serv-categ-main">
                        <div className="serv-categ-icon">
                          <img src={process.env.REACT_APP_IMAGE_URL + item.icon} alt="service-icon" />
                        </div>
                        <div className="serv-categ-head">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                      <div className="serv-categ-para">
                        <p>{item.short_descirption}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={serveimgicon} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>The AI Vision with uncompromising Security</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We prioritize vulnerability control to safeguard your applications against potential threats.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgstrategic} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Strategic Roadmap</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>A comprehensive roadmap for maximum impact
                      and  implementing strategies that require less user input.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-catg-top">
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgoptimize} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Execution and Optimization</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Our focus on vulnerability control throughout the development process mitigates risks before they can impact your operations.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgai} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Ongoing AI Support and Maintenance</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We provide continuous support through proactive maintenance, ensuring your systems adapt and remain secure over time.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* service category section end */}

      {/* service second section start */}
      <div className="home-service-sec differ-service-sec vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="service-img-right servce-img-left">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>

                {
                  serviceData?.servicecustomintro &&
                  <>
                    <div className="serv-img-inner servleft-img-inner">
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.servicecustomintro.image} />
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">
                {
                  serviceData?.servicecustomintro &&
                  <>
                    <h2>{serviceData.servicecustomintro.title}</h2>
                    <p> {serviceData.servicecustomintro.short_des}
                    </p>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="service-inner-category">

            {serviceData.servicecustomdata.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([item]); // Start a new row with the current item
              } else {
                rows[rows.length - 1].push(item); // Add the item to the last row
              }
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              <div key={rowIndex} className={`row ${rowIndex > 0 ? 'margin-catg-top' : ''}`}>
                {rowItems.map(item => (
                  <div key={item._id} className="col-md-6">
                    <div className="serv-categ-box serv-categ-box-grey">
                      <div className="serv-categ-main">
                        <div className="serv-categ-icon">
                          <img src={process.env.REACT_APP_IMAGE_URL + item.icon} alt="service-icon" />
                        </div>
                        <div className="serv-categ-head">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                      <div className="serv-categ-para">
                        <p>{item.short_descirption}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Strategic Safeguards in Every Step</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We prioritize security by implementing encrypted passwords, email validation, and OTP validation to protect sensitive user information.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Robust Development Process</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We follow best practices by utilizing API validation
                      and IP restriction throughout the software lifecycle.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-catg-top">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Measuring User Adoption and Satisfaction</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We measure software success through user adoption and satisfaction, continuously seeking feedback to make iterative changes.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Dedicated Support</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Available for troubleshooting and enhancements,
                      our team ensures your software evolves securely.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}


          </div>
        </div>
      </div>
      {/* service second section end */}


      {/* service third section start */}
      <div className="home-service-sec differ-service-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">
                {
                  serviceData?.servicemobilityintro &&
                  <>
                    <h2>{serviceData.servicemobilityintro.title}</h2>
                    <p> {serviceData.servicemobilityintro.short_des}
                    </p>
                  </>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-right">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>
                {
                  serviceData?.servicemobilityintro &&
                  <>
                    <div className="serv-img-inner">
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.servicemobilityintro.image} />
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className="service-inner-category">

            {serviceData.servicemobilitydata.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([item]); // Start a new row with the current item
              } else {
                rows[rows.length - 1].push(item); // Add the item to the last row
              }
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              <div key={rowIndex} className={`row ${rowIndex > 0 ? 'margin-catg-top' : ''}`}>
                {rowItems.map(item => (
                  <div key={item._id} className="col-md-6">

                    <div className="serv-categ-box">
                      <div className="serv-categ-main">
                        <div className="serv-categ-icon">
                          <img src={process.env.REACT_APP_IMAGE_URL + item.icon} alt="service-icon" />
                        </div>
                        <div className="serv-categ-head">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                      <div className="serv-categ-para">
                        <p>{item.short_descirption}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Digital-Centric Development</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We develop incorporating cloud database solutions to safely store and manage sensitive data.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Testing with Integrated Security Controls</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Our rigorous testing includes AWS RDS for reliable data storage and AWS SNS for secure notifications, ensuring a seamless user experience.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row margin-catg-top">
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Go Live and optimizing</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Once live, we assist in maintaining and optimizing performance, ensuring that security protocols remain effective and adaptable to new threats.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Comprehensive Mobility Support</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We offer ongoing assistance with proactive measures like CI/CD pipelines using Bitbucket and Git pipeline to streamline updates.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* service third section end */}

      {/* service fourth section start */}
      <div className="home-service-sec differ-service-sec vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="service-img-right servce-img-left">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>
                {
                  serviceData?.servicesecurityintro &&
                  <>
                    <div className="serv-img-inner servleft-img-inner">
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.servicesecurityintro.image} />
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">
                {
                  serviceData?.servicesecurityintro &&
                  <>
                    <h2>{serviceData.servicesecurityintro.title}</h2>
                    <p> {serviceData.servicesecurityintro.short_des}
                    </p>
                  </>
                }
              </div>

            </div>

          </div>
          <div className="service-inner-category">
            {serviceData.servicesecuritydata.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([item]); // Start a new row with the current item
              } else {
                rows[rows.length - 1].push(item); // Add the item to the last row
              }
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              <div key={rowIndex} className={`row ${rowIndex > 0 ? 'margin-catg-top' : ''}`}>
                {rowItems.map(item => (
                  <div key={item._id} className="col-md-6">

                    <div className="serv-categ-box serv-categ-box-grey">
                      <div className="serv-categ-main">
                        <div className="serv-categ-icon">
                          <img src={process.env.REACT_APP_IMAGE_URL + item.icon} alt="service-icon" />
                        </div>
                        <div className="serv-categ-head">
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                      <div className="serv-categ-para">
                        <p>{item.short_descirption}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {/* <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Enhanced Security & Deployment</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Prioritizing the protection of digital tools and sensitive information to prevent unauthorized access. </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Agile Work Model</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Ensures timely resolution of issues while maintaining a strong security posture through continuous monitoring and assessment.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-catg-top">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Maintenance & Upgrades</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We provide regular system updates and improvements that evolve alongside emerging threats and vulnerabilities.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Our Exclusive Support</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>With proactive monitoring and troubleshooting, ensuring your infrastructure is fortified against potential risks.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* service fourth section end */}

      {/* proof of concept section start here */}
      {
        serviceData?.servicelowersec &&
        <>

          <div className="proof-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="prrof-img">
                    <div className="proof-content-sec">
                      <img src={process.env.REACT_APP_IMAGE_URL + serviceData.servicelowersec.image} />
                      <div className="tem-img-overlay"></div>
                      <div className="team-content">
                        <h3>{serviceData.servicelowersec.title} </h3>
                        <h4>{serviceData.servicelowersec.sub_title}</h4>

                        <div className="button-sec red-button-sec servicebtn-prof">

                          {/* <TriggerShadow /> */}
                          {/* <TriggerEdge /> */}
                          <a class="btn-inner-sec btn-red-sec" onClick={handleDialogChange}>
                            <span className="btn-name">{serviceData.servicelowersec.button_title}</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </a>

                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      }
      {/* proof of concept section end here */}

      <Footer />



      {/* modal start from here */}
      <BootstrapDialog
        onClose={handleDialogOffChange}
        aria-labelledby="customized-dialog-title"
        open={isOpen} className="prodt-modl-news service-modl-new"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOffChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="form-sec-main form-serv-sec">
            <h2>Got a Challenge Brewing</h2>
            <p className="serv-head-modl">Send us your requirement</p>

            <div className='form-sec form-modal form-radio-sec'>
              <FormControl>
                <FormLabel className="radio-sec-label">Are you an</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="individual" control={<Radio />} label="individual"
                    name="contact_us"
                    checked={formValues.contact_us == 'individual'}
                    onChange={handleInputChange}
                  />
                  <FormControlLabel value="enterprise" control={<Radio />} label="enterprise"
                    name="contact_us"
                    checked={formValues.contact_us == 'enterprise'}
                    onChange={handleInputChange}
                  />

                </RadioGroup>
              </FormControl>
              {
                formErrors.contact_us &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.contact_us}
                  </div>
                </div>
              }

            </div>
            {formValues.contact_us == 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Business Name " variant="outlined"

                      name="business_name"
                      className={classNames("form-control", { 'is-invalid': formErrors.business_name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.business_name}


                    />
                    {
                      formErrors.business_name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.business_name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Work Email" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }

            {formValues.contact_us != 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>



                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }




            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                noValidate
                autoComplete="off"
              >

                <TextField
                  id="outlined-multiline-static"
                  label="Can you describe your requirements? "
                  multiline
                  rows={4}
                  placeholder=""
                  name="message"
                  className={classNames("form-control", { 'is-invalid': formErrors.message })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.message}
                />
                {
                  formErrors.message &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.message}
                    </div>
                  </div>
                }
              </Box>
            </div>





            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrors.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrors.recaptcha}
                  </div>
                </div>
              }
            </div>



          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
              <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>




        </DialogContent >

      </BootstrapDialog >

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
          className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="otp-content-sec">
            <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
            </p>
          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>



            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >



      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="newotp-sec thanksuou"
      >


        <DialogContent dividers>
          <div class="success-animation">
            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


        </DialogContent >

      </BootstrapDialog >
      {/* modal end from here */}
    </>
  )
}

export default Services;
