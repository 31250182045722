import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import filtericon from '../assets/img/filter.png';
import SearchIcon from '@mui/icons-material/Search';
import blogimg from '../assets/img/blog-img.png';
import calngicon from '../assets/img/calendar.png';
import EastIcon from '@mui/icons-material/East';

import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import { useSelector } from "react-redux";

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { isComingSoon, generateUniqueToken } from "../utils/helper";

import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function truncateText(text, limit = 120) {
  console.log(text.length);
 return text.length > limit ? text.slice(0, limit) + "..." : text;
}
function dateformat(datwene) {
  const inputDate = datwene;
  const date = new Date(inputDate);

  // Format the date as "Month DD, YYYY"
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC' // Ensures consistent output in UTC
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;

}
const Blog = () => {
  const { blogData } = useSelector((state) => state.pageContents);
  console.log(blogData);

  // Filter blogs based on the search query

  // State to store visible blogs, search query, and the number of visible blogs
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(4);
  const [filteredBlogs, setFilteredBlogs] = useState(blogData.blogs);

  // Filter blogs based on the search query
  useEffect(() => {
    let formID = localStorage.getItem("blogcat");
     console.log(formID);
    //  setSearchQuery(formID);
    if (searchQuery === '') {
      setFilteredBlogs(
        blogData.blogs.filter((blog) =>
          blog.title.toLowerCase().includes(formID.toLowerCase()) ||
        blog.blog_category.category_name.toLowerCase().includes(formID.toLowerCase()) ||
        blog.short_des.toLowerCase().includes(formID.toLowerCase()) ||
        blog.blog_details.description.toLowerCase().includes(formID.toLowerCase()) 
        )
      );
    } else {
      setFilteredBlogs(
        blogData.blogs.filter((blog) =>
          blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.blog_category.category_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.short_des.toLowerCase().includes(searchQuery.toLowerCase()) ||
      
        blog.blog_details.description.toLowerCase().includes(searchQuery.toLowerCase()) 

        )
      );
    }
  }, [searchQuery]);

  // Handle input change for search
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle load more
  const loadMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 4, filteredBlogs.length));
  };

  // Handle backspace to clear search
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && searchQuery === '') {
      setFilteredBlogs(blogData.blogs);
      setVisibleCount(4);
    }
  };
  const [isOpencat, setIsOpencat] = useState(false)

  const handleDialogChangecat = (isOpen) => {
    setIsOpencat(true)
  }


  const handleDialogChangecatclose = () => {
    setIsOpencat(false);

  }

 
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log(selectedCategories);
    // If checked, add to selectedCategories array, else remove it
    setSelectedCategories((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((category) => category !== value);
      }
    });
  };

  const checkcat = () => {
    if (selectedCategories.length == 0) {
      setFilteredBlogs(blogData.blogs);
      setIsOpencat(false);
    } else {
      setFilteredBlogs(
        blogData.blogs.filter((blog) =>
          selectedCategories.some(query =>

            blog.blog_category.category_name.toLowerCase().includes(query.toLowerCase())
          )
        )

      );
      setIsOpencat(false);
    }
  };
  
  // // Determine what blogs to display
  // const displayBlogs = searchQuery ? filteredBlogs : blogData.blogs;
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner">
          <div className="container">
            <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  blogData?.blogbanner &&
                  <>
                    <h1>{blogData.blogbanner.title} </h1>
                    <p>{blogData.blogbanner.subtitle}</p>
                  </>
                }
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* blog section start */}
      <div className="home-service-sec about-innov-main blog-list">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container-width-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-inner-sec">
                  <div className="blog-category">
                    <p>All Category      <img src={filtericon} onClick={handleDialogChangecat} /></p>
                  </div>
                  <div className="blog-search">
                    <span><SearchIcon /></span><input type="text" placeholder="Search" className="input-srch"  
                      value={searchQuery}
                      
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="blog-list-inner">
              {filteredBlogs.slice(0, visibleCount).reduce((rows, item, index) => {
                // Create a new row every 2 items
                if (index % 2 === 0) {
                  rows.push([]); // Add a new row every two items
                }
                rows[rows.length - 1].push(item);
                return rows;
              }, []).map((rowItems, rowIndex) => (
                // Apply the 'margin cat top' class to every second row
                <div
                  className={`row ${rowIndex === 0 ? "" : "margin-blog-top"}`}
                  key={rowIndex}>
                  {rowItems.map((item, indexc) => (
                    <div className="col-md-6">
                      <div className="blog-list-sec">
                        <img src={process.env.REACT_APP_IMAGE_URL + item.image} />
                        <div className="blog-new-list">
                          <div className="blog-impor-sec">
                            <div className="blog-date">
                              <a>
                                <img src={calngicon} /><span>{dateformat(item.post_date)}</span>
                              </a>
                            </div>
                            <div className="blog-category">
                              <p>{item.blog_category.category_name} </p>
                            </div>
                          </div>
                          <div className="blog-list-content">
                            <h2>{item.title} </h2>
                            <p>
                              {/* {item.short_des} */}
                              {truncateText(item.short_des)}
                            </p>
                          </div>
                          {/* {item.blog_details != null  &&
                          <div className="button-sec btn-align btn-learn">
                          <Link  to={`/blogdetails/${item.slug}`} href="#" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Learn More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link >
                        </div>
                          } */}
                            {item.blog_details != null  &&
                          <div className="button-sec btn-align btn-blog-blue">
                            <Link to={`/blogdetails/${item.slug}`} href="#" class="btn-inner-sec">
                              <span className="btn-name">Read Post</span>
                              <span className="btn-icon"><EastIcon /></span>
                            </Link>
                            <div class="rating">
                                {/* <p>Rate this Blog:</p> */}
                                <p className="like-count">
                                {item.bloglike}</p>
                            <div class="like grow">
                              <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                            </div>

                            <div class="dislike grow">
                              <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                            </div>
                          </div>
                          </div>
                           }
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}

              {/* <div className="row">
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>AI-Driven Business Processes</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>AI interventions Should Be Central to Your Business</h2>
                        <p>
                          Through careful execution, it can offer smarter ways to run processes better and faster
                          through continuous learning and improvement, creating sustainable growth.
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Customer Journey Optimization</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Chatbots Today Enhance the Customer Journey</h2>
                        <p>
                          Businesses can escalate customer engagement with short resolution times and streamline interactive processes through the use of chatbots.
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row margin-blog-top">
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Business Process Transformation</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Technology Influence Can Foster Exponential Business Growth</h2>
                        <p>
                          The transformative role of technology in different parts of running a business brings streamlined operations and process optimization

                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Business Process Transformation</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Technology Influence Can Foster Exponential Business Growth</h2>
                        <p>
                          The transformative role of technology in different parts of running a business brings streamlined operations and process optimization
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
              {visibleCount < filteredBlogs.length && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="button-sec btn-align load-btn">
                      <a href="javascript:void(0)" class="btn-inner-sec" onClick={loadMore}>
                        <span className="btn-name">Load More</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </div>

              )}

            </div>

          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

      </div>
      {/* blog section end */}
      <Footer />

      <BootstrapDialog
        onClose={handleDialogChangecatclose}
        aria-labelledby="customized-dialog-title"
        open={isOpencat} className="prodt-modl-news modl-blog-widh "
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogChangecatclose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
          <div className=" ">
          <h2>All Category</h2>
          <div className="categfilt-sec">

            <Box className="category-box">
              {blogData.blogcategory.reduce((rows, item, index) => {
                // Create a new row every 2 items
                if (index % 2 === 0) {
                  rows.push([]); // Add a new row every two items
                }
                rows[rows.length - 1].push(item);
                return rows;
              }, []).map((rowItems, rowIndex) => (
                // Apply the 'margin cat top' class to every second row
                <>
                  <FormControl className={` ${rowIndex % 2 === 0 ? 'categy-left' : 'categy-right'}`} component="fieldset" variant="standard">
                    <FormGroup>

                      {rowItems.map(item => (
                        <FormControlLabel control={<Checkbox />} value={item.category_name} label={item.category_name}
                          onChange={handleCheckboxChange}
                          checked={selectedCategories.includes(item.category_name)}
                        />

                      ))}
                    </FormGroup>
                  </FormControl>
                </>

              ))}

            </Box>



          </div>
          <div className="button-sec btn-align btn-blog-blue mdl-btn modl-submit-cate">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={checkcat}>
              <span className="btn-name btn-sub">Submit</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>
          </div>
          </div>
        </DialogContent >

      </BootstrapDialog >
    </>
  )
}

export default Blog;
