import React, { useState } from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import filtericon from '../assets/img/filter.png';
import SearchIcon from '@mui/icons-material/Search';
import blogimg from '../assets/img/blog-img.png';
import calngicon from '../assets/img/calendar.png';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";
import { useTransition, animated } from '@react-spring/web'
import { styled } from '@stitches/react'
import * as Dialog from '@radix-ui/react-dialog';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import bannerimg from '../assets/img/new-img-ivd/blog-removebg-preview.png';
import placeholderBlogImg from '../assets/img/learner-driven-school.webp';
import blogEventImg from '../assets/img/blog-event.webp';
import chatbotInSchoolImg from '../assets/img/chatbots_in_school_operation.webp';
import schoolDigitalImg from '../assets/img/School_Digital_Transformation_m.webp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const TriggerPart = styled('span', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 8,
})

const TriggerShadow = styled(TriggerPart, {
  background: 'hsl(0deg 0% 0% / 0.1)',
  transform: 'translateY(2px)',
  transition: 'transform 250ms ease-out',
})

const TriggerEdge = styled(TriggerPart, {
  background: `linear-gradient(
      to left,
      hsl(0deg 0% 69%) 0%,
      hsl(0deg 0% 85%) 8%,
      hsl(0deg 0% 85%) 92%,
      hsl(0deg 0% 69%) 100%
    )`,
})

const TriggerLabel = styled('span', {
  display: 'block',
  position: 'relative',
  borderRadius: 8,
  color: '#569AFF',
  fontSize: '14px',
  padding: '16px 24px',
  background: '#fafafa',
  transform: 'translateY(-4px)',
  width: '100%',
  userSelect: 'none',
  transition: 'transform 250ms ease-out',
})

const Trigger = styled(Dialog.Trigger, {
  border: 'none',
  fontWeight: 600,
  cursor: 'pointer',
  background: 'transparent',
  position: 'relative',
  padding: 0,
  cursor: 'pointer',
  transition: 'filter 250ms ease-out',

  '&:hover': {
    filter: 'brightness(110%)',

    [`& ${TriggerLabel}`]: {
      transform: 'translateY(-6px)',
    },

    [`& ${TriggerShadow}`]: {
      transform: 'translateY(4px)',
    },
  },

  '&:active': {
    [`& ${TriggerLabel}`]: {
      transform: 'translateY(-2px)',
      transition: 'transform 34ms',
    },

    [`& ${TriggerShadow}`]: {
      transform: 'translateY(1px)',
      transition: 'transform 34ms',
    },
  },
})

const OverlayBackground = styled(animated(Dialog.Overlay), {
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  pointerEvents: 'all',
  position: 'fixed',
  inset: 0,
})

const Content = styled(animated(Dialog.Content), {
  position: 'absolute',
  /* height: auto; */
  backgroundColor: '#fafafa',
  borderRadius: 8,
  padding: '24px 24px 32px',
  top: '20%',
  width: '100%',
  maxWidth: '740px',
  margin: '0 auto',
  textAlign: 'center',
  transform: 'translate(-50%, -50%) !important',
  left: '50%',
})

const DialogHeader = styled('header', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 16,
})

const CloseButton = styled(Dialog.Close, {
  backgroundColor: 'transparent',
  border: 'none',
  position: 'absolute',
  top: 16,
  right: 16,
  cursor: 'pointer',
  color: '#1B1A22',
})

const Title = styled(Dialog.Title, {
  fontSize: 20,
})


const Blog = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen)
  }


  const transition = useTransition(isOpen, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  })

    // for caltegody inner modal start
    ;
  // for category inner modal end
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            {/* <div className="col-lg-12">
                <div className="banner-sec-content main-banner-sec">
                    <h1>Blog</h1>
                    <p>The Benefits of Technology Extended With AI Assistance for Educational Institutions</p>
                </div>
              </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  <h1>Blog </h1>
                  <p>The Benefits of Technology Extended With AI Assistance for Educational Institutions</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  <img src={bannerimg} alt="homeimg" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* blog section start */}
      <div className="home-service-sec about-innov-main blog-list">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container-width-blog">
          <div className="container">

            <div className="row">
              <div className="col-md-12">
                <div className="blog-inner-sec">
                  <div className="blog-category">
                    <p>All Category
                      <Dialog.Root open={isOpen} onOpenChange={handleDialogChange} >
                        <Trigger className="dialogfilter">
                          {/* <TriggerShadow /> */}
                          {/* <TriggerEdge /> */}
                          <TriggerLabel>   <img src={filtericon} /></TriggerLabel>
                        </Trigger>
                      </Dialog.Root>
                    </p>
                  </div>
                  <div className="blog-search">
                    <span><SearchIcon /></span><input type="text" placeholder="Search" className="input-srch" />
                  </div>
                </div>
              </div>
            </div>

            <div className="blog-list-inner">

              <div className="row">

                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={placeholderBlogImg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>February 14, 2025</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Learner-Driven Classrooms</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Can There Be a Truly Learner-Driven Classroom Beyond Traditional Teaching?</h2>
                        <p>
                          Traditional classrooms follow structured teaching, but what if students take charge of their own lea...
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue btn-flex">
                        <Link to="/blogdetails/can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </Link>
                     
                              <div class="rating-time">
                              <p className="rating-clock"><AccessTimeIcon /><span>3 min read</span></p>
                              </div>
                        <div class="rating">
                          <p className="like-count">9</p>
                          <div class="like grow">
                            <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                          </div>
                          <div class="dislike grow">
                            <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogEventImg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>February 11, 2025</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>AI in Education</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>A Manifesto for the Future of AI</h2>
                        <p>
                          Capturing insights from thought leaders at the International Workshop on AI-driven learning for St. ...
                        </p>
                      </div>
                   
                      <div className="button-sec btn-align btn-blog-blue btn-flex">
                        <Link to="/blogdetails/ai-in-education" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </Link>
                       
                              <div class="rating-time">
                              <p className="rating-clock"><AccessTimeIcon /><span>3 min read</span></p>
                              </div>
                        <div class="rating">
                          <p className="like-count">1</p>
                          <div class="like grow">
                            <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                          </div>
                          <div class="dislike grow">
                            <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row margin-blog-top">

                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={chatbotInSchoolImg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>January 04, 2025</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Chatbots in School Operations</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Chatbots Today Revolutionize the School Operational Journey</h2>
                        <p>
                          Enhance school operational engagement & streamline interactions with quick resolutions by using chat...
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue btn-flex">
                        <Link to="/blogdetails/chatbots-today-revolutionize-the-school-operational-journey" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </Link>
                        
                              <div class="rating-time">
                              <p className="rating-clock"><AccessTimeIcon /><span>3 min read</span></p>
                              </div>
                        <div class="rating">
                          <p className="like-count">0</p>
                          <div class="like grow">
                            <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                          </div>
                          <div class="dislike grow">
                            <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={schoolDigitalImg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>School Digital Transformation</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Technology Influence Can Foster Exponential Growth In Educational Institutions</h2>
                        <p>
                          The transformative role of technology in different parts of running a school brings streamlined oper...
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue btn-flex">
                        <Link to="/blogdetails/technology-influence-can-foster-exponential-growth-in-educational-institutions" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </Link> <div class="rating-time">
                              <p className="rating-clock"><AccessTimeIcon /><span>2 min read</span></p>
                              </div>
                        <div class="rating">
                          <p className="like-count">0</p>
                          <div class="like grow">
                            <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                          </div>
                          <div class="dislike grow">
                            <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="button-sec btn-align load-btn">
                    <a href="#" class="btn-inner-sec">
                      <span className="btn-name">Load More</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

      </div>
      {/* blog section end */}


      <Footer />


      {/* modal section start */}
      <Dialog.Root open={isOpen} onOpenChange={handleDialogChange} >
        <Dialog.Portal forceMount >
          {transition((style, isOpen) => (
            <>
              {isOpen ? (
                <OverlayBackground style={{ opacity: style.opacity }} />
              ) : null}
              {isOpen ? (
                <Content forceMount style={style} className="dialogfilternew">
                  <DialogHeader>
                    <CloseButton>
                      <div className="close-modal-sec">
                        <CloseIcon />
                      </div>
                    </CloseButton>
                  </DialogHeader>
                  <Title>All Category</Title>
                  <div className="categfilt-sec">

                    <Box className="category-box">
                      <FormControl className="categy-left" component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel control={<Checkbox />} label="AI-Driven Business Processes" />
                          <FormControlLabel control={<Checkbox />} label="Customer Journey Optimization" />
                          <FormControlLabel control={<Checkbox />} label="Business Process Transformation" />
                        </FormGroup>
                      </FormControl>

                      <FormControl className="categy-right" component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel control={<Checkbox />} label="AI-Driven Business Processes" />
                          <FormControlLabel control={<Checkbox />} label="Customer Journey Optimization" />
                          <FormControlLabel control={<Checkbox />} label="Business Process Transformation" />
                        </FormGroup>
                      </FormControl>

                    </Box>


                  </div>
                </Content>
              ) : null}
            </>
          ))}
        </Dialog.Portal>
      </Dialog.Root>
      {/* modal section end */}
    </>
  )
}

export default Blog;
