import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import casestudyimg from '../assets/img/case-study-img-one.png';
import casestudyimgtwo from '../assets/img/case-study-img-two.png.png';
import { Link } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import caseimginventory from '../assets/img/case-inventory-img.png';
import caseimgabsence from '../assets/img/case-absence-reporting-img.png';
import { useSelector } from "react-redux";

function truncateText(text, limit = 83) {
   console.log(text.length);
  return text.length > limit ? text.slice(0, limit) + "..." : text;
}

const Casestudies = () => {
  const { casestudiesData } = useSelector((state) => state.pageContents);
  console.log(casestudiesData);
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  {
                    casestudiesData?.casestudiesbanner &&
                    <>
                      <h1>{casestudiesData.casestudiesbanner.title} </h1>
                      <p>{casestudiesData.casestudiesbanner.subtitle}</p>
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  {
                    casestudiesData?.casestudiesbanner &&
                    <>
                      <img src={process.env.REACT_APP_IMAGE_URL + casestudiesData.casestudiesbanner.banner_image} alt="homeimg" />
                    </>
                  }

                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  casestudiesData?.casestudiesbanner &&
                  <>
                    <h1>{casestudiesData.casestudiesbanner.title} </h1>
                    <p>{casestudiesData.casestudiesbanner.subtitle}</p>
                  </>
                }

              </div>
            </div> */}

          </div>
        </div>
      </div>

      {/* case studies listing section start */}
      <div className="home-service-sec home-projt-sec ">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head product-head">
                {
                  casestudiesData?.casestudiesintro &&
                  <>
                    <p className="short-head-line">{casestudiesData.casestudiesintro.title}</p>

                    <p className="case-stu-para">
                      {casestudiesData.casestudiesintro.short_des}</p>
                  </>
                }

              </div>
            </div>
          </div>


          <div className="case-studies-list">
            {casestudiesData?.casestudies.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([]); // Add a new row every two items
              }
              rows[rows.length - 1].push(item);
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              <div
                className={`case-studies-list-row ${rowIndex % 2 === 0 ? "" : "margin-case"}`}
                key={rowIndex}>
                {rowItems.map((study, indexc) => (
                  <div className={`${indexc % 2 == 0 ? 'case-studies-list-left' : 'case-studies-list-right'}`}>
                    <div className="case-study-box">
                      <img src={process.env.REACT_APP_IMAGE_URL + study.case_image} />
                      <p>
                        
                      {truncateText(study.short_des)} </p>
                      <div className="button-sec btn-align btn-learn btn-new-case">
                        {study.banner_title != '' &&
                          <div className="button-sec btn-align btn-learn">
                            <Link to={`/casestudiesdetails/${study.product_details.slug}`} class="btn-inner-sec btn-inner-lern">
                              <span className="btn-name">Know More</span>
                              <span className="btn-icon"><EastIcon /></span>
                            </Link >
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                ))}
              </div>
            ))}
            {/* <div className="case-studies-list-row">
                <div className="case-studies-list-left">
                  <div className="case-study-box">
                    <img src={casestudyimg} />
                    <p>Our digitally evolved ERP solution impacted in positive growth for organizations with 0 downtime </p>
                      <div className="button-sec btn-align btn-learn">
                        <Link to="/productdetails" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Know More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link>
                      </div>
                  </div>
                </div>
                <div className="case-studies-list-right">
                  <div className="case-study-box">
                    <img src={casestudyimgtwo} />
                    <p>Publish all your workshops by streamlining training announcements, saving over 90% of  administrators time </p>
                    <div className="button-sec btn-align btn-learn">
                        <Link to="/productdetails" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Know More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link>
                      </div>
                  </div>
                </div>
              </div>


              <div className="case-studies-list-row margin-case">
                <div className="case-studies-list-left">
                  <div className="case-study-box">
                    <img src={caseimginventory} />
                    <p>Maintain your inventory that you could define and customize, with a maximum efficiency Up to 100% </p>
                      <div className="button-sec btn-align btn-learn">
                        <Link to="/productdetails" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Know More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link>
                      </div>
                  </div>
                </div>
                <div className="case-studies-list-right">
                  <div className="case-study-box">
                    <img src={caseimgabsence} />
                    <p>Automated leave request and approval processes that drive overall 100% of progress for both staff and management </p>
                    <div className="button-sec btn-align btn-learn">
                        <Link to="/productdetails" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Know More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link>
                      </div>
                  </div>
                </div>
              </div> */}


          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />

        </div>
      </div>
      {/* case studies listing section end */}
      <Footer />
    </>
  )
}

export default Casestudies;
