import React from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import prductimg from '../assets/img/eform-img.jpg';
import EastIcon from '@mui/icons-material/East';
import VideocamIcon from '@mui/icons-material/Videocam';
import workshopimg from '../assets/img/workshop-img.png';
import absenceimg from '../assets/img/absence-reporting-img.png';
import inventoryimg from '../assets/img/inventory-img.png';
import { Media, Video } from '@vidstack/player-react';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Products = () => {
  const { productData } = useSelector((state) => state.pageContents);
  console.log(productData);
  const [videof, setVideof] = React.useState('');
  const [imgfallvid, setImgfallvid] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (image, video) => {
    setImgfallvid(image);
    setVideof(video);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  {
                    productData?.productbanner &&
                    <>
                      <h1>{productData.productbanner.title} </h1>
                      <p>{productData.productbanner.subtitle}</p>
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  {
                    productData?.productbanner &&
                    <>
                      <img src={process.env.REACT_APP_IMAGE_URL + productData.productbanner.banner_image} alt="homeimg" />
                    </>
                  }

                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  productData?.productbanner &&
                  <>
                    <h1>{productData.productbanner.title} </h1>
                    <p>{productData.productbanner.subtitle}</p>
                  </>
                }
              </div>
            </div> */}

          </div>
        </div>

      </div>

      {/* product section start */}
      <div className="home-service-sec home-projt-sec product-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head product-head">
                {
                  productData?.productintro &&
                  <>
                    <p className="short-head-line">{productData.productintro.title}</p>

                    <p className="about-innov-para secure-para">
                      {productData.productintro.short_des}</p>
                  </>
                }
              </div>
            </div>
          </div>
          <div className="produt-category">
            {productData.product.reduce((rows, item, index) => {
              // Create a new row every 2 items
              if (index % 2 === 0) {
                rows.push([]); // Add a new row every two items
              }
              rows[rows.length - 1].push(item);
              return rows;
            }, []).map((rowItems, rowIndex) => (
              // Apply the 'margin cat top' class to every second row
              // <div
              //   className={`product-main ${rowIndex % 2 === 0 ? "" : "prodct-margin"}`}
              //   key={rowIndex}>
              <div
              className={`product-main ${rowIndex == 0 ? "" : "prodct-margin"}`}
              key={rowIndex}>
                {rowItems.map((item, indexc) => (
                  <div className={`${indexc == 0 && 'prodct-left'} ${indexc == 1 && 'prodct-right'}`}>
                    <div className="row product-box">
                      <div className="col-md-5 prodct-image">
                        <img src={process.env.REACT_APP_IMAGE_URL + item.image} alt="product" />
                      </div>

                      <div className="col-md-7 prodct-descp">
                        <h2>{item.title} </h2>
                        <p>{item.short_des}
                        </p>
                        <div className="btn-prodct">
                          {item.product_details != null &&
                            <div className="button-sec btn-align btn-learn">
                              <Link to={`/productdetails/${item.slug}`} href="#" class="btn-inner-sec btn-inner-lern">
                                <span className="btn-name">Learn More</span>
                                <span className="btn-icon"><EastIcon /></span>
                              </Link >
                            </div>
                          }

                          {item.video != '' &&
                            <div className="button-sec btn-align btn-video">
                              <a href="#" onClick={() => handleClickOpen(item.video_image, item.video)} class="btn-inner-sec btn-inner-vido">
                                <span className="btn-name" >Watch Video</span>
                                <span className="btn-icon"><VideocamIcon /></span>
                              </a>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/* <div className="product-main">
                    <div className="prodct-left">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={prductimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Enterprise Resource Planning </h2>
                              <p>Automate your primary processes with our ERP System
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <a href="#" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </a>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="prodct-right ">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={workshopimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Workshop Management System</h2>
                              <p>Optimize all your training tasks through one centralized platform in no time.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <a href="#" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </a>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                  </div>

                  <div className="product-main prodct-margin">
                    <div className="prodct-left">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={absenceimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Absence Reporting Portal </h2>
                              <p>Streamline all Leave processes in one single digital portal.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <a href="#" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </a>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    <div className="prodct-right">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={inventoryimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>Inventory Management System</h2>
                              <p>Reduce the complexity of misplaced assets with one solution.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <a href="#" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </a>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                  </div>

                  <div className="product-main prodct-margin">
                    <div className="prodct-left">
                      <div className="row product-box">
                          <div className="col-md-5 prodct-image">
                            <img src={absenceimg} alt="product" />
                          </div>
                      
                            <div className="col-md-7 prodct-descp">
                              <h2>AI-Powered Chatbot </h2>
                              <p>Interact Efficiently with our high-quality AI chatbot.
                              </p>
                              <div className="btn-prodct">
                              <div className="button-sec btn-align btn-learn">
                                <a href="#" class="btn-inner-sec btn-inner-lern">
                                  <span className="btn-name">Learn More</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </a>
                              </div>
                              <div className="button-sec btn-align btn-video">
                                <a href="#" class="btn-inner-sec btn-inner-vido">
                                  <span className="btn-name">Watch Video</span>
                                  <span className="btn-icon"><VideocamIcon /></span>
                                </a>
                              </div>
                            </div>
                            </div>
                    </div>
                    </div>
                    </div> */}



          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* product section end */}

      <Footer />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title" className="prodt-modl-news videoplay-modl"
        open={open}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {(videof != null && imgfallvid != '') &&
              <Media style={{ width: "100%" }}>
                <Video autoplay="true" loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid}>
                  <video loading="visible" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid} autoplay="true" src={process.env.REACT_APP_IMAGE_URL + videof} preload="none" data-video="0" controls />
                </Video>
              </Media>

            }
          </Typography>

        </DialogContent>

      </BootstrapDialog>
    </>
  )
}

export default Products;
