import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import projtimgvideo from '../assets/img/project-video-img.png';
import videoplayimg from "../assets/img/icon-play.png";
import benekeyone from "../assets/img/reduce_cost_increase_efficency.png";
import benekeytwo from "../assets/img/Quicker_decision_making_Approval_processes.png";
import benekeythree from "../assets/img/key_update.png";
import serviceImg from "../assets/img/8555_vision-img.png";
import drivingSuccessImg from "../assets/img/wms-details-icon/Driving-success-through-timely-trainings copy.webp";
import allowsStaffImg from "../assets/img/wms-details-icon/Allows-staff-to-focus-on-higher-value-activities copy.webp";
import profitableContributionImg from "../assets/img/wms-details-icon/Profitable contribution with high skilled employees copy.webp";
import fineTunedInventoryImg from "../assets/img/inventory-details-icons/Fine-tuned inventory levels assends financial performance.webp";
import preservesFinancialImg from "../assets/img/inventory-details-icons/Preserves financial resources by mitigating asset loss.webp";
import adaptabilityGrowthImg from "../assets/img/inventory-details-icons/Adaptability & Growth with seamless adjustments to inventory.webp";
import EastIcon from '@mui/icons-material/East';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";



const Casestudiesdetails = () => {
  const { slug } = useParams();
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        {slug === "enterprise-resource-planning" &&
          <>
            <div className="main-sec main-innerbanner">
              <div className="container">
                <div className="col-lg-12">
                  <div className="banner-sec-content main-banner-sec main-bannerinner">
                    <h1>Progressing Key Processes in Schools With Our ERP Systems </h1>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {slug === "absence-reporting-portal" &&
          <>
            <div className="main-sec main-innerbanner">
              <div className="container">
                <div className="col-lg-12">
                  <div className="banner-sec-content main-banner-sec main-bannerinner">
                    <h1>A Simple Absence Reporting & Tracking Of Leave Bank For Your School Staff </h1>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {slug === "workshop-management-system" &&
          <>
            <div className="main-sec main-innerbanner">
              <div className="container">
                <div className="col-lg-12">
                  <div className="banner-sec-content main-banner-sec main-bannerinner">
                    <h1>Maximize Your Employee Value In School To Drive Growth & Profitability </h1>
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {slug === "inventory-management-system" &&
          <>
            <div className="main-sec main-innerbanner">
              <div className="container">
                <div className="col-lg-12">
                  <div className="banner-sec-content main-banner-sec main-bannerinner">
                    <h1>A Powerful Inventory System That Saves Time & Money for Schools </h1>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div >

      {/* case studies video section start */}
      < div className="case-video-sec" >
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="case-video-main">
              <div className="case-video-main-inner">
                {slug === "enterprise-resource-planning" &&
                  <>
                    <div className="case-video-left">
                      <div className="case-video-box">
                        <p>Watch our ERP solution in action</p>
                      </div>
                    </div>
                    <div className="case-video-right">
                      <div className="case-vieo-inner">
                        <img src={projtimgvideo} />

                        <div className="play-sec-prdt">
                          <a id="play-video" class="video-play-button" href="#">
                            <img src={videoplayimg} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }

                {slug === "absence-reporting-portal" &&
                  <>
                    <div className="case-video-left">
                      <div className="case-video-box">
                        <p>See our absence management software in action</p>
                      </div>
                    </div>
                    <div className="case-video-right">
                      <div className="case-vieo-inner">
                        <img src={projtimgvideo} />

                        <div className="play-sec-prdt">
                          <a id="play-video" class="video-play-button" href="#">
                            <img src={videoplayimg} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }

                {slug === "workshop-management-system" &&
                  <>
                    <div className="case-video-left">
                      <div className="case-video-box">
                        <p>Bring workshop solution into action</p>
                      </div>
                    </div>
                    <div className="case-video-right">
                      <div className="case-vieo-inner">
                        <img src={projtimgvideo} />

                        <div className="play-sec-prdt">
                          <a id="play-video" class="video-play-button" href="#">
                            <img src={videoplayimg} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }

                {slug === "inventory-management-system" &&
                  <>
                    <div className="case-video-left">
                      <div className="case-video-box">
                        <p>Watch how you can track & manage your school Inventory</p>
                      </div>
                    </div>
                    <div className="case-video-right">
                      <div className="case-vieo-inner">
                        <img src={projtimgvideo} />

                        <div className="play-sec-prdt">
                          <a id="play-video" class="video-play-button" href="#">
                            <img src={videoplayimg} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>
          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div >
      {/* case studies video section end */}

      {/* business challenge section start */}
      <div className="home-service-sec about-innov-main busn-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {slug === "enterprise-resource-planning" &&
                <>
                  <div className="service-short-head business-sec">
                    <h2> Challenges In The Schools</h2>
                    <h3>The challenge was to overcome the pain points of legacy
                      systems that was delaying critical decision-making</h3>
                    <p>A challenge to modernize and replace the existing system,
                      handling faster turnaround that can streamline processes.</p>
                  </div>
                </>
              }

              {slug === "absence-reporting-portal" &&
                <>
                  <div className="service-short-head business-sec">
                    <h2>School Advantage</h2>
                    <h3>A centralized absence management</h3>
                    <p>Our system made it easier for staff to submit requests, track and approve.</p>
                  </div>
                </>
              }

              {slug === "workshop-management-system" &&
                <>
                  <div className="service-short-head business-sec">
                    <h2>Challenges in Schools</h2>
                    <h3>To Effectively equip employees by accelerating their skills with timely trainings</h3>
                    <p>To ensure employees progress and make them a valuable asset over time by enhancing their skills with initiatives like publishing workshops & announcements of training sessions</p>
                  </div>
                </>
              }

              {slug === "inventory-management-system" &&
                <>
                  <div className="service-short-head business-sec">
                    <h2>Challenges in Schools</h2>
                    <h3>To Implement Inventory Management to know what supplies and equipment school have on hand and their exact location</h3>
                    <p>To revolutionizes how educational institutions manage and track their assets, resulting in improved operational efficiency</p>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* business challenge section end */}

      {/* key business benefits section start */}
      <div className="case-video-sec busn-main-sec key-main-secss">
        <div className="container">
          {slug != "absence-reporting-portal" &&
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="busn-key-head">
                    <h2>Key Benefits</h2>
                  </div>
                </div>
              </div>
            </>
          }
          <div className="row">
            {slug === "absence-reporting-portal" &&
              <>
                <div className="home-service-sec differ-service-sec absentcase">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="differ-service-inner left-side-head-before new-case-add">
                          <h2>Key Benefit</h2>
                          <p>Unified organisational visibility about the number of people available to do tasks.</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="service-img-right">
                          <div className="vision-image-bg sev-main-bg">
                            <div className="bg-icon-belowservicess serv-bg-iconrigt">
                              <img src={bgimagedots} alt="bg-icon" />
                            </div>
                          </div>

                          <div className="serv-img-inner">
                            <img src={serviceImg} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }

            {slug === "enterprise-resource-planning" &&
              <>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={benekeytwo} />
                    <h3> Quicker decision-making & Approval processes</h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={benekeyone} />
                    <h3>Reduce operational costs
                      & increased requests accuracy</h3>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={benekeythree} />
                    <h3>Prompt updates for your
                      key prioritised work </h3>
                  </div>
                </div>
              </>
            }

            {slug === "workshop-management-system" &&
              <>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={drivingSuccessImg} />
                    <h3>Driving success through timely trainings</h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={allowsStaffImg} />
                    <h3>Allows staff to focus on higher-value activities</h3>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={profitableContributionImg} />
                    <h3>Profitable contribution with high skilled employees</h3>
                  </div>
                </div>
              </>
            }

            {slug === "inventory-management-system" &&
              <>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={fineTunedInventoryImg} />
                    <h3>Fine-tuned inventory levels assends financial performance</h3>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={preservesFinancialImg} />
                    <h3>Preserves financial resources by mitigating asset loss</h3>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="key-benefits-sec">
                    <img src={adaptabilityGrowthImg} />
                    <h3>Adaptability & Growth with seamless adjustments to inventory</h3>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>

      {/* key business benefits section end */}

      {/* call to action section start */}
      <div className="callactn-sec consult-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">

              <div className="case-bg-icons">
                <img src={bgimagedots} alt="bg-icon" />
              </div>

              {slug === "enterprise-resource-planning" &&
                <>
                  <div className="callactn-content consult-content">
                    <p>Is your school facing a similar challenge?</p>
                    <div className="button-sec red-button-sec cal-btn-sec consult-btn">
                      <a href="#" class="btn-inner-sec  btn-white">
                        <span className="btn-name">Get a Free Consultation</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </>
              }

              {slug === "absence-reporting-portal" &&
                <>
                  <div className="callactn-content consult-content">
                    <p>Track & manage all your leave balances with a clear oversight</p>
                    <div className="button-sec red-button-sec cal-btn-sec consult-btn">
                      <a href="#" class="btn-inner-sec  btn-white">
                        <span className="btn-name">Get a Free Consultation</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </>
              }

              {slug === "workshop-management-system" &&
                <>
                  <div className="callactn-content consult-content">
                    <p>Are your key initiatives timely supporting staff progress that value school growth?</p>
                    <div className="button-sec red-button-sec cal-btn-sec consult-btn">
                      <a href="#" class="btn-inner-sec  btn-white">
                        <span className="btn-name">Get a Free Consultation</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </>
              }

              {slug === "inventory-management-system" &&
                <>
                  <div className="callactn-content consult-content">
                    <p>Is Your Approach to Inventory Management supporting your school objectives?</p>
                    <div className="button-sec red-button-sec cal-btn-sec consult-btn">
                      <a href="#" class="btn-inner-sec  btn-white">
                        <span className="btn-name">Get a Free Consultation</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
      </div>
      {/* call to action section end */}

      {/* Resolution section start */}

      <div className="impact-sec resol-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Resolution</h2>

              {slug === "enterprise-resource-planning" &&
                <>
                  <p>To address the challenges we developed ERP solutions to manage and consolidate key school operations, support growth while serving effectively</p>
                </>
              }

              {slug === "absence-reporting-portal" &&
                <>
                  <p>Improving Every Step of Leave Management for Schools</p>
                </>
              }

              {slug === "workshop-management-system" &&
                <>
                  <p>To ensure that the school invests in the right resources for the right people</p>
                </>
              }

              {slug === "inventory-management-system" &&
                <>
                  <p>The ability to track assets, helps schools to safeguards their valuable assets</p>
                </>
              }

            </div>
          </div>
          <div className="resol-inner-sec">
            <div className="row">
              <div className="col-md-12">

                {slug === "enterprise-resource-planning" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Delivers Tangible Value </h3>
                      </div>
                      <div className="resol-para">
                        <p>By incorporating advanced programming to meets demands and maturity of schools reducing the costs associated with corrections and rework.</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "absence-reporting-portal" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Leave Entitlement with Clear Visibility</h3>
                      </div>
                      <div className="resol-para">
                        <p>Employees can monitor their leave balances across various categories ensuring clear awareness of their available time off.</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "workshop-management-system" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Fostering Employee Development</h3>
                      </div>
                      <div className="resol-para">
                        <p>Keep staff Informed with important updates of upcoming training sessions, ensures seamless engagement which leads to employee development</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "inventory-management-system" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Reducing Costs</h3>
                      </div>
                      <div className="resol-para">
                        <p>Your school can sidestep unnecessary expenditures related to excess stock and shortages.</p>
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">

                {slug === "enterprise-resource-planning" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Less Risk in Missing Major Deadlines </h3>
                      </div>
                      <div className="resol-para">
                        <p>By streamlining the approval process it leads to timely access and to get crucial information with better responsiveness.</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "workshop-management-system" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Valuable Training Initiatives</h3>
                      </div>
                      <div className="resol-para">
                        <p>Promptly participating in workshops and sessions maximizes the contribution of employee value for the school.</p>
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">

                {slug === "enterprise-resource-planning" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3> A Flexible ERP Solution  </h3>
                      </div>
                      <div className="resol-para">
                        <p>The faster implementation of new processes prioritises essential workflows with minimal disruption by increasing progress during the switch.</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "absence-reporting-portal" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Improved Service Continuity</h3>
                      </div>
                      <div className="resol-para">
                        <p>Proactive absence management reduces disruptions, ensuring ongoing operational effectiveness</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "workshop-management-system" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Timely Initiative for the Timely Growth</h3>
                      </div>
                      <div className="resol-para">
                        <p>Ensuring the key initiatives reach the right employee maximizing the allocation of training resources to those who need them most</p>
                      </div>
                    </div>
                  </>
                }

                {slug === "inventory-management-system" &&
                  <>
                    <div className="resol-inner-content">
                      <div className="resol-head">
                        <h3>Ensures Financial Protection</h3>
                      </div>
                      <div className="resol-para">
                        <p>Reducing the risk of asset loss directly safeguards wealth resources, allowing for reinvestment into growth opportunities.</p>
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>
          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>

      {/* Resolution section end */}
      {/* outcome section start */}
      <div className="outcome-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">

              {slug === "enterprise-resource-planning" &&
                <>
                  <div className="outcome-head">
                    <h2>Outcome</h2>
                    <p>The implementation resulted in remarkable outcomes</p>
                    <div className="outcome-order">
                      <ul>
                        <li>The system helps automate processes, leading to reduced operational costs.</li>
                        <li>The ERP system grows with the school, easily adapting to changing needs and supporting expansion.</li>
                        <li>The system led towards operational efficiency, making the investment value count.</li>
                      </ul>
                    </div>
                  </div>
                </>
              }

              {slug === "absence-reporting-portal" &&
                <>
                  <div className="outcome-head">
                    <h2>Outcome</h2>
                    <div className="outcome-order">
                      <ul>
                        <li>The ability to monitor and analyze absence trends equips schools to develop flexible staffing solutions</li>
                        <li>Eliminating confusion surrounding leave requests & statutes enhances employee experience</li>
                      </ul>
                    </div>
                  </div>
                </>
              }

              {slug === "workshop-management-system" &&
                <>
                  <div className="outcome-head">
                    <h2>Outcome</h2>
                    <p>Overall, implementation has led to positive outcomes</p>
                    <div className="outcome-order">
                      <ul>
                        <li>Employees are now more engaged in training initiatives, leading to focused sessions that directly enhance their skill sets</li>
                        <li>Proper timing of the key activities, reduces repetitive tasks, allowing them to concentrate on strategic initiatives that drive school growth</li>
                        <li>Acquisition of new skills for the employees not only increases employee value but also strengthens the school's position</li>
                      </ul>
                    </div>
                  </div>
                </>
              }

              {slug === "inventory-management-system" &&
                <>
                  <div className="outcome-head">
                    <h2>Outcome</h2>
                    <div className="outcome-order">
                      <ul>
                        <li>Our inventory management scales up your school by identifying availability of assets correctly & holding proper stock levels, reducing financial blockages.</li>
                        <li>Mitigating financial losses associated with asset mismanagement increases the likelihood for streamlining your operations.</li>
                      </ul>
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
      </div>
      {/* outcome section end */}
      <Footer />


    </>
  )
}

export default Casestudiesdetails;
