import React from 'react';
import Header from './header';
import thankyouimg from '../assets/img/thankyou.png';
import EastIcon from '@mui/icons-material/East';
import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
  } from "react-router-dom";

const Thankyou = () => {
  return (
   <>
    <div className="main-bg-sec">
    <Header />
    <div className="main-sec main-innerbanner policy-banner error-page-banner">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className='thanksuou'>
                      {/* <div class="success-animation">
                            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                       </div> */}
                <img className="thankyouimg" src={thankyouimg} />
          
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, </p><p>they will do the needful soon</p>
          <div className="button-sec btn-align btn-aror-algn back-btnn">
                              <Link to={`/`} class="btn-inner-sec">
                                <span className="btn-name">Back To Home</span>
                                <span className="btn-icon"><EastIcon /></span>
                              </Link>
                            </div>
          
          </div>
          </div>
          </div>
            </div>
            </div>
            </div>
   </>
  )
}

export default Thankyou