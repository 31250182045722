import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import jobtypeimg from '../assets/img/jobb-type.png';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import experienceimg from '../assets/img/experience.png';
import qulifimg from '../assets/img/qualificaion.png';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GroupIcon from '@mui/icons-material/Group';
import EastIcon from '@mui/icons-material/East';
import StickyBox from "react-sticky-box";

import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";

const Jobdetails = () => {
    const { slug } = useParams();
    return (
        <>
            <div className="main-bg-sec">
                <Header />
                <div className="main-sec main-innerbanner main-detsila-two">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="banner-sec-content main-banner-sec main-bannerinner">
                                {slug === 'digital-marketing' &&
                                    <>
                                        <h1>Digital Marketing </h1>
                                    </>
                                }
                                {slug === 'business-growth-consultant' &&
                                    <>
                                        <h1>Business Growth Consultant </h1>
                                    </>
                                }
                                {slug === 'customer-acquisition-expert' &&
                                    <>
                                        <h1>Customer Acquisition Expert </h1>
                                    </>
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {slug === 'digital-marketing' &&
                <>
                    <div className="job-details about-innov-main ">


                        <div className="job-desc-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="vision-image-bg sev-main-bg">
                                            <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg job-detl-dots">
                                                <img src={bgimagedots} alt="bg-icon" />
                                            </div>
                                        </div>
                                        <StickyBox offsetTop={20} offsetBottom={20}>
                                            <div className="job-desc-left">
                                                <h2>Job Information</h2>

                                                <div className="job-inform-below">

                                                    <ul>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-job">
                                                                    <img src={jobtypeimg} />
                                                                </div>
                                                                <div className="info-text ">
                                                                    <h3>Job Type</h3>
                                                                    <p>Digital Marketing</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-loc">
                                                                    <PlaceIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Location</h3>
                                                                    <p>Kolkata</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-date">
                                                                    <CalendarMonthIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Date Posted</h3>
                                                                    <p>17 Feb, 2025

                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-experience">
                                                                    <img src={experienceimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Experience</h3>
                                                                    <p>Min 5 years</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-quali">
                                                                    <img src={qulifimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Qualification</h3>
                                                                    <p>Post Graduate in Marketing from a premier institute</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-emply">
                                                                    <HowToRegIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Employee Type</h3>
                                                                    <p>Full Time</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-vac">
                                                                    <GroupIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>No of vacancies</h3>
                                                                    <p> 01</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </StickyBox>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="job-desc-right">
                                            <h2>Job Description</h2>

                                            <p>To accelerate our Sales Volume we are actively hunting for a lead generator. The goal would be to generate relevant leads using online channels. </p>
                                            <p>The desired candidate should have more than 5 years of rich experience in the digital marketing space. Has served in an agency or  on the client side. Candidates having certifications will be given preference.</p>
                                            <p>A pleasing personality, fluent in english, good at copywriting, go getter and target achiever
                                            </p>

                                            <p>We are looking for a digital marketing specialist having good knowledge about generating leads from PAID & Free campaigns in the US market.</p>

                                            <p>Good knowledge about omni channels and understanding the online space and people behaviour.</p>

                                            <p>Targetting and Remarketing are a must to know skills.</p>


                                            <h3>The ideal candidate should have the following expertise</h3>
                                            <ul class="listcarer">
                                                <li> <span>Website Launches</span></li>
                                                <li> <span>Email Marketing</span></li>
                                                <li> <span>LinkedIn Expert</span> </li>
                                                <li> <span>Google Analytics Expert</span> </li>
                                                <li> <span>Google Ads Expert</span> </li>
                                                <li> <span>SEO Expertis</span></li>
                                                <li> <span>Facebook Expertise</span></li>
                                                <li> <span>Instagram Expert</span></li>


                                            </ul>

                                            <p>These are channels where we expect the candidate to bring deep knowledge about setting up a campaign and generating leads.</p>
                                            <p>Landing page creation and content creation should be his or her forte
                                            </p>

                                            <h4>What we Offer</h4>
                                            <p> Our basic emoluments starts with a six figure CTC, the opportunity to grow your pay is limitless.Apart from pay, the adrenalin rush is seeing the business grow due to your efforts will be your priceless takeaway for a long time.
                                            </p>
                                            <p>We are a rewarding enterprise and your positive contribution will be surely recognised and you can move up the trajectory. </p>
                                            <p> Start up with sending your latest CV and a short note where do you see yourself in the next 2 years.</p>



                                            <div className="detail-apply-sec">
                                                <div className="detail-inn">

                                                    <p>Apply at <span>hr@ivdisplays.com</span></p>

                                                    <p className="or-sec">OR</p>

                                                    <div className="button-sec btn-align btn-learn btn-job-aply">
                                                        <a href="#" class="btn-inner-sec btn-inner-lern" >
                                                            <span className="btn-name">Apply Now</span>
                                                            <span className="btn-icon"><EastIcon /></span>
                                                        </a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="bg-icon-belowprojt">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>




                    </div>
                </>
            }
            {/* job details section start */}
            {slug === 'business-growth-consultant' &&
                <>
                    <div className="job-details about-innov-main ">


                        <div className="job-desc-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="vision-image-bg sev-main-bg">
                                            <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg job-detl-dots">
                                                <img src={bgimagedots} alt="bg-icon" />
                                            </div>
                                        </div>
                                        <StickyBox offsetTop={20} offsetBottom={20}>
                                            <div className="job-desc-left">
                                                <h2>Job Information</h2>

                                                <div className="job-inform-below">

                                                    <ul>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-job">
                                                                    <img src={jobtypeimg} />
                                                                </div>
                                                                <div className="info-text ">
                                                                    <h3>Job Type</h3>
                                                                    <p>Business Growth Consultant</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-loc">
                                                                    <PlaceIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Location</h3>
                                                                    <p>Kolkata</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-date">
                                                                    <CalendarMonthIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Date Posted</h3>
                                                                    <p>17 Feb, 2025

                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-experience">
                                                                    <img src={experienceimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Experience</h3>
                                                                    <p>More than 18 Years</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-quali">
                                                                    <img src={qulifimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Qualification</h3>
                                                                    <p>MBA</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-emply">
                                                                    <HowToRegIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Employee Type</h3>
                                                                    <p>Full Time</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-vac">
                                                                    <GroupIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>No of vacancies</h3>
                                                                    <p> 01</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </StickyBox>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="job-desc-right">
                                            <h2>Job Description</h2>

                                            <p>Ideal candidate should be a marketing wizard having 15 - 20 years of working
                                                experience having sold IT solutions in the education domain. Should have a
                                                proven record of scaling small businesses into large enterprises. A good strategist and a great executioner.
                                                Is able to understand market gaps and position businesses accordingly. Good contacts in the education sector is a must,
                                                especially in NewJersey</p>
                                            <p>Seeking an opportunity to break through and prove to the world his or her worth.</p>
                                            <p>Understands the software solutions market at the back of his/her hand.
                                                A smart and intelligent worker. A business traveller at heart.
                                                Has the ability to strike rapport easily with people, is honest and yearns for the best things in life.
                                                Has good connections with CIO's & Technology heads in the K-12 domain, can influence them to win large contracts.</p>
                                            <h3>Desired Candidate Profile</h3>
                                            <ul class="listcarer">
                                                <li><span>Should be in a similar role with a CMMI Level 3 organisation.</span></li>
                                                <li><span>Having good clarity over technical specification of software products.</span></li>
                                                <li><span>Experience in customer acquisition for software services in the education domain Entrepreneur at heart.</span></li>
                                                <li><spna>Preferably a Engineering graduate with a Management Degree from a leading institution A go getter and an achiever.</spna></li>
                                            </ul>
                                            <h4>What we Offer</h4>
                                            <p>Remuneration starting at 7 digits and an opportunity to reach for the sky</p>
                                            <p>If you have a strong desire to change your current lifestyle and be recognised for having built a large business from the ground up,
                                                we are your best bet. We are here to change how our customers work today and will work tomorrow and you can create this story. If you have dreamt of building a large business, this is your chance to do it. The successful candidate will have the vote of trust from the leadership team,
                                                will be given a clear advantage to lay down the growth plans and be the torch bearer for the next growth phase of the business.
                                            </p>


                                            <div className="detail-apply-sec">
                                                <div className="detail-inn">

                                                    <p>Apply at <span>hr@ivdisplays.com</span></p>

                                                    <p className="or-sec">OR</p>

                                                    <div className="button-sec btn-align btn-learn btn-job-aply">
                                                        <a href="#" class="btn-inner-sec btn-inner-lern" >
                                                            <span className="btn-name">Apply Now</span>
                                                            <span className="btn-icon"><EastIcon /></span>
                                                        </a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="bg-icon-belowprojt">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>




                    </div>
                </>
            }
            {slug === 'customer-acquisition-expert' &&
                <>
                    <div className="job-details about-innov-main ">


                        <div className="job-desc-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="vision-image-bg sev-main-bg">
                                            <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg job-detl-dots">
                                                <img src={bgimagedots} alt="bg-icon" />
                                            </div>
                                        </div>
                                        <StickyBox offsetTop={20} offsetBottom={20}>
                                            <div className="job-desc-left">
                                                <h2>Job Information</h2>

                                                <div className="job-inform-below">

                                                    <ul>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-job">
                                                                    <img src={jobtypeimg} />
                                                                </div>
                                                                <div className="info-text ">
                                                                    <h3>Job Type</h3>
                                                                    <p>Customer Acquisition Expert</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-loc">
                                                                    <PlaceIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Location</h3>
                                                                    <p>Kolkata</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-date">
                                                                    <CalendarMonthIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Date Posted</h3>
                                                                    <p>17 Feb, 2025

                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-experience">
                                                                    <img src={experienceimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Experience</h3>
                                                                    <p>More than 5 years</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-quali">
                                                                    <img src={qulifimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Qualification</h3>
                                                                    <p>Post Graduate in Marketing from a premier institute</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-emply">
                                                                    <HowToRegIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Employee Type</h3>
                                                                    <p>Full Time</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-vac">
                                                                    <GroupIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>No of vacancies</h3>
                                                                    <p> 01</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </StickyBox>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="job-desc-right">
                                            <h2>Job Description</h2>

                                            <p>We are looking to hire a result-oriented IT Business Manager who will be responsible for acquiring medium to large contracts in the education software solutions space from International and Domestic markets . </p>
                                            <p>The ideal candidate should have over 5-8 years of handson experience in Pre-Sales activities in the similar domain.
                                            </p>

                                            <h3>Key Responsibilities</h3>
                                            <ul class="listcarer">
                                                <li> <span>Identifying and acquiring new clients on business networking platforms</span></li>
                                                <li><span>Creating Pitch decks to acquire new customers</span></li>
                                                <li><span>Preparing SRS and SOW. Responding to RFP/RFQ.</span></li>
                                                <li><span>Creating growth hacks and implementing them to propel revenue growth</span></li>
                                                <li><span>Develop detailed project plans for future growth.</span></li>
                                                <li><span>Sales Closure</span></li>





                                            </ul>



                                            <h4>Candidate Profile</h4>
                                            <ul class="listcarer">
                                                <li> <span>Should be in a similar role with a CMMI Level 3 organisation</span></li>
                                                <li><span>Having good clarity over technical specification of software products</span></li>

                                                <li><span>Experience in customer acquisition for software services in the international market</span></li>
                                                <li><span>Entrepreneur at heart</span></li>
                                                <li><span>A go getter and an achiever</span>
                                                </li>





                                            </ul>



                                            <div className="detail-apply-sec">
                                                <div className="detail-inn">

                                                    <p>Apply at <span>hr@ivdisplays.com</span></p>

                                                    <p className="or-sec">OR</p>

                                                    <div className="button-sec btn-align btn-learn btn-job-aply">
                                                        <a href="#" class="btn-inner-sec btn-inner-lern" >
                                                            <span className="btn-name">Apply Now</span>
                                                            <span className="btn-icon"><EastIcon /></span>
                                                        </a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="bg-icon-belowprojt">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>




                    </div>
                </>
            }
            {/* job details section start */}

            {/* job etails section end  */}
            <Footer />



        </>
    )
}

export default Jobdetails;
