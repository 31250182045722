import React, { useEffect, useState } from "react";
import {
  BrowserRouter, Routes, Route, Navigate, useParams,
  useNavigate,
} from "react-router-dom";
import './App.css';
import Home from "./components/home";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./components/about";
import Services from "./components/services";
import Products from "./components/products";
import Blog from "./components/blog";
import Blogcategory from "./components/blogcategory";
import Casestudies from "./components/casestudies";
import Career from "./components/career";
import Contact from "./components/contact";
import Productdetails from "./components/product-details";
import Blogdetails from "./components/blogdetails";
import Casestudiesdetails from "./components/casestudies-details";
import Jobdetails from "./components/jobdetails";
import Privacy from "./components/privacy-policy";
import Terms from "./components/terms-condition";
import Newpage from "./components/newpage";
import Thankyoucontact from "./components/thankyoucontact";
import Thankyouhome from "./components/thankyouhome";
import Thankyouabout from "./components/thankyouabout";
import Thankyouservices from "./components/thankyouservices";
import Thankyouproducts from "./components/thankyouproducts";
import Thankyoucasestudies from "./components/thankyoucasestudies";
import Thankyoublog from "./components/thankyoublog";
import Thankyoucareer from "./components/thankyoucareer";
import Homepre from "./components/homepre";
import Servicespre from "./components/servicespre";
import Contactpre from "./components/contactpre";
import Productspre from "./components/productspre";
import Productdetailspre from "./components/product-detailspre";
import Aboutpre from "./components/aboutpre";
import Careerpre from "./components/careerpre";
import Jobdetailspre from "./components/jobdetailspre";
import Casestudiespre from "./components/casestudiespre";
import Casestudiesdetailspre from "./components/casestudies-detailspre";
import Blogpre from "./components/blogpre";
import Blogdetailspre from "./components/blogdetailspre";
import Verify from "./components/verify";
import AOS from "aos";
import { AppContext } from "./context";
import {
  getHomePageContent,
  getAboutPageContent,
  getSerCasCarConPageContent,
  getCasestudy
  // checkIdco
} from "./actions/pagecontent.actions";
import { setPageContent } from "./features/pageContentSlice";
import { setFormToken, setIpAddress } from "./features/userdataSlice";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./utils/ScrollToTop";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { generateUniqueToken } from "./utils/helper";
import axios from "axios";
import Preloader from "./ui-components/Preloader";


function NoMatch() {
  // alert("No Match");
  return <Navigate to='/404page' />
}
function App() {

  const [pageLoaded, setPageLoaded] = useState(false);
  const dispatch = useDispatch();

  const formToken = useSelector((state) => state.userdata.formToken);
  const { id } = useParams();
  React.useEffect(() => {
    AOS.init();
    var pathname = window.location.pathname;


    /**
     * Load Contens of all pages
     */
    getHomePageContent()
      .then((res) => {
        if (res.data.status) {
          dispatch(setPageContent({ key: "home", data: res.data.home }));
          getAboutPageContent()
            .then((res) => {
              if (res.data.status) {
                //  console.log(res.data.about);
                dispatch(
                  setPageContent({ key: "about", data: res.data.about })
                );

                getSerCasCarConPageContent()
                  .then((res) => {
                    if (res.data.status) {
                      // console.log(res.data.service);
                      dispatch(
                        setPageContent({
                          key: "service",
                          data: res.data.service,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "product",
                          data: res.data.product,
                        })
                      );
                      dispatch(
                        setPageContent({
                          key: "blog",
                          data: res.data.blog,
                        })
                      );


                      getCasestudy()
                        .then((res) => {
                          if (res.data.status) {
                            // console.log(res.data.service);
                            dispatch(
                              setPageContent({
                                key: "casestudies",
                                data: res.data.casestudy,
                              })
                            );
                            dispatch(
                              setPageContent({
                                key: "career",
                                data: res.data.career,
                              })
                            );
                            dispatch(
                              setPageContent({
                                key: "contact",
                                data: res.data.contact,
                              })
                            );


                            setPageLoaded(true);





                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });


                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }

            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (!formToken) {
      const token = generateUniqueToken();
      dispatch(setFormToken(token));
    }

    //get user ip
    loadIp();

    // setTimeout(() =>, 1500);
  }, []);

  const loadIp = () => {
    axios
      .get("https://ipinfo.io/json")
      .then((res) => {
        dispatch(setIpAddress(res.data.ip));
      })
      .catch((err) => {
        loadIp();
      });
  };

  var data = [];

  const [storage, setStorage] = React.useState([data]);

  const dispatchStorageEvent = (payload) => {
    setStorage(payload);

    return true;
  };

  const handleAcceptCookie = () => { };

  var baseName = "/";
  return (

    <div className="App">
      {!pageLoaded ? (
        <AppContext.Provider value={{ storage, dispatchStorageEvent }}>
        <BrowserRouter basename={baseName}>
       
            <ScrollToTop />
            {/* Render navigation button */}
            {/* <Routes>
            <Route path="/" element={<Homepre />} />
            <Route path="/services" element={<Servicespre />} />
            </Routes> */}
          
            <Routes>
                <Route path="/" element={<Homepre />} />
   
                <Route path="/about" element={<Aboutpre />} />
              
                <Route path="/services" element={<Servicespre />} />
                <Route path="contact" element={<Contactpre />} />
                <Route path="/products" element={<Productspre />} />
                <Route path="/productdetails/:slug" element={<Productdetailspre />} />
                <Route path="/career" element={<Careerpre />} />
                <Route path="/jobdetails/:slug" element={<Jobdetailspre />} />
                <Route path="/casestudies" element={<Casestudiespre />} />
                <Route path="/casestudiesdetails/:slug" element={<Casestudiesdetailspre />} />
                <Route path="/blog" element={<Blogpre />} />
                <Route path="/blogdetails/:slug" element={<Blogdetailspre />} />
                <Route path="/verify/:slug" element={<Verify />} />
                <Route path="/thankyou/contact" element={<Thankyoucontact />} />
                <Route path="/thankyou/home" element={<Thankyouhome />} />
                <Route path="/thankyou/about" element={<Thankyouabout />} />
                <Route path="/thankyou/services" element={<Thankyouservices />} />
                <Route path="/thankyou/products" element={<Thankyouproducts />} />
                <Route path="/thankyou/casestudies" element={<Thankyoucasestudies />} />
                <Route path="/thankyou/blog" element={<Thankyoublog />} />
                <Route path="/thankyou/career" element={<Thankyoucareer />} />

              </Routes>

        </BrowserRouter>
        </AppContext.Provider>

      ) : (
        <>
          <AppContext.Provider value={{ storage, dispatchStorageEvent }}>
            <BrowserRouter basename={baseName}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NoMatch />} />
                <Route path="/header" element={<Header />} />
                <Route path="/404page" element={<Newpage />} />
                <Route path="/footer" element={<Footer />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/products" element={<Products />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/casestudies" element={<Casestudies />} />
                <Route path="/career" element={<Career />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/productdetails/:slug" element={<Productdetails />} />
                <Route path="/blogdetails/:slug" element={<Blogdetails />} />
                <Route path="/casestudiesdetails/:slug" element={<Casestudiesdetails />} />
                <Route path="/jobdetails/:slug" element={<Jobdetails />} />
                <Route path="/blogcategory" element={<Blogcategory />} />
                <Route path="/privacy-policy" element={<Privacy />} />

                <Route path="/terms-condition" element={<Terms />} />
                <Route path="/verify/:slug" element={<Verify />} />
                <Route path="/thankyou/contact" element={<Thankyoucontact />} />
                <Route path="/thankyou/home" element={<Thankyouhome />} />
                <Route path="/thankyou/about" element={<Thankyouabout />} />
                <Route path="/thankyou/services" element={<Thankyouservices />} />
                <Route path="/thankyou/products" element={<Thankyouproducts />} />
                <Route path="/thankyou/casestudies" element={<Thankyoucasestudies />} />
                <Route path="/thankyou/blog" element={<Thankyoublog />} />
                <Route path="/thankyou/career" element={<Thankyoucareer />} />

              </Routes>
            </BrowserRouter>

            <CookieConsent
              location="bottom"
              buttonText="Accept"
              cookieName="smfacookieconsent"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              expires={150}
              onAccept={handleAcceptCookie}
              enableDeclineButton
              declineButtonText="Reject"
              buttonClasses="accptbtn"
              declineButtonClasses="declinbtn" className="cookis-sec"
            >
              <h1 className="head-cookie">We use cookies to improve your experience
              </h1>
              <p className="para-cookk"> By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts</p>
            </CookieConsent>
          </AppContext.Provider>
        </>
      )}
    </div>
  );
}

export default App;
