import React from "react";
import Header from "./headerpre";
import digitalicon from '../assets/img/digital_product_development_icon_red.png';
import businessicon from '../assets/img/business_process_optimization_icon_blue.png';
import aiicon from '../assets/img/ai_&_automation_red.png';
import EastIcon from '@mui/icons-material/East';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import visionimg from '../assets/img/timeline-img-icon/vision-img_new.png';
import Footer from "./footerpre";
import homeimg from '../assets/img/home_new.webp';


const Home = () => {
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec homebanner">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="banner-sec-content">

                  <p>
                    The one-of-a-kind autonomous technology
                    partner transforming schools through modern
                    digitization models.
                  </p>

                  <h1>We Handle Process <span className="head-highlight">Smartly</span>, </h1>
                  <h2>So You Drive Progress <span className="sechead-highlight">Conveniently</span>.</h2>
                </div>
              </div>
              <div className="col-md-5">

                <div className="mainbaner-img">

                  <img src={homeimg} alt="homeimg" />
                </div>


              </div>
            </div>

          </div>
        </div>

      </div>

      {/* service section start */}
      <div className="home-service-sec ">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">

                <p className="short-head-line">A Unified Excellence</p>
                <h2>The Transformative Digital Solution For Schools</h2>
                <p>  Our modernization methodology that delivers efficiency consistently.  </p>


              </div>
            </div>
          </div>
          <div className="service-category">
            <div className="row">

              <div className="col-md-4 service-home-widg">
                <div className="service-box">
                  <div className="service-inner">
                    <div className="service-icon">
                      <div className="icon-sec">
                        <div className="icon-circle">
                          <img src={aiicon} alt="service-icon" />
                        </div>
                      </div>
                      <div className="service-para-main">
                        <h3>AI & Automation</h3>
                      </div>
                    </div>
                    <div className="service-content ">
                      <p>We can harness the power of Artificial Intelligence with adaptive algorithms and cognitive automation solutions that enable decision-making, process automation and predictive analytics.  </p>
                    </div>
                    <div className="button-sec btn-align">
                      <a href="#" class="btn-inner-sec">
                        <span className="btn-name">Go to Services</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-4 service-home-widg">
                <div className="service-box blue-service-box">
                  <div className="service-inner">
                    <div className="service-icon">
                      <div className="icon-sec">
                        <div className="icon-circle">
                          <img src={digitalicon} alt="service-icon" />
                        </div>
                      </div>
                      <div className="service-para-main">
                        <h3>Digital Product Development</h3>
                      </div>
                    </div>
                    <div className="service-content">
                      <p>We deploy cutting-edge digital technologies to develop innovations that revolutionize processes which are scalable and intuitive solutions that start from ideation to deployment to maximize overall digital experiences.
                      </p>

                    </div>
                    <div className="button-sec btn-align">
                      <a href="#" class="btn-inner-sec">
                        <span className="btn-name">Go to Services</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-4 service-home-widg">
                <div className="service-box">
                  <div className="service-inner">
                    <div className="service-icon">
                      <div className="icon-sec">
                        <div className="icon-circle">
                          <img src={businessicon} alt="service-icon" />

                        </div>
                      </div>
                      <div className="service-para-main">
                        <h3>Business Process Optimization</h3>

                      </div>
                    </div>
                    <div className="service-content ">
                      <p>We process engineering techniques by leveraging AI-driven insights for optimized workflows. Our operations help in analyzing your processes by identifying bottlenecks and eliminating them effectively with the implementation of intelligent automation solutions.   </p>
                    </div>
                    <div className="button-sec btn-align">
                      <a href="#" class="btn-inner-sec">
                        <span className="btn-name">Go to Services</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </div>

              </div>




            </div>
          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* service section end */}

      {/* Home our project section start */}
      <div className="home-service-sec home-projt-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head">

                <p className="short-head-line">Our Projects</p>
                <h2>With <span className="orange-bold">100</span> and counting successful projects and <span className="blue-bold">12</span> years of solid experience</h2>
                <p> Are you willing to embark on this journey with us?</p>

              </div>
            </div>
          </div>
          <div className="projet-category-sec">
            <div className="row">



              <div className="col-md-6">

                <div className="project-box project-bg">
                  <p>
                    As we adopt new technological approaches, our offerings have evolved too. Want to harness the latest advancements like our clients?
                  </p>
                  <div className="button-sec btn-align btn-white">
                    <a href="#" class="btn-inner-sec">
                      <span className="btn-name">Get In Touch</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">

                <div className="project-box project-box-blue">
                  <p>
                    Are you looking to leverage our twelve-year track record with a new chapter in serving your ideas with new-age technology?
                  </p>
                  <div className="button-sec btn-align btn-white">
                    <a href="#" class="btn-inner-sec">
                      <span className="btn-name">Reach Us</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </a>
                  </div>
                </div>

              </div>


            </div>

          </div>
        </div>
        <div className="bg-icon-belowprojt">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* Home our project section end */}

      {/* vision section start */}


      <div className="home-service-sec vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="vision-content">
                <h2>Our Vision</h2>
                <p>We aim to bring together the best of technology and the
                  best people to progressively supercharge school processes</p>
              </div>

            </div>
            <div className="col-md-6">
              <div className="vision-image-bg">
                <div className="bg-icon-belowservicess">
                  <img src={bgimagedots} alt="bg-icon" />
                </div>
              </div>

              <div className="vision-image">
                <img src={visionimg} />
              </div>

            </div>
          </div>
        </div>
        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>


      {/* vision section end */}

      <Footer />
    </>
  )
}

export default Home;
