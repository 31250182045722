import footerlogo from '../assets/img/IVD-Digital-white.png';
import facebookimg from '../assets/img/facebook.png';
import twitterimg from '../assets/img/twitter.png';
import linkedinimg from '../assets/img/linkedin.png';
import EmailIcon from '@mui/icons-material/Email';
import mapimg from '../assets/img/map-icon.png';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Footer = () => {

  return (
    <>
      <div className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer-left-sec">
                {/* <div className='footer-left-inner'> */}
                <img src={footerlogo} alt="footer-logo" />


                
                    <ul className='social-icon'>

                      <li><a href="#" target="_blank"><img src={facebookimg} /></a></li>
                      <li><a href="#" target="_blank"><img src={twitterimg} className='twit-sec' /></a></li>
                      <li><a href="#" target="_blank"><img src={linkedinimg} /></a></li>
                    </ul>

                    <div className="map-sec">
                      <img src={mapimg} />
                      <p>Tower 1, 15th Floor, Module - 5B, Bengal Eco Intelligent Park, Block EM, Plot No 3, Sector V, Salt Lake. Kolkata 700091, India.</p>
                    </div>
                    <div className='map-contect'>
                      <ul>
                        <li> <EmailIcon /><p>support@ivdisplays.com</p></li>
                        <li> <LocalPhoneIcon /><p>033-40033398</p></li>
                      </ul>
                    </div>
                
              </div>
            </div>
            <div className="col-md-3">
              <div className='footer-mid-sec'>
                <h3>Menu</h3>

                <ul>
                  <li><a href="#">Home</a></li>
                  <li><a href="#">About Us</a></li>
                  <li><a href="#">Services</a></li>
                  <li><a href="#">Products</a></li>
                  <li><a href="#">Blogs</a></li>
                  <li><a href="#">Case Studies</a></li>
                  <li><a href="#">Career</a></li>
                  <li><a href="#">Contact Us</a></li>
                </ul>

              </div>

            </div>
            <div className="col-md-4">

              <div className='footer-right-sec'>
                <h3>Newsletter</h3>

                <div className='email-sec'>
                  <p>Sign Up Your Newsletter</p>
                  <form>
                    <input type='email' placeholder='Email Address' className='inputemail' />

                    <button>
                      <div className="button-sec btn-align email-btn">
                        <a href="#" class="btn-inner-sec btn-inner-secss">
                          <span className="btn-name">Send</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </button>

                  </form>
                </div>

                <ul>
                  <li><a href="#">Privay Policy</a></li>
                  <li><a href="#">Terms & Conditions</a></li>
                </ul>

                <div className='copy-sec'>
                  <p>@2025 Copyright. All right reserved</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default Footer;
