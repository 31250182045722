import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import thankyouimg from '../assets/img/thankyou.png';
import EastIcon from '@mui/icons-material/East';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import {
  verify
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import { isComingSoon, generateUniqueToken } from "../utils/helper";



import classNames from "classnames";
import secureLocalStorage from "react-secure-storage";

const Thankyou = () => {
  const { slug } = useParams();
  const [submitting, setSubmitting] = React.useState(false);
  let navigate = useNavigate();
  const [verifymn, setVerify] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  useEffect(() => {
    setSubmitting(true);
    verify({ id: slug })
      .then((response) => {
        setSubmitting(false);
        if (response.data.status) {
          // alert('Your Mail is Verified');
          setVerify(true);
          setTimeout(() => {
            navigate('/');
          }, 2000);

        } else {
          setErrorMessage(response.data.message);
          setTimeout(() => {
            navigate('/');
          }, 2000);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        setErrorMessage('Something went wrong. Please try again later.');
      });



  },[]);

  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner policy-banner error-page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className='thanksuou'>
                  {/* <div class="success-animation">
                            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                       </div> */}
                  <img className="thankyouimg" src={thankyouimg} />

                  <h2>Thank You !!</h2>
                  {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                  {(submitting==true ) &&
                    <>
                      <p className="thanks-para">Your Mail is Verifying </p>
                    </>

                  }
                  {( verifymn==true) &&
                    <>
                      <p className="thanks-para">Your Mail is Verified </p>
                    </>

                  }
                  {/* {(errorMessage==true) &&
                    <>
                      <p className="thanks-para">{errorMessage}</p>
                    </>

                  } */}
                  <div className="button-sec btn-align btn-aror-algn back-btnn">
                    <Link to={`/`} class="btn-inner-sec">
                      <span className="btn-name">Back To Home</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Thankyou