
import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";
import bannerimg from '../assets/img/new-img-ivd/career_final.webp';



const Career = () => {
  return (

    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">

                  <h1>Current Openings</h1>
                  <p>Add Wings to your Career</p>

                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">

                  <img src={bannerimg} alt="homeimg" />


                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {/* post job opening details here start */}
      <div className="job-sec about-innov-main">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Open Job Position</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="job-inner-sec">
                <div className="job-inner-left">
                  <div className="job-desc-new">
                    <h3>Digital Marketing</h3>
                    <div className="jon-loc">
                      <LocationOnIcon />
                      <span>Kolkata</span>
                    </div>
                    <div className="job-desc">
                      <div className="job-desc-left">
                        <CalendarMonthIcon />
                        <span>17 Feb, 2025</span>
                      </div>
                      <div className="job-desc-right">
                        <PersonIcon />
                        <span>No of Vacancies 01</span>
                      </div>
                    </div>
                    <div className="apply-sec">
                      <Link to="/jobdetails/digital-marketing" className="apply-btn-cont">Apply Now<span className="apply-btn"><EastIcon /></span></Link>
                    </div>
                  </div>
                </div>
                <div className="job-inner-right">

                  <div className="job-desc-new">
                    <h3>Business Growth Consultant</h3>
                    <div className="jon-loc">
                      <LocationOnIcon />
                      <span>Kolkata</span>
                    </div>
                    <div className="job-desc">
                      <div className="job-desc-left">
                        <CalendarMonthIcon />
                        <span>17 Feb, 2025</span>
                      </div>
                      <div className="job-desc-right">
                        <PersonIcon />
                        <span>No of Vacancies 01</span>
                      </div>
                    </div>
                    <div className="apply-sec">
                      <Link to="/jobdetails/business-growth-consultant" className="apply-btn-cont">Apply Now<span className="apply-btn"><EastIcon /></span></Link>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="job-inner-sec">
                <div className="job-inner-left">
                  <div className="job-desc-new">
                    <h3>Customer Acquisition Expert</h3>
                    <div className="jon-loc">
                      <LocationOnIcon />
                      <span>Kolkata</span>
                    </div>
                    <div className="job-desc">
                      <div className="job-desc-left">
                        <CalendarMonthIcon />
                        <span>17 Feb, 2025</span>
                      </div>
                      <div className="job-desc-right">
                        <PersonIcon />
                        <span>No of Vacancies 02</span>
                      </div>
                    </div>
                    <div className="apply-sec">
                      <Link to="/jobdetails/customer-acquisition-expert" className="apply-btn-cont">Apply Now<span className="apply-btn"><EastIcon /></span></Link>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>



        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* post job opening details here end */}
      <Footer />
    </>

  )
}

export default Career;
