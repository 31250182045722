import React, { useState, useEffect } from "react";
import imglogo from '../assets/img/ivd-logo.png';
import { Link, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Header = () => {

  const baseUrl = "https://www.ivdisplays.com"; // Replace with your domain

  const location = useLocation();

  const isActiveMenu = (paths) => {
    console.log(location.pathname);
    console.log(paths);
    return paths.some((path) => location.pathname.startsWith(path));
  };
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {


    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
    const [isActive, setIsActive] = useState(false);
  
    const handleClick = () => {
      setIsActive(!isActive);
     
    };
  return (
    <>
    <div>
          <Helmet>
            {(location.pathname !== "/") && <link rel="canonical" href={`${baseUrl}${location.pathname}`} />}
    
            {location.pathname == '/' && <title>Transformative Digital Solutions for Education | Ivdisplays</title>}
        {location.pathname == '/' && <meta name="keyword" content="Digital Solutions for education, Software's for schools, AI Integration for education, AI Chatbots for schools." />}
        {location.pathname == '/' && <meta name="description" content="Give your institution the technology boost it requires. We have reformed over 50 education institutions over 12 years, it is time you gave us a chance to work with you." />}
        {location.pathname == '/about' && <title>About Us | Team Behind Ivdiplays | Ivdisplays Digital Services Pvt Ltd</title>}
        {location.pathname == '/about' && <meta name="keyword" content="Ivdisplays, Ivdisplays Digital Services, Vinay Menon Founder, Team behind Ivdisplays" />}
        {location.pathname == '/about' && <meta name="description" content="Ivdisplays in its 12th year is a digital transformation company helping education businesses and institutions to modernize their processes and reform their ways of working." />}

        {location.pathname == '/services' && <title>Customised Software & AI Solutions for Your Business</title>}
        {location.pathname == '/services' && <meta name="keyword" content="AI Services for education, Bespoke software development for schools, Mobility software solutions, Secured Infrastructure Roll out and Support, AI Chatbot, Chatbot for schools, Improve administrative task, staff attendance augmentation, workshop management system, asset tracking system for schools, eform system, action form, budget transfer form." />}
        {location.pathname == '/services' && <meta name="description" content="Discover our comprehensive software and AI services designed to elevate your business. Tailored solutions to meet your unique needs and drive innovation." />}

        {location.pathname == '/casestudies' && <title>Success Stories of Ivdisplays | Case Studies of successful Implementation | Ivdisplays</title>}
        {location.pathname == '/casestudies' && <meta name="keyword" content="Success Stories of Ivdisplays technology projects, software projects delivered by Ivdisplays, Case Studies of software projects implemented in schools." />}
        {location.pathname == '/casestudies' && <meta name="description" content="Over the last 7 years Ivdisplays have successfully delivered over 50 technology projects at various schools to ease administrative task. Listed are some of the most successful software implementation case studies." />}


        {location.pathname == '/blog' && <title>Posts on Administrative Task Improvements | AI Integration for education</title>}
        {location.pathname == '/blog' && <meta name="keyword" content="blog posts on AI Chatbot for schools, Learning Driven Classrooms, Chatbots for school classrooms, AI Processes for schools" />}
        {location.pathname == '/blog' && <meta name="description" content="Discover interesting post on custom Software & AI Solutions for education | Gain insights and stay ahead in the changing tech landscape." />}

        {location.pathname == '/career' && <title>Find Interesting Career Openings | Job Opportunities at Ivdisplays |</title>}
        {location.pathname == '/career' && <meta name="keyword" content="Interesting career openings at ivdisplays, hiring at ivdisplays, how to apply for jobs at ivdisplays, job opportunities, job openings" />}
        {location.pathname == '/career' && <meta name="description" content="Applying with us is adding wings to your career. Scout for an opportunity which fits your profile best. We are hiring.." />}

        {location.pathname == '/contact' && <title>Contact Us | Ivdisplays</title>}
        {location.pathname == '/contact' && <meta name="keyword" content="Where is Ivdisplays located, current location of ivdisplays digital" />}
        {location.pathname == '/contact' && <meta name="description" content="Currently we operate from our Kolkata location serving global clients, check our coordinates here" />}
      
        {location.pathname == '/casestudiesdetails/enterprise-resource-planning' && <title>Progressing With ERP in Schools | ERP For schools | Ivdisplays </title>}
        {location.pathname == '/casestudiesdetails/enterprise-resource-planning' && <meta name="keyword" content=" Successful Implementation of ERP in school, ERP readiness for school implementation, Eform System for schools, action forms, budget transfer forms" />}
        {location.pathname == '/casestudiesdetails/enterprise-resource-planning' && <meta name="description" content=" Implementing a ERP in education or institution or in a enterprise isn't a mean feat, we did it with careful planning and being better organised. Read the case study to gain an insight." />}
        
        
        {location.pathname == '/casestudiesdetails/absence-reporting-portal' && <title>Leave Management System | Absence Reporting Portal for staffs | Ivdisplays </title>}
        {location.pathname == '/casestudiesdetails/absence-reporting-portal' && <meta name="keyword" content="A Leave Management system for schools staffs, Customisable, successful deployment " />}
        {location.pathname == '/casestudiesdetails/absence-reporting-portal' && <meta name="description" content="Deploying an absence reporting system for a leading district public school. With this new system the leadership team can now augment staff management efficiently. The features are just enough for staff and leaderhip team to work together on absenteeism." />}
        
        
        
        {location.pathname == '/casestudiesdetails/workshop-management-system' && <title>Case Study to manage your school workshops better | Ivdisplays </title>}
        {location.pathname == '/casestudiesdetails/workshop-management-system' && <meta name="keyword" content=" Workshop Management System, Training Management system for schools and education, Announcing upcoming training to schools staffs." />}
        {location.pathname == '/casestudiesdetails/workshop-management-system' && <meta name="description" content="Case study of successful implementation of a workshop management system, Benefits of having a training management system are immense, it improves staff participation in training programs, read our case study here" />}
        

        {location.pathname == '/casestudiesdetails/inventory-management-system' && <title> Learn how an Inventory Management System benefits schools | Ivdisplays </title>}
        {location.pathname == '/casestudiesdetails/inventory-management-system' && <meta name="keyword" content="Benefits of Inventory Management System, tracking of school assets, Asset Management System, implementation, case studies, success stories." />}
        {location.pathname == '/casestudiesdetails/inventory-management-system' && <meta name="description" content=" A inventory management system or an asset management system should be an integral part of the technology integration in education domain. An asset tracker is a must since most schools are very heavy on asset build up. Read our success story here" />}
        
          </Helmet>
       <div className={`header-sec ${isScrolled ? "scrolled" : ""}`}>
              <div className='container'>
                <div className='row'>
      
      
      
                  <nav class="navbar navbar-expand-lg navbar-light ">
                    <Link class="navbar-brand" to="/">
                      <img src={imglogo} alt='logo' />
                      <p className="imglogbet">(Beta)</p>
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      {/* <span class="navbar-toggler-icon"></span> */}
                      <span   onClick={handleClick}   className={`navTrigger ${isActive ? "active" : ""}`} >
                      <i></i>
                      <i></i>
                      <i></i>
                  </span>
                    </button>
                    
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav mb-2 mb-lg-0">
                        <li class="nav-item nav-menu">
                          <NavLink to="/" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Home</NavLink>
      
                        </li>
      
                        <li class="nav-item nav-menu">
                          <NavLink to="/about" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>About Us</NavLink>
                        </li>
                        <li class="nav-item nav-menu">
                          <NavLink to="/services" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Services</NavLink>
                        </li>
                        <li class="nav-item nav-menu" >
                          <NavLink to="/products" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/productdetails']) ? 'active' : ''} `}>
                            Products
                          </NavLink>
                        </li>
      
                        <li class="nav-item nav-menu">
                          <NavLink to="/casestudies" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/casestudiesdetails']) ? 'active' : ''}`}>Case Studies</NavLink>
                        </li>
                        <li class="nav-item nav-menu">
                          <NavLink to="/blog" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/blogdetails']) || isActiveMenu(['/blogcategory']) ? 'active' : ''} `}>Blog</NavLink>
                        </li>
      
                        <li class="nav-item nav-menu">
                          <NavLink to="/career" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/jobdetails']) ? 'active' : ''}`}>Career</NavLink>
                        </li>
                        <li class="nav-item nav-menu">
                          <NavLink to="/contact" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Contact Us</NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
      </div>
    </>
  )
}

export default Header;
