import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import projtimgvideo from '../assets/img/project-video-img.png';
import videoplayimg from "../assets/img/icon-play.png";
import benekeyone from "../assets/img/reduce_cost_increase_efficency.png";
import benekeytwo from "../assets/img/Quicker_decision_making_Approval_processes.png";
import benekeythree from "../assets/img/key_update.png";
import EastIcon from '@mui/icons-material/East';
import { useSelector } from "react-redux";

import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";
import { Media, Video } from '@vidstack/player-react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';

import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import { isComingSoon, generateUniqueToken } from "../utils/helper";



import classNames from "classnames";
import secureLocalStorage from "react-secure-storage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const Casestudiesdetails = () => {
  let navigate = useNavigate();
  const { casestudiesData } = useSelector((state) => state.pageContents);
  console.log(casestudiesData);
  const { slug } = useParams();
  const [allprodata, setAllprodata] = useState(null);
  const [allprodataindex, setAllprodataindex] = useState(null);
  const [newprofda, setNewprofda] = useState('');
  useEffect(() => {
    if (casestudiesData.casestudies.length > 0) {
      var proal = casestudiesData.casestudies;
      var res = proal.map((item, indexc) => {
        if (item.product_details.slug == slug) {
          console.log(item);
          setAllprodataindex(indexc);
          setAllprodata(item);
        }


      });
    };



  }, []);

  useEffect(() => {
    if (casestudiesData.casestudies.length > 0) {
      var proal = casestudiesData.casestudies;
      var res = proal.map((item, indexc) => {
        if (item.product_details.slug == newprofda) {
          console.log(item);
          setAllprodataindex(indexc);
          setAllprodata(item);
        }


      });
    };



  }, [newprofda]);
  const handleclickmenu = (e) => {


    if (casestudiesData?.casestudies.length > 0) {
      let count = 0;
      let countf = 0;
      var tgitle = '';
      let str = casestudiesData.casestudies.map(function (el) {
        var o = Object.assign({}, el);
        // if (o.product_details.slug == slug) {

        //   count = 1;



        // }
        //  alert(allprodataindex);
        if (countf == (allprodataindex + 1)) {

          setNewprofda(o.product_details.slug);
          navigate(`/casestudiesdetails/${o.product_details.slug}`);



        }

        countf++;

      });
    }
  };
  const handleclickmenuback = (e) => {
    if (casestudiesData?.casestudies.length > 0) {
      let count = 0;
      let countf = 0;
      var tgitle = '';
      var frslug = '';
      var bcknew = allprodataindex - 1;

      let str = casestudiesData.casestudies.map(function (el) {
        var o = Object.assign({}, el);

        if (countf == bcknew) {

          setNewprofda(o.product_details.slug);
          navigate(`/casestudiesdetails/${o.product_details.slug}`);


        }

        countf++;

      });
    }
  };
  const [videof, setVideof] = React.useState('');
  const [imgfallvid, setImgfallvid] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (image, video) => {
    setImgfallvid(image);
    setVideof(video);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleDialogOffChange = (isOpen) => {
    setIsOpen(false)
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen);

    var casedeat = { ...formValues };

    casedeat.casestudyname = allprodata.product_details.title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'bookdemocasestudey';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    console.log(casedeat);
    setFormValues(casedeat);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');




  }





  const recaptchaRef = React.useRef();

  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'bookdemocasestudey',
    message: '',
    business_name: '',
    system: '',
    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);


  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenbookdemocasestudey = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = allprodata.product_details.title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'bookdemocasestudey';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [openthank, setOpenthank] = React.useState(false);
  const handleClickOpenthank = () => {

    setOpenthank(true);
  };
  const handleClosethank = () => {
    setOpenthank(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname'
        && key != 'sh_date_new' && key != 'sh_time_new'
        && key !== 'business_name' && key !== 'system'
      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      } else if (formValues.contact_us == 'enterprise' && key === 'business_name' && !formValues[key]) {
        isValid = false;
        errors[key] = 'This field is required';
      }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          // setOpenthank(true);
          navigate('/thankyou/casestudies');
          setTimeout(() => {

            // setOpenthank(false);
          }, 3000);

          if (pageselect == 'bookdemocasestudey') {
            // navigate('/thankyou/talktoleadership');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner main-detsila-two">
          <div className="container">
            <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec main-bannerinner">
                {allprodata != null &&

                  <>
                    <h1>{allprodata.banner_title} </h1>
                  </>
                }

              </div>
            </div>

          </div>
        </div>
      </div>

      {/* case studies video section start */}
      <div className="case-video-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="case-video-main">
              <div className="case-video-main-inner">

                <div className="case-video-left">
                  <div className="case-video-box">
                    {allprodata != null &&

                      <>
                        <p>{allprodata.subtitle}</p>

                      </>
                    }


                  </div>
                </div>
                <div className="case-video-right">
                  <div className="case-vieo-inner">
                    {allprodata != null &&

                      <>
                        <img src={process.env.REACT_APP_IMAGE_URL + allprodata.sec_title_three} onClick={() => handleClickOpen(allprodata.watch_image, allprodata.watch_video)} />

                      </>
                    }


                    <div className="play-sec-prdt">
                      <a id="play-video" class="video-play-button" href="#">
                        <img src={videoplayimg} onClick={() => handleClickOpen(allprodata.watch_image, allprodata.watch_video)} />
                      </a>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />

        </div>
      </div>
      {/* case studies video section end */}

      {/* business challenge section start */}
      <div className="home-service-sec about-innov-main busn-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head business-sec details-new">
                {allprodata != null &&

                  <>

                    <h2> {allprodata.short_sub_title}</h2>
                    <h3>{allprodata.short_sub_des}</h3>
                    <p>{allprodata.short_sub_subdes}</p>


                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* business challenge section end */}

      {/* key business benefits section start */}
      <div className="case-video-sec busn-main-sec key-main-secss">
        <div className="container">
          {

            (allprodata != null && allprodata?.casestudy_key_benefits.length) &&
            <>
              {allprodata.product_details.slug != 'absence-reporting-portal'
                &&

                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="busn-key-head">
                        <h2>Key  Benefits</h2>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          }
          <div className="row">
            {

              (allprodata != null && allprodata?.casestudy_key_benefits.length) &&
              <>
                {allprodata.product_details.slug == 'absence-reporting-portal' ?

                  <>
                    {allprodata.casestudy_key_benefits.map((keysd, index) => (


                      <div className="home-service-sec differ-service-sec absentcase">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="differ-service-inner left-side-head-before new-case-add">

                                <h2>{allprodata.key_title}</h2>
                                <p> {keysd.title}
                                </p>


                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="service-img-right">
                                <div className="vision-image-bg sev-main-bg">
                                  <div className="bg-icon-belowservicess serv-bg-iconrigt">
                                    <img src={bgimagedots} alt="bg-icon" />
                                  </div>
                                </div>

                                <div className="serv-img-inner">
                                  <img src={process.env.REACT_APP_IMAGE_URL + keysd.icon} />
                                </div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    ))}
                  </>
                  :
                  <>
                    {allprodata.casestudy_key_benefits.map((keysd, index) => (


                      <div className="col-md-4">
                        <div className="key-benefits-sec">
                          <img src={process.env.REACT_APP_IMAGE_URL + keysd.icon} />
                          <h3>{keysd.title}</h3>
                        </div>
                      </div>
                    ))}
                  </>

                }


              </>

            }


          </div>
        </div>
      </div >
      {/* key business benefits section end */}

      {/* call to action section start */}
      <div className="callactn-sec consult-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">

              <div className="case-bg-icons">
                <img src={bgimagedots} alt="bg-icon" />
              </div>

              <div className="callactn-content consult-content">

                {allprodata != null &&

                  <>

                    <p>{allprodata.consult_title}</p>

                  </>
                }
                <div className="button-sec red-button-sec cal-btn-sec consult-btn">
                  <a href="#" class="btn-inner-sec  btn-white" onClick={handleDialogChange}>
                    <span className="btn-name">Get a Free Consultation</span>
                    <span className="btn-icon"><EastIcon /></span>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* call to action section end */}

      {/* Resolution section start */}

      <div className="impact-sec resol-sec">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {allprodata != null &&

                <>

                  <h2>{allprodata.consult_sub_title}</h2>
                  <p>{allprodata.sec_title_one}</p>

                </>
              }

            </div>
          </div>
          <div className="resol-inner-sec">
            {allprodata != null &&

              <>


                <div dangerouslySetInnerHTML={{ __html: allprodata.sec_title_des_one }}></div>
              </>
            }

          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>

      {/* Resolution section end */}
      {/* outcome section start */}
      <div className="outcome-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="outcome-head">
                {allprodata != null &&

                  <>

                    <h2>{allprodata.sec_title_two}</h2>

                    <div dangerouslySetInnerHTML={{ __html: allprodata.sec_title_des_two }}></div>
                  </>
                }

              </div>
            </div>
          </div>
        </div>
        {/* prev ad next section start */}
        <div className="prev-next-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="pre-btnss">
                  {allprodata != null &&
                    <>
                      {allprodataindex != 0 &&




                        <a href="#" className="prev-main-sec"
                          onClick={(e) => handleclickmenuback()}
                        >
                          <div class="arrows-container">
                            <div class="arrownewss arrownewwprev arrow-one">
                            </div>
                            <div class="arrownew arrownewwprevsec arrow-two">
                            </div>


                          </div>
                          <span className="btn-name">Previous  Case Study</span>


                        </a>
                      }

                      {casestudiesData.casestudies.length > (allprodataindex + 1) &&


                        <a className="next-main-sec"
                          onClick={(e) => handleclickmenu()}
                        >

                          <span className="btn-name" >Next  Case Study</span>
                          <div class="arrows-container">
                            <div class="arrownewss arrow-one">
                            </div>
                            <div class="arrownew arrow-two">
                            </div>
                          </div>
                        </a>

                      }

                    </>
                  }


                </div>
              </div>
            </div>
          </div>
        </div>
        {/* prev and next sectione end */}
      </div>
      {/* outcome section end */}
      <Footer />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title" className="prodt-modl-news videoplay-modl"
        open={open}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Modal title
        </DialogTitle> */}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {(videof != null && imgfallvid != '') &&
              <Media style={{ width: "100%" }}>
                <Video autoplay="true" loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid}>
                  <video loading="visible" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid} autoplay="true" src={process.env.REACT_APP_IMAGE_URL + videof} preload="none" data-video="0" controls />
                </Video>
              </Media>

            }
          </Typography>

        </DialogContent>

      </BootstrapDialog>

      {/* modal new start from here */}

      <BootstrapDialog
        onClose={handleDialogOffChange}
        aria-labelledby="customized-dialog-title"
        open={isOpen} className="prodt-modl-news new-respon-case"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOffChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <h2>Create A Winning Story With Us</h2>
          <div className="form-sec-main">
            {/* <h2>Book a Free Demo</h2> */}
            <div className='form-sec form-modal form-radio-sec'>
              <FormControl>
                <FormLabel className="radio-sec-label">Are you an </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="individual" control={<Radio />} label="individual"
                    name="contact_us"
                    checked={formValues.contact_us == 'individual'}
                    onChange={handleInputChange}
                  />
                  <FormControlLabel value="enterprise" control={<Radio />} label="enterprise"
                    name="contact_us"
                    checked={formValues.contact_us == 'enterprise'}
                    onChange={handleInputChange}
                  />
                </RadioGroup>
              </FormControl>
              {
                formErrors.contact_us &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.contact_us}
                  </div>
                </div>
              }
            </div>
            {formValues.contact_us == 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Business Name " variant="outlined"

                      name="business_name"
                      className={classNames("form-control", { 'is-invalid': formErrors.business_name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.business_name}


                    />
                    {
                      formErrors.business_name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.business_name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Work Email" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }

            {formValues.contact_us != 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>



                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }


            <div className='form-sec form-modal'>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                noValidate
                autoComplete="off"
              >

                <TextField
                  id="outlined-multiline-static"
                  label="Your Requirement in Brief"
                  multiline
                  rows={2}
                  placeholder=""
                  name="message"
                  className={classNames("form-control", { 'is-invalid': formErrors.message })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.message}
                />
                {
                  formErrors.message &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.message}
                    </div>
                  </div>
                }
              </Box>

            </div>








            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrors.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrors.recaptcha}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
              <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>

          <div className='validate-sec validate-sec-mode'>
            {
              successMessage &&
              <div className="success-msg-sec">
                <p className="succ-msg">{successMessage}
                </p>
              </div>
            }
            {
              errorMessage &&
              <div className="error-msg-sec">
                <p className="error-msg">{errorMessage}
                </p>
              </div>
            }
          </div>




        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!


        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
          className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          <div className="otp-content-sec">
            <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
            </p>
          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>



            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleClosethank}
        aria-labelledby="customized-dialog-title"
        open={openthank} className="newotp-sec thanksuou"
      >


        <DialogContent dividers>
          <div class="success-animation">
            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


        </DialogContent >

      </BootstrapDialog >
      {/* modal new end from here */}

    </>
  )
}

export default Casestudiesdetails;
