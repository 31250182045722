import React, { useState, useRef, useEffect } from "react";
import footerlogo from '../assets/img/IVD-Digital-white.png';
import facebookimg from '../assets/img/facebook.png';
import twitterimg from '../assets/img/twitter.png';
import linkedinimg from '../assets/img/linkedin.png';
import EmailIcon from '@mui/icons-material/Email';
import mapimg from '../assets/img/map-icon.png';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  newslet
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import { isComingSoon, generateUniqueToken } from "../utils/helper";



import classNames from "classnames";
import secureLocalStorage from "react-secure-storage";

const Footer = () => {
  const { contactData } = useSelector((state) => state.pageContents);
  console.log(contactData);



  const formFields = {

    email: '',



  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };

      newslet({ email: formValues.email })
        .then((response) => {
          setSubmitting(false);
           console.log(response.data);
          if (response.data.status) {
            setFormValues(formFields);
            setSuccessMessage('Your subscription is successful');
          } else {
            setFormValues(formFields);
            setErrorMessage(response.data.message);
          }
          setTimeout(() => {
            setSuccessMessage('');
            setErrorMessage('');
          }, 2000);
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }


    });

    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }
  return (
    <>
      <div className="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-5 footer-main">
              <div className="footer-left-sec">
                {/* <div className='footer-left-inner'> */}
                <img src={footerlogo} alt="footer-logo" />


                {
                  contactData?.contactDetails &&
                  <>
                    <ul className='social-icon'>

                      <li><a href={contactData.contactDetails.facebook} target="_blank"><img src={facebookimg} /></a></li>
                      <li><a href={contactData.contactDetails.twitter} target="_blank"><img src={twitterimg} className='twit-sec' /></a></li>
                      <li><a href={contactData.contactDetails.linkdin} target="_blank"><img src={linkedinimg} /></a></li>
                    </ul>

                    <div className="map-sec">
                      <img src={mapimg} />
                      <p>{contactData.contactDetails.address}</p>
                    </div>
                    <div className='map-contect'>
                      <ul>
                        <li> <EmailIcon /><p>{contactData.contactDetails.Sec_mail}</p></li>
                        <li> <LocalPhoneIcon /><p>{contactData.contactDetails.phone}</p></li>
                      </ul>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className="col-md-3">
              <div className='footer-mid-sec'>
                <h3>Menu</h3>

                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/services">Services</Link></li>
                  <li><Link to="/products">Products</Link></li>
                  <li><Link to="/blog">Blogs</Link></li>
                  <li><Link to="/casestudies">Case Studies</Link></li>
                  <li><Link to="/career">Career</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>

              </div>

            </div>
            <div className="col-md-4">

              <div className='footer-right-sec'>
                <h3>Newsletter</h3>

                <div className='email-sec'>
                  <p>Sign Up Your Newsletter</p>
                  <form>
                    <input type='email' placeholder='Email Address' name="email"
                      className={classNames("inputemail form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                 

                    <button>
                      <div className="button-sec btn-align email-btn">
                        <a href="#" class="btn-inner-sec btn-inner-secss" onClick={handleSubmit}>
                          <span className="btn-name">Send {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>}</span>
                          {submitting == false && <span className="btn-icon"><EastIcon /></span>}

                        </a>
                      </div>
                    </button>
                    {
                      formErrors.email &&
                      <div className="invalid-feedback footer-fedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                    <div className='validate-sec validate-sec-mode'>
                      {
                        successMessage &&
                        <div className="success-msg-sec">
                          <p className="succ-msg">{successMessage}
                          </p>
                        </div>
                      }
                      {
                        errorMessage &&
                        <div className="error-msg-sec">
                          <p className="error-msg">{errorMessage}
                          </p>
                        </div>
                      }
                    </div>
                  </form>
                </div>

                <ul>
                  <li><Link to="/privacy-policy">Privay Policy</Link></li>
                  <li><Link to="/terms-condition">Terms & Conditions</Link></li>
                </ul>

                <div className='copy-sec'>
                  <p>@2025 Copyright. All right reserved</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default Footer;
