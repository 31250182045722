
import React, { useState, useEffect } from "react";
import imglogo from '../assets/img/ivd-logo.png';
import { Link, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, NavLink, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
const Header = () => {
  const baseUrl = "https://www.ivdisplays.com"; // Replace with your domain

  const location = useLocation();

  const isActiveMenu = (paths) => {
    return paths.some((path) => location.pathname.startsWith(path));
  };
  const [allprodata, setAllprodata] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const { homeData } = useSelector((state) => state.pageContents);
  // console.log(homeData.allpage);
  useEffect(() => {


    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const { slug } = useParams();
  useEffect(() => {
    console.log(location.pathname);

    var loc = location.pathname;
    if (homeData.allpage.length > 0) {
      var proal = homeData.allpage;
      var res = proal.map((item, indexc) => {
        if (item.page_name == 'Home' && loc == '/') {

          setAllprodata(item);
        } else if (item.page_name == 'About' && loc == '/about') {
          console.log(item.meta_key);
          setAllprodata(item);
        } else if (item.page_name == 'Case Studies' && loc == '/casestudies') {
          console.log(item);
          setAllprodata(item);
        } else if (item.page_name == 'Career' && loc == '/career') {
          setAllprodata(item);
        } else if (item.page_name == 'Contact Us' && loc == '/contact') {
          setAllprodata(item);
        }
        else if (item.page_name == 'Services' && loc == '/services') {
          setAllprodata(item);

        }else if (item.page_name == 'Blogs' && loc == '/blog') {
          setAllprodata(item);
        }
        else if (item.page_name == 'Products' && loc == '/products') {
          setAllprodata(item);
        }
      });

    };
    if (homeData.casestudyseo.length > 0) {
      var proal = homeData.casestudyseo;
      var res = proal.map((item, indexc) => {
        if (item.page_name == slug) {

          setAllprodata(item);
        }



      });

    };



  });


  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
   
  };

  return (
    <><div>
      <Helmet>
        {(location.pathname !== "/") && <link rel="canonical" href={`${baseUrl}${location.pathname}`} />}

        {allprodata != null &&
          <title>{allprodata.meta_title}</title>
        }


        {allprodata != null &&
          <meta name="keyword" content={allprodata.meta_key} />
        }

        {allprodata != null &&
          <meta name="description" content={allprodata.meta_des} />

        }

      </Helmet>
      <div className={`header-sec ${isScrolled ? "scrolled" : ""}  ${isActive ? "activeheader" : ""}`} >
        <div className='container'>
          <div className='row'>



            <nav class="navbar navbar-expand-lg navbar-light ">
              <Link class="navbar-brand" to="/">
                <img src={imglogo} alt='logo' />
                <p className="imglogbet">(Beta)</p>
              </Link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                {/* <span class="navbar-toggler-icon"></span> */}
                <span   onClick={handleClick}   className={`navTrigger ${isActive ? "active" : ""}`} >
                <i></i>
                <i></i>
                <i></i>
            </span>
              </button>
              
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mb-2 mb-lg-0">
                  <li class="nav-item nav-menu">
                    <NavLink to="/" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Home</NavLink>

                  </li>

                  <li class="nav-item nav-menu">
                    <NavLink to="/about" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>About Us</NavLink>
                  </li>
                  <li class="nav-item nav-menu">
                    <NavLink to="/services" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Services</NavLink>
                  </li>
                  <li class="nav-item nav-menu" >
                    <NavLink to="/products" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/productdetails']) ? 'active' : ''} `}>
                      Products
                    </NavLink>
                  </li>

                  <li class="nav-item nav-menu">
                    <NavLink to="/casestudies" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/casestudiesdetails']) ? 'active' : ''}`}>Case Studies</NavLink>
                  </li>
                  <li class="nav-item nav-menu">
                    <NavLink to="/blog" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/blogdetails']) || isActiveMenu(['/blogcategory']) ? 'active' : ''} `}>Blog</NavLink>
                  </li>

                  <li class="nav-item nav-menu">
                    <NavLink to="/career" className={({ isActive }) => `nav-link-menu ${isActive || isActiveMenu(['/jobdetails']) ? 'active' : ''}`}>Career</NavLink>
                  </li>
                  <li class="nav-item nav-menu">
                    <NavLink to="/contact" className={({ isActive }) => `nav-link-menu ${isActive ? 'active' : ''}`}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Header;
