import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import blogimg from '../assets/img/blog-details-new.png';
import calngicon from '../assets/img/calendar.png';
import PersonIcon from '@mui/icons-material/Person';
import EastIcon from '@mui/icons-material/East';
import SearchIcon from '@mui/icons-material/Search';
import recentblogimg from '../assets/img/recent-blog-img.png';
import learnerDrivenImg from '../assets/img/blog-image-details-new_m.webp';
import aiInAducationImg from '../assets/img/blog-event-details.webp';
import placeHolderImg from '../assets/img/placeholder-blog.webp';
import blogEvent from '../assets/img/blog-event.webp';
import chatBotImg from '../assets/img/chatbots_in_school_operation.webp';

import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";

const Blogdetails = () => {
    const { slug } = useParams();
    return (
        <>
            <div className="main-bg-sec">
                <Header />
                <div className="main-sec main-innerbanner main-detsila-two">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="banner-sec-content main-banner-sec main-bannerinner">
                                {slug === "can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" &&
                                    <>
                                        <h1>Empowering the Student Learning Journey Through Active Engagement</h1>
                                    </>
                                }

                                {slug === "ai-in-education" &&
                                    <>
                                        <h1>The New Learning Paradigm in Education</h1>
                                    </>
                                }

                                {slug === "chatbots-today-revolutionize-the-school-operational-journey" &&
                                    <>
                                        <h1>Chatbots Today Revolutionize the School Operational Journey</h1>
                                    </>
                                }

                                {slug === "technology-influence-can-foster-exponential-growth-in-educational-institutions" &&
                                    <>
                                        <h1>Technology Influence Can Foster Educational Institutions Grow Exponentially</h1>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* blog details section start */}
            <div className="blog-details-sec home-service-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="blog-details-inner">
                                {slug === "can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" &&
                                    <>
                                        <img src={learnerDrivenImg} />
                                        <div className="blog-tag-details">
                                            <div className="blog-tag-para-new">
                                                <div className="blog-tag-para">
                                                    <p>Learner-Driven Classrooms</p>
                                                </div>
                                            </div>
                                            <div className="blog-detal-date">
                                                <img src={calngicon} /><a>February 14, 2025</a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "ai-in-education" &&
                                    <>
                                        <img src={aiInAducationImg} />
                                        <div className="blog-tag-details">
                                            <div className="blog-tag-para-new">
                                                <div className="blog-tag-para">
                                                    <p>AI in Education</p>
                                                </div>
                                            </div>
                                            <div className="blog-detal-date">
                                                <img src={calngicon} /><a>February 11, 2025</a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "chatbots-today-revolutionize-the-school-operational-journey" &&
                                    <>
                                        <img src={placeHolderImg} />
                                        <div className="blog-tag-details">
                                            <div className="blog-tag-para-new">
                                                <div className="blog-tag-para">
                                                    <p>Chatbots in School Operations</p>
                                                </div>
                                            </div>
                                            <div className="blog-detal-date">
                                                <img src={calngicon} /><a>January 04, 2025</a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "technology-influence-can-foster-exponential-growth-in-educational-institutions" &&
                                    <>
                                        <img src={placeHolderImg} />
                                        <div className="blog-tag-details">
                                            <div className="blog-tag-para-new">
                                                <div className="blog-tag-para">
                                                    <p>School Digital Transformation</p>
                                                </div>
                                            </div>
                                            <div className="blog-detal-date">
                                                <img src={calngicon} /><a>December 04, 2024</a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" &&
                                    <>
                                        <div className="blog-detal-content">
                                            <h2>The Changing Face of Education</h2>

                                            <p>For years, education has largely followed a teacher-led model, where instructors set the agenda, dictate the pace, and drive the learning process. However, as the world shifts towards personalized experiences in nearly every industry, a critical question arises: <span className="blog-para-span"> Can students take charge of their own learning?</span></p>
                                            <p>This shift from a traditional classroom to a learner-driven one is gaining momentum, offering a fresh perspective on how students engage with their education.</p>
                                            <p>The idea of a learner-driven classroom emphasizes student agency—giving students more control over what, how, and when they learn. This approach moves beyond standardized lesson plans and rigid structures, fostering an environment<span className="blog-para-span"> where students become active participants rather than passive recipients of knowledge.</span></p>
                                            <h3>But what does this transformation truly look like? And how can schools effectively implement it?</h3>

                                            <h2>The Concept of a Learner-Driven Classroom</h2>
                                            <p>A learner-driven classroom is built on the principle that students should have a say in their education. It empowers them to make decisions about their learning journey, setting the stage for increased motivation, deeper understanding, and better long-term retention. This concept goes beyond merely allowing students to choose topics of interest—it involves cultivating a learning environment where they set goals, navigate challenges, and take responsibility for their progress.</p>
                                            <p>This approach aligns with the growing recognition that students have diverse learning needs and preferences. A one-size-fits-all teaching model often leaves some students disengaged, while a more flexible, personalized approach helps cater to different learning styles and abilities.</p>

                                            <h2>Key Elements of a Learner-Driven Approach</h2>
                                            <h3>1. Goal-Setting: Encouraging Ownership of Learning</h3>
                                            <p>When students set their own learning goals, they feel more connected to their educational journey. This sense of ownership fosters intrinsic motivation, making learning more meaningful.</p>
                                            <p>Teachers play a crucial role here—not by dictating what students must achieve, but by guiding them in setting realistic and challenging goals that align with their interests and future aspirations.</p>

                                            <h3>2. Learner Profiles: Understanding Student Strengths</h3>
                                            <p>A learner-driven approach requires recognizing that every student has unique strengths, weaknesses, and interests. By developing learner profiles—detailed insights into a student’s skills, challenges, and learning preferences—educators can tailor instruction and provide more relevant resources to enhance individual growth.</p>

                                            <h3>Engaging Students in the Process</h3>
                                            <p>Educators can actively involve students in the creation and maintenance of learner profiles using tools like surveys, empathy interviews, For learners, the following prompts can guide meaningful self-reflection:</p>

                                            <ul>
                                                <li>
                                                    <h4>I will do it</h4>
                                                    <p>Instead of simply identifying strength it assesses their confidence in applying them.</p>
                                                </li>
                                                <li>
                                                    <h4>Will this be a challenge?</h4>
                                                    <p>Encouraging students to recognize obstacles and develop strategies to overcome them.</p>
                                                </li>
                                                <li>
                                                    <h4>My best work happens when</h4>
                                                    <p>Recognize the environments, routines, or methods that help them stay focused.</p>
                                                </li>
                                                <li>
                                                    <h4>Someone who supports my growth is</h4>
                                                    <p>Helping students identify trusted mentors - who inspire and guide them.</p>
                                                </li>
                                            </ul>

                                            <h3>3. Flexible Learning Paths: Breaking Away from Rigid Structures</h3>
                                            <p>Unlike traditional classroom models that follow a fixed curriculum, learner-driven education embraces flexibility. Students might engage in project-based learning, experiential learning, or self-paced modules that allow them to progress based on their understanding rather than a fixed timeline. This flexibility fosters curiosity, creativity, and independent thinking.</p>

                                            <h3>4. Collaborative Learning: Strengthening Peer Engagement</h3>
                                            <p>A learner-driven classroom doesn’t mean students work in isolation. Collaboration remains a key component, as students engage in peer discussions, group projects, and mentorship opportunities. Working together helps reinforce concepts, enhances problem-solving skills, and cultivates teamwork—an essential skill for the future workforce.</p>

                                            <h2>The Role of Educators in a Learner-Driven Model</h2>
                                            <p>One of the biggest shifts in a learner-driven approach is redefining the role of educators. Instead of being the sole providers of knowledge, teachers become facilitators, mentors, and guides. They help students navigate challenges, provide necessary resources, and support their critical thinking processes.</p>
                                            <p>Technology plays a significant role in this transformation. Adaptive learning platforms, artificial intelligence-driven tutoring systems, and personalized content recommendations all help educators cater to individual student needs effectively. However, the key lies in balancing technological integration with human guidance, ensuring students receive the mentorship necessary for a well-rounded learning experience.</p>

                                            <h2>Real-World Success Stories: Learner-Driven Models in Action</h2>
                                            <p>Several educational institutions have successfully implemented elements of learner-driven models, demonstrating the impact of student-centered education.</p>
                                            <p>For instance, a recent case study highlighted by<span className="blog-para-span"> “Getting Smart “</span> showcases how schools adopting these approaches see students take greater accountability for their learning outcomes. When given a say in their education, students develop higher levels of motivation and a stronger sense of responsibility.</p>

                                            <h2>Conclusion: The Future of Student-Centered Learning</h2>
                                            <p>The move toward a learner-driven classroom is a concept —it represents a fundamental shift in how education can be structured to better serve students. By fostering student agency, embracing flexibility, and leveraging technology, educators can create an environment where students take ownership of their learning journey, develop critical thinking skills, and become lifelong learners.</p>
                                            <p>As institutions continue to explore this transformative mode -</p>

                                            <div className="rate-admin-details-sec">
                                                <div class="rating rating-blgdetals">
                                                    <p >Rate this Blog:</p>
                                                    <p className="like-count-details">9</p>
                                                    <div class="like grow">
                                                        <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="dislike grow">
                                                        <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="admin-sec">
                                                    <p><PersonIcon />
                                                        <span>By: Admin</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "ai-in-education" &&
                                    <>
                                        <div className="blog-detal-content">
                                            <h2>International Workshop on Mastering Generative AI</h2>

                                            <p>Our founder recently had the opportunity to attend the<span className="blog-para-span"> International Workshop on Generative AI in Education at St. Joseph College for Women (Tiruppur)</span> — it was a glimpse into the future of learning. As a company deeply invested in the intersection of AI and education we see this beyond the industry trend by acquiring the knowledge, understanding the potential, and recognizing the crucial role AI will play in shaping the learning experiences of tomorrow.</p>

                                            <h2>Proactive Approach by St. Joseph College for Women</h2>
                                            <p>Setting the standard in education by spearheading the initiative. The institution’s forward-thinking approach sets a benchmark demonstrating what it truly means to prepare students to acquire skills and craft their careers with the help of AI-powered learning.</p>
                                            <p>We commend the institution’s efforts trying to bring AI into their learning frameworks, setting an example by empowering students, fostering curiosity, critical thinking, deeper engagement and revolutionizing traditional learning methodologies.</p>

                                            <h2>Dr. Helen Crompton: A Thought Leader in AI for Education</h2>
                                            <p>A key highlight of the workshop was the presence of <span className="blog-para-span">Dr. Helen Crompton</span> , a distinguished voice in AI and education. Her journey—marked by dedication and a relentless drive to advance educational initiatives—resonated deeply with the workshop’s mission. She reinforced the idea that<span className="blog-para-span"> AI in education is beyond technology; it’s about the people who shape its responsible use.</span></p>
                                            <p>Her insights on knowledge-sharing and collaboration serve as a guiding light for those of us who aim to make AI an enabler of deeper learning.</p>

                                            <h2>AI’s Role in Education: Where We Are & Where We’re Headed</h2>
                                            <p>AI has already become an integral part of our daily lives—from search engines to intelligent chatbots. But in education, its potential is just beginning to unfold.</p>

                                            <ul>
                                                <li>
                                                    <h4>Connecting Students & AI</h4>
                                                    <p>Encouraging student agency and interactive learning.</p>
                                                </li>
                                                <li>
                                                    <h4>AI as a Thought Partner</h4>
                                                    <p>Providing intelligent feedback, refining insights, and fostering critical thinking.</p>
                                                </li>
                                                <li>
                                                    <h4>Balancing AI with Human Intelligence</h4>
                                                    <p>Ensuring ethical considerations, responsible AI usage, and minimizing misinformation.</p>
                                                </li>
                                            </ul>

                                            <p>These themes reinforce the need for AI to be implemented thoughtfully and ethically, ensuring that students learn with AI, not from AI.</p>

                                            <h2>Let’s Keep the Conversation Alive</h2>
                                            <h3>We remember this workshop, and we want to ensure that we are remembered too.</h3>
                                            <p>By continuing to engage with thought leaders like Dr. Helen Crompton, & pioneering institutions like St. Joseph College for women (Tiruppur), actively participating in these transformative discussions, we build relationships that go beyond a single event. The workshop reinforced our belief that participation was not just about observing but about immersing ourselves in the evolving landscape of AI driven education, ensuring that we contribute meaningfully to its responsible use.</p>
                                            <p>We invite the institution, educators to collaborate with us in exploring new frontiers of AI-powered education.</p>

                                            <h2>Our Key Takeaways: The Big Questions AI Raises in Education</h2>
                                            <h3>Being a part of this dynamic exchange, we left with important insights that shape how we envision our role in AI in education.</h3>
                                            <ul>
                                                <li>
                                                    <h4>AI as a Personal Tutor</h4>
                                                    <p>With tools like GPT, students can receive instant guidance in various subjects. This has the potential to make personalized learning accessible to all.</p>
                                                </li>
                                                <li>
                                                    <h4>A Responsible Approach to AI in Classrooms</h4>
                                                    <p>While AI is a powerful resource, critical thinking is essential to avoid over-reliance. The ability to challenge, test, and refine AI-generated insights must be encouraged.</p>
                                                </li>
                                                <li>
                                                    <h4>Co-Creating Ethical AI Solutions</h4>
                                                    <p>Institutions must ensure that AI remains a co-pilot, not an answer key. The emphasis should be on guiding students to use AI responsibly rather than passively consuming its outputs.</p>
                                                </li>
                                            </ul>

                                            <h2>Where We See Ourselves in This AI Journey</h2>
                                            <p>As a company at the intersection of AI and education, we recognize the potential of AI driven learning, and we’re committed to working alongside forward-thinking institutions contributing the transformation of AI in learning actively to make it a reality.</p>
                                            <p>But for AI to truly empower students, it must be integrated thoughtfully, ethically, and with a clear vision. To make AI a responsible, effective, and transformative learning companion.</p>

                                            <div className="rate-admin-details-sec">
                                                <div class="rating rating-blgdetals">
                                                    <p >Rate this Blog:</p>
                                                    <p className="like-count-details">1</p>
                                                    <div class="like grow">
                                                        <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="dislike grow">
                                                        <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="admin-sec">
                                                    <p><PersonIcon />
                                                        <span>By: Admin</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "chatbots-today-revolutionize-the-school-operational-journey" &&
                                    <>
                                        <div className="blog-detal-content">
                                            <h2>The Role of Chatbots in Educational Institutions</h2>

                                            <p>In an era where educational institutions are embracing digital transformation, chatbots are emerging as powerful tools to enhance efficiency, engagement, and communication. While AI driven learning tools are gaining traction, the real game-changer in school administration is the seamless integration of chatbots into their operations.</p>

                                            <h2>Why Chatbots? The Shift Towards Intelligent Assistance</h2>
                                            <h3>And Their Overall Impact on the Education Sector</h3>
                                            <p>Educational institutions operate in an environment where <span className="blog-para-span"> time-sensitive interactions are critical,</span> where administrative staff juggle multiple responsibilities. This is where <span className="blog-para-span"> AI-powered chatbots step in, providing real-time assistance and seamless support.</span></p>

                                            <h2>Chatbots Can Be Beneficial Across Various School Functions</h2>
                                            <ul>
                                                <li>
                                                    <h4>Streamlining Administrative Tasks with Automation</h4>
                                                    <p>School administrators juggle multiple responsibilities, from managing inquiries and handling student records to coordinating events and ensuring smooth communication. Chatbots simplify these processes by automating routine interactions, reducing response time, and allowing staff to focus on more strategic tasks.</p>
                                                </li>
                                                <li>
                                                    <h4>Streamlined Admissions Process</h4>
                                                    <p>AI-Powered Chatbots are also streamlining the admissions process, making it more efficient for students and administrative staff. They can assist students about admissions requirements reducing the burden on admissions teams.</p>
                                                </li>
                                                <li>
                                                    <h4>Academic Support and Resources</h4>
                                                    <p>Institutes can support students academically by recommending resources, and answering subject-specific queries through chatbots. Additionally, chatbots can help students work on their assignments easier. This level of support enhances the overall student experience and contributes to better academic outcomes.</p>
                                                </li>
                                                <li>
                                                    <h4>Available Round the Clock</h4>
                                                    <p>Conversational chatbots are available 24/7, which is particularly beneficial for students who may have questions or need assistance outside of regular hours.</p>
                                                </li>
                                                <li>
                                                    <h4>Gain Student Insights</h4>
                                                    <p>Educational institutions can gain insights into student behavior and preferences. By tracking the questions students ask, schools can make decisions to improve their offerings and better meet the needs of their students.</p>
                                                </li>
                                            </ul>



                                            <h2>The Future of Chatbots in Education</h2>
                                            <p>The integration of chatbots in school operations is just the beginning. As AI technology advances, chatbots will evolve into more intuitive and adaptive tools, capable of personalizing interactions based on student behavior and institutional needs.</p>

                                            <h2>Thought Leadership: How Institutions Can Leverage This Shift</h2>
                                            <p>To stay ahead in this rapidly changing landscape, educational institutions who embrace chatbot technology thinking as more than just a tool—as a strategic enabler of efficiency, engagement, and innovation build a smarter, more connected educational experience.</p>
                                            <p>As thought leaders we believe that the future belongs to institutions that prioritize smart integrations .The institutions that proactively integrate chatbots are setting the stage for the future of education paving a way for operational efficiency & creating a more dynamic and responsive learning environment. <span className="blog-para-span"> The question isn’t whether chatbots will become a staple in education—it’s how institutions can leverage them strategically to drive value. </span></p>


                                            <div className="rate-admin-details-sec">
                                                <div class="rating rating-blgdetals">
                                                    <p >Rate this Blog:</p>
                                                    <p className="like-count-details">0</p>
                                                    <div class="like grow">
                                                        <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="dislike grow">
                                                        <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="admin-sec">
                                                    <p><PersonIcon />
                                                        <span>By: Admin</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "technology-influence-can-foster-exponential-growth-in-educational-institutions" &&
                                    <>
                                        <div className="blog-detal-content">
                                            <p>Utilising Technology Automates Processes, Opens up New Ways of Running Educational Institutions</p>
                                            <h2>Digital Transformation Journey of the Schools</h2>

                                            <p>Schools embarking on a digital transformation initiative, can have a major shift from manual processes toward digital solutions, but is that all it takes?</p>
                                            <p>Keeping transformation at the forefront. Schools using technology can effectively figure out what went well and what can be done better next time in the process. This way they can strike the balance by adopting new technologies in the long run.</p>

                                            <h3>Navigating The Technological Landscape Progressively Reduces The Risk Of Errors And Helps Schools Work In A Flexible Way</h3>
                                            <ul>
                                                <li>
                                                    <h4>Enables Schools to Easily Adapt</h4>
                                                    <p>Technology improves agility to adapt quickly to conditions, positioning schools to seize new opportunities</p>
                                                </li>
                                                <li>
                                                    <h4>Promoting Cross-Department Collaboration</h4>
                                                    <p>Software solutions facilitate communication between departments, breaking down silos and fostering teamwork</p>
                                                </li>
                                                <li>
                                                    <h4>Innovative School Revenue</h4>
                                                    <p>Integrating technology can open up avenues for new revenue streams.</p>
                                                </li>
                                                <li>
                                                    <h4>Leads to Faster Workflows</h4>
                                                    <p>The enhancements reduce manual interventions which improve internal workflows while the integration creates a more responsive approach.</p>
                                                </li>
                                                <li>
                                                    <h4>Driving a School-Centric Culture</h4>
                                                    <p>A major goal of digital transformation is using technology to provide seamless experiences across the school channels.</p>
                                                </li>
                                                <li>
                                                    <h4>Supporting Adoption of New Technologies</h4>
                                                    <p>Digital transformation inherently relies on the adoption of new technologies by providing visibility into end-to-end processes and helping identify the best areas to leverage new technologies for maximum impact on the schools.</p>
                                                </li>
                                            </ul>

                                            <div className="rate-admin-details-sec">
                                                <div class="rating rating-blgdetals">
                                                    <p >Rate this Blog:</p>
                                                    <p className="like-count-details">0</p>
                                                    <div class="like grow">
                                                        <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="dislike grow">
                                                        <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="admin-sec">
                                                    <p><PersonIcon />
                                                        <span>By: Admin</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            {/* call to action section start */}
                            <div className="callactn-sec blog-detl-call ">
                                {slug === "can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" &&
                                    <>
                                        <div className="callactn-content blog-detl-sec">
                                            <p>It's time to give students the power to shape their own education.</p>
                                            <div className="button-sec red-button-sec cal-btn-sec blg-detl-btn">
                                                <a href="#" class="btn-inner-sec btn-red-sec">
                                                    <span className="btn-name">  Consult free</span>
                                                    <span className="btn-icon"><EastIcon /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "ai-in-education" &&
                                    <>
                                        <div className="callactn-content blog-detl-sec">
                                            <p>Join us in co-creating the future of AI in education.</p>
                                            <div className="button-sec red-button-sec cal-btn-sec blg-detl-btn">
                                                <a href="#" class="btn-inner-sec btn-red-sec">
                                                    <span className="btn-name">  Consult free</span>
                                                    <span className="btn-icon"><EastIcon /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "chatbots-today-revolutionize-the-school-operational-journey" &&
                                    <>
                                        <div className="callactn-content blog-detl-sec">
                                            <p>Leverage the value of smart chatbot today</p>
                                            <div className="button-sec red-button-sec cal-btn-sec blg-detl-btn">
                                                <a href="#" class="btn-inner-sec btn-red-sec">
                                                    <span className="btn-name">  Consult free</span>
                                                    <span className="btn-icon"><EastIcon /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                }

                                {slug === "technology-influence-can-foster-exponential-growth-in-educational-institutions" &&
                                    <>
                                        <div className="callactn-content blog-detl-sec">
                                            <p>Are you an educational institution looking for long-term success by optimizing processes?</p>
                                            <div className="button-sec red-button-sec cal-btn-sec blg-detl-btn">
                                                <a href="#" class="btn-inner-sec btn-red-sec">
                                                    <span className="btn-name">  Consult free</span>
                                                    <span className="btn-icon"><EastIcon /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {/* call to action section end */}

                        </div>
                        <div className="col-md-4">
                            <div className="blog-detail-rights">
                                <div className="blog-search">
                                    <p>Search</p>
                                    <div className="blog-search-box">
                                        <input type="text" placeholder="Search" className="input-srch" />
                                        <span className="search-icon"><SearchIcon /></span>
                                    </div>
                                </div>
                                <div className="detail-categ">
                                    <p>Category</p>
                                    <div className="categ-list">
                                        <ul>
                                            <li className={slug === "technology-influence-can-foster-exponential-growth-in-educational-institutions" ? "active" : ""}>
                                                <a>School Digital Transformation</a>
                                            </li>
                                            <li className={slug === "chatbots-today-revolutionize-the-school-operational-journey" ? "active" : ""}>
                                                <a>Chatbots in School Operations</a>
                                            </li>
                                            <li>
                                                <a>AI Driven School Processes</a>
                                            </li>
                                            <li className={slug === "ai-in-education" ? "active" : ""}>
                                                <a>AI in Education</a>
                                            </li>

                                            <li className={slug === "can-there-be-a-truly-learner-driven-classroom-beyond-traditional-teaching" ? "active" : ""}>
                                                <a>Learner-Driven Classrooms</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="recent-post">
                                    <p>Recent Post</p>

                                    <div className="recent-post-list">
                                        <ul>
                                            <li>
                                                <img src={placeHolderImg} />
                                                <p>Can There Be a Truly Learner-Driven Classroom Beyond Traditional Teaching?</p>
                                            </li>
                                            <li>
                                                <img src={blogEvent} />
                                                <p>A Manifesto for the Future of AI</p>
                                            </li>
                                            <li>
                                                <img src={chatBotImg} />
                                                <p>Chatbots Today Revolutionize the School Operational Journey</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* blog etails section end */}
            <Footer />
        </>
    )
}

export default Blogdetails;
