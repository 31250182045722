import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import casestudyimg from '../assets/img/case-study-img-one.png';
import casestudyimgtwo from '../assets/img/case-study-img-two.png.png';
import { Link } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';
import caseimginventory from '../assets/img/case-inventory-img.png';
import caseimgabsence from '../assets/img/case-absence-reporting-img.png';
import bannerimg from '../assets/img/new-img-ivd/case-study-removebg-previewssss.webp';



const Casestudies = () => {
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">

              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  <h1>A Collection of Real-World Success Stories </h1>
                  <p>Featured Case Studies Capture Our Solutions in Action</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  <img src={bannerimg} alt="homeimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* case studies listing section start */}
      <div className="home-service-sec home-projt-sec ">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head product-head">
                <p className="short-head-line">Diversified Digital Innovation</p>
                <p className="case-stu-para">Explore All Our Success Stories</p>
              </div>
            </div>
          </div>

          <div className="case-studies-list">
            <div className="case-studies-list-row">
              <div className="case-studies-list-left">
                <div className="case-study-box">
                  <img src={casestudyimg} />
                  <p>
                    Our digitally evolved ERP solution impacted in positive growth
                    for schools with 0 d...
                  </p>
                  <div className="button-sec btn-align btn-learn">
                    <Link
                      to="/casestudiesdetails/enterprise-resource-planning"
                      class="btn-inner-sec btn-inner-lern"
                    >
                      <span className="btn-name">Know More</span>
                      <span className="btn-icon">
                        <EastIcon />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="case-studies-list-right">
                <div className="case-study-box">
                  <img src={caseimgabsence} />
                  <p>
                    Automated leave requests and approval processes can drive 100%
                    progress in managing...
                  </p>
                  <div className="button-sec btn-align btn-learn">
                    <Link
                      to="/casestudiesdetails/absence-reporting-portal"
                      class="btn-inner-sec btn-inner-lern"
                    >
                      <span className="btn-name">Know More</span>
                      <span className="btn-icon">
                        <EastIcon />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="case-studies-list-row margin-case">
              <div className="case-studies-list-left">
                <div className="case-study-box">
                  <img src={casestudyimgtwo} />
                  <p>
                    Publish all your school related workshops by streamlining
                    training announcements, s...
                  </p>
                  <div className="button-sec btn-align btn-learn">
                    <Link
                      to="/casestudiesdetails/workshop-management-system"
                      class="btn-inner-sec btn-inner-lern"
                    >
                      <span className="btn-name">Know More</span>
                      <span className="btn-icon">
                        <EastIcon />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="case-studies-list-right">
                <div className="case-study-box">
                  <img src={caseimginventory} />
                  <p>
                    Maintain your school inventory that you could define and
                    customize, with a maximum ...
                  </p>
                  <div className="button-sec btn-align btn-learn">
                    <Link
                      to="/casestudiesdetails/inventory-management-system"
                      class="btn-inner-sec btn-inner-lern"
                    >
                      <span className="btn-name">Know More</span>
                      <span className="btn-icon">
                        <EastIcon />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
      </div>
      {/* case studies listing section end */}
      <Footer />
    </>
  )
}

export default Casestudies;
