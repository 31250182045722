import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import videoplayimg from "../assets/img/icon-play.png";
import bannerimg from "../assets/img/desktop-file-psd.png";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import Carousel from 'react-bootstrap/Carousel';
import projctimage from '../assets/img/eform-img.jpg';
import keyfeatureone from '../assets/img/automated_data_validation_blue.png';
import keyfeaturetwo from '../assets/img/efficoent.png';
import keyfeaturethree from '../assets/img/user-friendly_icon_blue.png';
import keyfeaturefour from '../assets/img/report_blue.png';

import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Media, Video } from '@vidstack/player-react';
import Button from '@mui/material/Button';


import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';



import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';






import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EastIcon from '@mui/icons-material/East';
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
    addConnect,
    otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function dateformat(datwene) {
    const inputDate = datwene;
    const date = new Date(inputDate);

    // Format the date as "Month DD, YYYY"
    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        timeZone: 'UTC' // Ensures consistent output in UTC
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;

}


function addZero(i) {
    if (i < 10) { i = "0" + i }
    return i;
}
const Productdetails = () => {
    let navigate = useNavigate();
    const { productData } = useSelector((state) => state.pageContents);
    const [allprodata, setAllprodata] = useState(null);
    const [newprofda, setNewprofda] = useState('');
    // console.log(productData);
    const { slug } = useParams();
    // console.log(slug);
    useEffect(() => {
        if (productData.product.length > 0) {
            var proal = productData.product;
            var res = proal.map((item, indexc) => {
                if (item.slug == slug) {
                    console.log(item);
                    setAllprodata(item);
                }


            });
        };



    }, []);
    useEffect(() => {
        // console.log(newprofda);;
        if (productData.product.length > 0) {
            var proal = productData.product;
            var res = proal.map((item, indexc) => {
                if (item.slug == newprofda) {
                    console.log(item);
                    setAllprodata(item);
                }


            });
        };



    }, [newprofda]);
    const handleclickmenu = (e) => {


        if (productData?.product.length > 0) {
            let count = 0;
            let countf = 0;
            var tgitle = '';
            let str = productData.product.map(function (el) {
                var o = Object.assign({}, el);
                if (o.slug == slug) {

                    count = parseInt(o.sort) + 1;



                }
                // alert(count);
                // alert(o.sort);
                if (count == o.sort) {
                    //  alert(o.slug);
                    setNewprofda(o.slug);
                    navigate(`/productdetails/${o.slug}`);



                }
                countf++;

            });
        }
    };
    const handleclickmenuback = (e, backid) => {
        if (productData?.product.length > 0) {
            let count = 0;
            let countf = 0;
            var tgitle = '';
            var frslug = '';
            var bcknew = parseInt(backid) - 1;

            let str = productData.product.map(function (el) {
                var o = Object.assign({}, el);

                if (bcknew == parseInt(o.sort)) {

                    setNewprofda(o.slug);
                    navigate(`/productdetails/${o.slug}`);


                }

                countf++;

            });
        }
    };

    const [videof, setVideof] = React.useState('');
    const [imgfallvid, setImgfallvid] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [pageselect, setPageselect] = React.useState('');

    const handleClickOpen = (image, video) => {
        setImgfallvid(image);
        setVideof(video);

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openthank, setOpenthank] = React.useState(false);
    const handleClickOpenthank = () => {

        setOpenthank(true);
    };
    const handleClosethank = () => {
        setOpenthank(false);
    };
    const [formdateto, setFormdateto] = useState(null);
    const recaptchaRef = React.useRef();
    const datePickerRef = useRef(null);
    useEffect(() => {
        console.log('hi');;

        var date = new Date();
        setFormdateto(date);

    }, []);
    const openDatePicker = () => {
        alert('hi');
        if (datePickerRef.current) {
            datePickerRef.current.focus();
        }
    };
    const [opencase, setOpenCase] = React.useState(false);

    const handleClickOpenCasestudies = () => {
        setOpenCase(true);
    };
    const handleClosecase = () => {
        setOpenCase(false);
    };

    const [isOpen, setIsOpen] = useState(false)


    const handleDialogOffChange = (isOpen) => {
        setIsOpen(false)
    }


    const [opencaseyes, setOpenCaseyes] = React.useState(false);


    const handleClosecaseyes = () => {
        setOpenCaseyes(false);
    };



    const [opencaseno, setOpenCaseno] = React.useState(false);

    const handleClickOpenCaseno = () => {
        setOpenCaseno(true);
        setOpenCase(false);
    };
    const handleClosecaseno = () => {
        setOpenCaseyes(false);
        setOpenCaseno(false);
    };




    const formFields = {
        name: '',
        email: '',
        phone: '',
        contact_us: '',
        casestudyname: '',
        pagename: 'bookdemoproject',
        message: '',
        business_name: '',
        system: '',
        sh_date: '',
        sh_time: '',

        sh_date_new: null,
        sh_time_new: null

    }
    const [opendate, setOpenDate] = useState(false); // State to control the open/close
    const handleIconClick = () => {
        setOpenDate(true); // Open the picker when the icon is clicked
    };

    const handleDialogChange = (isOpen) => {
        var casedeat = { ...formValues };
        casedeat.casestudyname = allprodata.title;
        casedeat.name = '';
        casedeat.email = '';
        casedeat.phone = '';
        casedeat.contact_us = '';
        casedeat.message = '';
        casedeat.business_name = '';
        casedeat.system = '';
        casedeat.pagename = 'bookdemoproject';
        casedeat.sh_date = '';
        casedeat.sh_time = '';
        casedeat.sh_date_new = null;
        casedeat.sh_time_new = null;
        setFormValues(casedeat);
        setIsOpen(isOpen)
    }

    const handleDate = (e) => {
        let temp = { ...formValues }

        var d = new Date(e);
        var date;
        var month;
        if (d.getDate() < 10) {
            date = "0" + d.getDate();
        }
        else {
            date = d.getDate();
        }

        if ((d.getMonth() + 1) < 10) {
            month = "0" + (d.getMonth() + 1);
        }
        else {
            month = (d.getMonth() + 1);
        }
        var datestring = d.getFullYear() + "-" + month + "-" + date;
        temp.sh_date = datestring;
        temp.sh_date_new = e;

        console.log(datestring);
        setFormErrors({ ...formErrors, ['sh_date']: null });
        setFormValues(temp);
        setOpenDate(false);
    }



    const handleTime = (e) => {
        let temp = { ...formValues }
        console.log(e);
        var d = new Date(e);
        var hour;
        var hours;
        var mintues;
        hours = d.getHours();
        hour = d.getHours();
        mintues = d.getMinutes();
        console.log(hour);

        hour = hour % 12;
        hour = hour ? hour : 12;
        temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
        temp.sh_time_new = e;

        console.log(temp.sh_time);
        setFormErrors({ ...formErrors, ['sh_time']: null });
        setFormValues(temp);
    }
    const [loader, setLoader] = React.useState(true);

    const { ip_address } = useSelector((state) => state.userdata);
    const [formValues, setFormValues] = React.useState(formFields);
    const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
    const [submitting, setSubmitting] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [otpid, setotpid] = React.useState(null);

    const [openotp, setOpenotp] = React.useState(false);

    const handleClickOpenotp = () => {
        setOpenotp(true);
    };
    const handleCloseotp = () => {
        setOpenotp(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(formValues);
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: null });
    }
    const handleClickOpenCaseyes = (title) => {
        var casedeat = { ...formValues };
        casedeat.casestudyname = title;
        casedeat.name = '';
        casedeat.email = '';
        casedeat.phone = '';
        casedeat.contact_us = '';
        casedeat.message = '';
        casedeat.business_name = '';
        casedeat.system = '';
        casedeat.pagename = 'bookdemoproject';
        casedeat.sh_date = '2024-02-08';
        casedeat.sh_time = '';
        casedeat.sh_date_new = null;
        casedeat.sh_time_new = null;
        setFormValues(casedeat);
        //    recaptchaRef.current.reset();
        setFormErrors(updateNullOfObjectValues(formFields));
        setErrorMessage('');
        setSuccessMessage('');
        setOtpin({
            otpIn_first: "",
            otpIn_secon: "",
            otpIn_thir: "",
            otpIn_forth: "",
            otpIn_fifth: "",
            otpIn_six: "",
            error_first: 1,
            error_secon: 1,
            error_thir: 1,
            error_forth: 1,
            error_fifth: 1,
            error_six: 1,
            signIn_err: '0',
            signIn_mesage: '',
            status: 'fail',
            id: ''
        });
        setOpenCaseyes(true);

    };
    const handleClickOpenGetintouch = (title) => {


        setFormErrors(updateNullOfObjectValues(formFields));
        setErrorMessage('');
        setSuccessMessage('');
        setIsOpen(isOpen);


        var casedeat = { ...formValues };
        casedeat.casestudyname = title;
        casedeat.name = '';
        casedeat.email = '';
        casedeat.phone = '';
        casedeat.contact_us = '';
        casedeat.message = '';
        casedeat.business_name = '';
        casedeat.system = '';
        casedeat.pagename = 'bookdemoproject';
        casedeat.sh_date = '';
        casedeat.sh_time = '';
        casedeat.sh_date_new = null;
        casedeat.sh_time_new = null;
        setFormValues(casedeat);
        setOtpin({
            otpIn_first: "",
            otpIn_secon: "",
            otpIn_thir: "",
            otpIn_forth: "",
            otpIn_fifth: "",
            otpIn_six: "",
            error_first: 1,
            error_secon: 1,
            error_thir: 1,
            error_forth: 1,
            error_fifth: 1,
            error_six: 1,
            signIn_err: '0',
            signIn_mesage: '',
            status: 'fail',
            id: ''
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitting(false);

        console.log(validateForm());
        if (validateForm()) {
            setSubmitting(true);
            setErrorMessage('');
            setSuccessMessage('');
            let formData = { ...formValues };
            formData.recaptcha = recaptchaRef.current.getValue();
            setPageselect(formData.pagename);
            addConnect(formData)
                .then((response) => {
                    setSubmitting(false);
                    if (response.data.status) {
                        setotpid(response.data.lastid);
                        //setSuccessMessage(response.data.message);
                        setFormValues(formFields);
                        recaptchaRef.current.reset();
                        setOpenCaseyes(false);
                        ;
                        setIsOpen(false);
                        setOpenotp(true);
                        setOtpin({
                            otpIn_first: "",
                            otpIn_secon: "",
                            otpIn_thir: "",
                            otpIn_forth: "",
                            otpIn_fifth: "",
                            otpIn_six: "",
                            error_first: 1,
                            error_secon: 1,
                            error_thir: 1,
                            error_forth: 1,
                            error_fifth: 1,
                            error_six: 1,
                            signIn_err: '0',
                            signIn_mesage: '',
                            status: 'fail',
                            id: ''
                        });
                        // setTimeout(() => {
                        //  // setOpen(false);
                        // }, 2000);


                    } else {
                        setErrorMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    setErrorMessage('Something went wrong. Please try again later.');
                });
        }
    }

    const validateForm = () => {
        let isValid = true;
        let errors = {};
        console.log(formValues);
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date_new' && key != 'sh_time_new' && key !== 'casestudyname'
                && key !== 'business_name'
                && key !== 'message'


            ) {
                isValid = false;
                errors[key] = 'This field is required';
            } else if (key === 'email' && !isEmail(formValues[key])) {
                isValid = false;
                errors[key] = 'Invalid email address';
            } else if (formValues.contact_us == 'enterprise' && key === 'business_name' && !formValues[key]) {
                isValid = false;
                errors[key] = 'This field is required';
            } else if (formValues.system == 'yes' && key === 'message' && !formValues[key]) {
                isValid = false;
                errors[key] = 'This field is required';
            } if (formValues.sh_date == '' && key == 'sh_date') {
                isValid = false;
                errors[key] = 'This field is required';
            } if (formValues.sh_time == '' && key == 'sh_time') {
                isValid = false;
                errors[key] = 'This field is required';
            }
            if (formValues.sh_time != '' && key == 'sh_time') {
                var d = new Date(formValues.sh_time_new);
                var hour;
                var hours;
                var mintues;
                hours = d.getHours();
                hour = d.getHours();
                mintues = d.getMinutes();
                console.log(hour);

                hour = hour % 12;
                hour = hour ? hour : 12;
                var reshr = hours >= 12 ? 'PM' : 'AM';
                console.log(hours);
                console.log(mintues);
                console.log(reshr);
                if (reshr == 'AM' && hours < 10) {
                    isValid = false;
                    errors[key] = 'Please select time between 10 AM to 12 PM and 3 PM to 5 PM';
                }
                else if (reshr == 'PM' && hours > 17 && mintues > 0) {
                    isValid = false;
                    errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
                }
                else if (reshr == 'PM' && hours < 15 && hours != 12) {
                    isValid = false;
                    errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
                } else if (reshr == 'PM' && hours == 22) {
                    isValid = false;
                    errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
                } else if (reshr == 'PM' && hours == 23) {
                    isValid = false;
                    errors[key] = 'Please select time between 10 AM to 12 PM  and 3 PM to 5 PM';
                }


            }
            // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
            //   isValid = false;
            //   errors[key] = 'Invalid phone number';
            // }

        });
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            isValid = false;
            errors.recaptcha = 'Please verify captcha';
        }
        console.log(errors);
        setFormErrors(errors);
        return isValid;
    }

    const [otpIn, setOtpin] = useState({
        otpIn_first: "",
        otpIn_secon: "",
        otpIn_thir: "",
        otpIn_forth: "",
        otpIn_fifth: "",
        otpIn_six: "",
        error_first: 1,
        error_secon: 1,
        error_thir: 1,
        error_forth: 1,
        error_fifth: 1,
        error_six: 1,
        signIn_err: '0',
        signIn_mesage: '',
        status: 'fail',
        id: ''
    });
    const inputEventOtpIn = (event) => {
        console.log(event);
        setOtpin((preValue) => {

            if (event.target.name === 'otpIn_first') {

                return {
                    otpIn_first: event.target.value,
                    otpIn_secon: preValue.otpIn_secon,
                    otpIn_thir: preValue.otpIn_thir,
                    otpIn_forth: preValue.otpIn_forth,
                    otpIn_fifth: preValue.otpIn_fifth,
                    otpIn_six: preValue.otpIn_six,
                    error_first: 0,
                    error_secon: preValue.error_secon,
                    error_thir: preValue.error_thir,
                    error_forth: preValue.error_forth,
                    error_fifth: preValue.error_fifth,
                    error_six: preValue.error_six,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };

            }

            if (event.target.name === 'otpIn_secon') {

                return {
                    otpIn_first: preValue.otpIn_first,
                    otpIn_secon: event.target.value,
                    otpIn_thir: preValue.otpIn_thir,
                    otpIn_forth: preValue.otpIn_forth,
                    otpIn_fifth: preValue.otpIn_fifth,
                    otpIn_six: preValue.otpIn_six,
                    error_first: preValue.error_first,
                    error_secon: 0,
                    error_thir: preValue.error_thir,
                    error_forth: preValue.error_forth,
                    error_fifth: preValue.error_fifth,
                    error_six: preValue.error_six,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };

            }
            if (event.target.name === 'otpIn_thir') {

                return {
                    otpIn_first: preValue.otpIn_first,
                    otpIn_secon: preValue.otpIn_secon,
                    otpIn_thir: event.target.value,
                    otpIn_forth: preValue.otpIn_forth,
                    otpIn_fifth: preValue.otpIn_fifth,
                    otpIn_six: preValue.otpIn_six,
                    error_first: preValue.error_first,
                    error_secon: preValue.error_secon,
                    error_thir: 0,
                    error_forth: preValue.error_forth,
                    error_fifth: preValue.error_fifth,
                    error_six: preValue.error_six,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };

            }

            if (event.target.name === 'otpIn_forth') {

                return {
                    otpIn_first: preValue.otpIn_first,
                    otpIn_secon: preValue.otpIn_secon,
                    otpIn_thir: preValue.otpIn_thir,
                    otpIn_forth: event.target.value,
                    otpIn_fifth: preValue.otpIn_fifth,
                    otpIn_six: preValue.otpIn_six,
                    error_first: preValue.error_first,
                    error_secon: preValue.error_secon,
                    error_thir: preValue.error_thir,
                    error_forth: 0,
                    error_fifth: preValue.error_fifth,
                    error_six: preValue.error_six,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };

            }



            if (event.target.name === 'otpIn_fifth') {

                return {
                    otpIn_first: preValue.otpIn_first,
                    otpIn_secon: preValue.otpIn_secon,
                    otpIn_thir: preValue.otpIn_thir,
                    otpIn_forth: preValue.otpIn_forth,
                    otpIn_fifth: event.target.value,
                    otpIn_six: preValue.otpIn_six,
                    error_first: preValue.error_first,
                    error_secon: preValue.error_secon,
                    error_thir: preValue.error_thir,
                    error_forth: preValue.error_forth,
                    error_fifth: 0,
                    error_six: preValue.error_six,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };

            }
            if (event.target.name === 'otpIn_six') {

                return {
                    otpIn_first: preValue.otpIn_first,
                    otpIn_secon: preValue.otpIn_secon,
                    otpIn_thir: preValue.otpIn_thir,
                    otpIn_forth: preValue.otpIn_forth,
                    otpIn_fifth: preValue.otpIn_fifth,
                    otpIn_six: event.target.value,
                    error_first: preValue.error_first,
                    error_secon: preValue.error_secon,
                    error_thir: preValue.error_thir,
                    error_forth: preValue.error_forth,
                    error_fifth: preValue.error_fifth,
                    error_six: 0,
                    signIn_err: preValue.signIn_err,
                    signIn_mesage: '',
                    status: 'fail',
                    id: preValue.id,
                };
            }
        });
        if (event.target.name === 'otpIn_first') {

            document.getElementById('otpIn_secon').focus();
        }

        if (event.target.name === 'otpIn_secon') {

            document.getElementById('otpIn_thir').focus();
        }
        if (event.target.name === 'otpIn_thir') {


            document.getElementById('otpIn_forth').focus();
        }

        if (event.target.name === 'otpIn_forth') {


            //   document.getElementById('otpIn_fifth').focus();
        }



        //      if(event.target.name==='otpIn_fifth'){


        //           document.getElementById('otpIn_six').focus();
        //  }
        console.log(otpIn);

    }

    React.useEffect(() => {
        if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
            && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
            handleSubmitotp();
        }

    }, [otpIn]);
    const handleSubmitotp = (event) => {

        setErrorMessage('');
        setSuccessMessage('');
        if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
            || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
            setErrorMessage('Please Enter Valid otp');
            return false;
        }
        var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

        setSubmitting(true);
        otpUpdate({ id: otpid, otp: otp })
            .then(res => {
                console.log(res.data);

                if (res.data.status == true) {
                    // setOpen(true);
                    setOpenotp(false);
                    setSubmitting(false);
                    // setOpenthank(true);
                    navigate('/thankyou/products');
                    setTimeout(() => {

                        // setOpenthank(false);
                    }, 3000);

                    // setTimeout(() => {
                    //   setOpen(false);
                    // }, 3000);


                    if (pageselect == 'bookdemoproject') {
                        //    navigate('/thankyou/bookademoproject');
                    }



                }
                else {

                    setErrorMessage(res.data.message);
                    setOtpin((preValue) => {

                        return {
                            otpIn_first: '',
                            otpIn_secon: '',
                            otpIn_thir: '',
                            otpIn_forth: '',
                            otpIn_fifth: '',
                            otpIn_six: '',
                            error_first: 0,
                            error_secon: 0,
                            error_thir: 0,
                            error_forth: 0,
                            error_fifth: 0,
                            error_six: 0,

                        };
                    })
                    setSubmitting(false);
                }
            });
    }


    const [selectedDate, setSelectedDate] = useState(null);

    // three day block code from calendar
    const todaynew = dayjs();


    const minDate = todaynew.add(4, "day");

    // Disable all times except 10-11 AM and 3-5 PM
    // Disable all times except 10-11 AM and 3-5 PM
    const shouldDisableTime = (value, view) => {

        const hour = dayjs(value).hour();
        const minute = dayjs(value).minute();

        if (view === "hours") {
            // Allow only 10 AM - 11 AM, 12 PM (only 12:00 PM), 3 PM - 4 PM, and 5 PM (only 5:00 PM)
            return ![10, 11, 12, 15, 16, 17].includes(hour);
        }

        if (view === "minutes") {
            if (hour === 12 || hour === 17) {
                // Allow only 12:00 PM and 5:00 PM (disable all other minutes for 12 PM and 5 PM)
                return minute !== 0;
            }
        }
        return false;
    };


    const [selectedRadioValue, setSelectedRadioValue] = useState("");

    return (
        <>
            <div className="main-bg-sec">
                <Header />
                <div className="main-sec main-innerbanner main-proddetai-inner main-detsila-two">
                    <div className="container">
                        <div className="row">
                            {allprodata != null &&

                                <>
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>{allprodata.product_details.ban_title_one} <span>{allprodata.product_details.ban_title_two} </span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">{allprodata.product_details.ban_title_third} </a>
                                                <div className="play-sec-prdt">
                                                    {allprodata.video != '' &&
                                                        <a id="play-video" class="video-play-button" href="#" onClick={() => handleClickOpen(allprodata.video_image, allprodata.video)}>
                                                            <img src={videoplayimg} />
                                                        </a>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={process.env.REACT_APP_IMAGE_URL + allprodata.product_details.product_image} />
                                        </div>
                                    </div>
                                </>
                            }


                        </div>
                    </div>
                </div>

            </div>

            {/* key process section start */}
            <div className="keyprocs-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="keyprocs-content">
                                {allprodata != null &&
                                    <>
                                        <h2>{allprodata.product_details.product_intro}  </h2>
                                        <p>{allprodata.product_details.short_desc} </p>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* key process section end */}

            {/* carousal sec start */}
            <div className="product-sec detail-carousl-sec">
                <div className="bg-icon-service">
                    <img src={bgimagedots} alt="bg-icon" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="carousal-img">


                                {
                                    allprodata?.product_images.length &&
                                    <>
                                        <Carousel>
                                            {allprodata.product_images.map((imagef, index) => (
                                                <Carousel.Item interval={1000}>
                                                    <div>
                                                        <img src={process.env.REACT_APP_IMAGE_URL + imagef.product_image} alt="project-image" />
                                                    </div>
                                                </Carousel.Item>

                                            ))}
                                        </Carousel>
                                    </>

                                }

                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="carousl-details-content">
                                {allprodata != null &&
                                    <>
                                        <h2>{allprodata.product_details.title}</h2>
                                        <p>{allprodata.product_details.descrip}</p>
                                    </>

                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-icon-belowservice">
                    <img src={bgimagedots} alt="bg-icon" />
                </div>
            </div>
            {/* carousal sec end */}

            {/* key feature section start */}
            <div className="keyprocs-feturesec">
                {/* <div className="keyfeatre-container"> */}

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="keyprocs-heading">
                                {allprodata != null &&
                                    <>

                                        <h2>{allprodata.product_details.product_key}</h2>

                                    </>

                                }

                            </div>
                        </div>
                    </div>
                    <div className="keyfeature-inner">
                        <div className="row">
                            {
                                allprodata?.product_effect_keys.length &&
                                <>
                                    {allprodata.product_effect_keys.map((keysd, index) => (

                                        <div className="col-md-3 key-prodt-width">
                                            <div className="keyfeature-box">
                                                <div className="keyfetur-inner">
                                                    <div className="keyfeatre-inn-sec">
                                                        <img src={process.env.REACT_APP_IMAGE_URL + keysd.icon} alt="keyfeature" />
                                                        <h3>{keysd.title}</h3>
                                                        <p>{keysd.short_descirption}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </>

                            }



                        </div>
                    </div>
                </div>
            </div>
            {/* key feature section end */}

            {/* impact section start */}
            <div className="impact-sec">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {allprodata != null &&
                                <>

                                    <h2>{allprodata.product_details.product_tangiable}</h2>

                                </>

                            }

                        </div>
                    </div>
                    <div className="imapct-inner-sec">
                        <div className="row">

                            {
                                allprodata?.product_tangible.length &&
                                <>
                                    {allprodata.product_tangible.map((tang, index) => (

                                        <div className="col-md-4 width-impact-res">
                                            <div className={`impact-box ${index == 1 && 'impact-box-center'}`}>
                                                <h3>{tang.per}%</h3>
                                                <p>{tang.title}</p>
                                            </div>
                                        </div>

                                    ))}
                                </>

                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* impact section end */}
            {/* call to action section start */}
            <div className="callactn-sec  ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="callactn-content">
                                {allprodata != null &&
                                    <>
                                        <p>{allprodata.product_details.product_lower_sec}</p>


                                    </>

                                }


                                <div className="button-sec red-button-sec cal-btn-sec">

                                    <a class="btn-inner-sec btn-red-sec" onClick={handleDialogChange}>
                                        <span className="btn-name">  Book a Free Demo</span>
                                        <span className="btn-icon"><EastIcon /></span>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* prev ad next section start */}
                <div className="prev-next-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pre-btnss">

                                    {allprodata != null &&
                                        <>
                                            {allprodata.sort != 1 &&




                                                <a href="#" className="prev-main-sec"
                                                    onClick={(e) => handleclickmenuback(e, (allprodata.sort))}
                                                >
                                                    <div class="arrows-container">
                                                        <div class="arrownewss arrownewwprev arrow-one">
                                                        </div>
                                                        <div class="arrownew arrownewwprevsec arrow-two">
                                                        </div>


                                                    </div>
                                                    <span className="btn-name">Previous Product</span>


                                                </a>
                                            }

                                            {productData.product.length > (allprodata.sort) &&


                                                <a className="next-main-sec"
                                                    onClick={(e) => handleclickmenu()}
                                                >

                                                    <span className="btn-name" >Next Product</span>
                                                    <div class="arrows-container">
                                                        <div class="arrownewss arrow-one">
                                                        </div>
                                                        <div class="arrownew arrow-two">
                                                        </div>
                                                    </div>
                                                </a>

                                            }

                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* prev and next sectione end */}
            </div>
            {/* call to action section end */}


            <Footer />







            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title" className="prodt-modl-news videoplay-modl"
                open={open}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {(videof != null && imgfallvid != '') &&
                            <Media style={{ width: "100%" }}>
                                <Video autoplay="true" loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid}>
                                    <video loading="visible" poster={process.env.REACT_APP_IMAGE_URL + imgfallvid} autoplay="true" src={process.env.REACT_APP_IMAGE_URL + videof} preload="none" data-video="0" controls />
                                </Video>
                            </Media>

                        }
                    </Typography>
                </DialogContent>

            </BootstrapDialog>


            <BootstrapDialog
                onClose={handleDialogOffChange}
                aria-labelledby="customized-dialog-title"
                open={isOpen} className="prodt-modl-news"
            >

                <IconButton
                    aria-label="close"
                    onClick={handleDialogOffChange}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                    className="closebew-icon"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <h2>Book a Free Demo</h2>
                    <div className="form-sec-main">
                        {/* <h2>Book a Free Demo</h2> */}
                        <div className='form-sec form-modal form-radio-sec'>
                            <FormControl>
                                <FormLabel className="radio-sec-label">I am a</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="individual" control={<Radio />} label="individual "
                                        name="contact_us"
                                        checked={formValues.contact_us == 'individual'}
                                        onChange={handleInputChange}
                                    />
                                    <FormControlLabel value="enterprise" control={<Radio />} label="enterprise"
                                        name="contact_us"
                                        checked={formValues.contact_us == 'enterprise'}
                                        onChange={handleInputChange}
                                    />

                                </RadioGroup>
                            </FormControl>
                            {
                                formErrors.contact_us &&
                                <div className="invalid-feedbackcontact">
                                    <div className='error-msg'>
                                        {formErrors.contact_us}
                                    </div>
                                </div>
                            }

                        </div>
                        {formValues.contact_us == 'enterprise' &&

                            <>


                                <div className='form-sec form-modal'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                                            name="name"
                                            className={classNames("form-control", { 'is-invalid': formErrors.name })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.name}


                                        />
                                        {
                                            formErrors.name &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.name}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                </div>


                                <div className='form-sec form-modal'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField id="standard-basic" label="Your Business Name " variant="outlined"

                                            name="business_name"
                                            className={classNames("form-control", { 'is-invalid': formErrors.business_name })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.business_name}


                                        />
                                        {
                                            formErrors.business_name &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.business_name}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                </div>
                                <div className='form-sec form-modal'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField id="standard-basic" label="Work Email" variant="outlined"
                                            name="email"
                                            className={classNames("form-control", { 'is-invalid': formErrors.email })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.email}

                                        />
                                        {
                                            formErrors.email &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.email}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                    <div className="email-verify-sec">
                                        <p>This email will be verified</p>
                                    </div>


                                </div>


                            </>

                        }

                        {formValues.contact_us != 'enterprise' &&

                            <>


                                <div className='form-sec form-modal'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                                            name="name"
                                            className={classNames("form-control", { 'is-invalid': formErrors.name })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.name}


                                        />
                                        {
                                            formErrors.name &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.name}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                </div>



                                <div className='form-sec form-modal'>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { m: 1, width: '100%' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                                            name="email"
                                            className={classNames("form-control", { 'is-invalid': formErrors.email })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.email}

                                        />
                                        {
                                            formErrors.email &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.email}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                    <div className="email-verify-sec">
                                        <p>This email will be verified</p>
                                    </div>


                                </div>


                            </>

                        }



                        <div className='form-sec form-modal form-radio-sec system-radio'>

                            <FormControl>
                                <FormLabel className="radio-sec-label">Are you currently using any system?</FormLabel>
                                <RadioGroup
                                    row


                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes"
                                        name="system"
                                        checked={formValues.system == 'yes'}
                                        onChange={handleInputChange}

                                    />
                                    <FormControlLabel value="no" control={<Radio />} label="No"
                                        name="system"
                                        checked={formValues.system == 'no'}
                                        onChange={handleInputChange}
                                    />
                                </RadioGroup>


                            </FormControl>
                            {
                                formErrors.system &&
                                <div className="invalid-feedbackcontact">
                                    <div className='error-msg'>
                                        {formErrors.system}
                                    </div>
                                </div>
                            }


                        </div>
                        <div className='form-sec form-modal'>
                            {formValues.system == 'yes' &&

                                <>
                                    <Box
                                        component="form"
                                        sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Name of the system"
                                            multiline
                                            rows={2}
                                            placeholder=""

                                            name="message"
                                            className={classNames("form-control", { 'is-invalid': formErrors.message })}
                                            onChange={handleInputChange}
                                            style={{ backgroundImage: 'none' }}
                                            value={formValues.message}
                                        />
                                        {
                                            formErrors.message &&
                                            <div className="invalid-feedback">
                                                <div className='error-msg'>
                                                    {formErrors.message}
                                                </div>
                                            </div>
                                        }
                                    </Box>
                                </>
                            }
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className='form-sec form-modal date-pick-sec-modl new-modl-calen' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileDatePicker',
                                            ]}
                                        >

                                            <DemoItem >

                                                <MobileDatePicker
                                                    label="Select Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={formValues.sh_date_new}
                                                    open={opendate} // Control the open state of the picker
                                                    onClose={() => setOpenDate(false)}
                                                    onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field

                                                    onChange={handleDate}
                                                    minDate={minDate} />
                                                <span className="new-calen-icons"><CalendarMonthIcon /></span>
                                            </DemoItem>


                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {
                                        formErrors.sh_date &&
                                        <div className="invalid-feedbackcontact">
                                            <div className='error-msg'>
                                                {formErrors.sh_date}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='form-sec form-modal time-pick-sec-modl new-modl-time' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileDatePicker',
                                            ]}
                                        >



                                            <DemoItem >
                                                <MobileTimePicker label="Time Slot"
                                                    onChange={handleTime}

                                                    value={formValues.sh_time_new}
                                                    views={["hours", "minutes"]} // Ensure both hours and minutes are selectable

                                                    //defaultValue={dayjs('2022-04-17T15:30')}
                                                    ampm={true}
                                                    inputFormat="hh:mm A"
                                                    shouldDisableTime={shouldDisableTime} // Apply time restrictions
                                                    renderInput={(params) => <TextField {...params} />}



                                                />
                                                <span className="abut-clock-icon"><AccessTimeIcon /></span>


                                            </DemoItem>

                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {
                                        formErrors.sh_time &&
                                        <div className="invalid-feedbackcontact">
                                            <div className='error-msg'>
                                                {formErrors.sh_time}
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* <div className='form-sec form-modal form-radio-sec'>



                            {formValues.pagename == 'bookdemoproject' &&
                                <>
                                    <div className='form-sec form-modal date-modal-case data-scroll-modal'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer
                                                                    components={[
                                                                        'DatePicker',
                                                                        'MobileDatePicker',
                                                                        'DesktopDatePicker',
                                                                        'StaticDatePicker',
                                                                    ]}
                                                                >

                                                                    <MobileDatePicker
                                                                        label="Select Date"
                                                                        variant="standard"
                                                                        inputFormat="MM/dd/yyyy"
                                                                        placeholder="MM/dd/yyyy"
                                                                        value={formValues.sh_date_new}
                                                                        open={opendate} 
                                                                        onClose={() => setOpenDate(false)}
                                                                        onOpen={() => setOpenDate(true)} 

                                                                        onChange={handleDate}
                                                                        minDate={dayjs(formdateto)}
                                                                        renderInput={(params) => <TextField {...params}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton onClick={handleIconClick}>
                                                                                            <CalendarMonthIcon />
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}

                                                                        />}

                                                                    />
                                                                    <span className="calend-icon" onClick={handleIconClick}>
                                                                        <CalendarMonthIcon /></span>


                                                                </DemoContainer>
                                                            </LocalizationProvider>

                                        {
                                            formErrors.sh_date &&
                                            <div className="invalid-feedbackcontact">
                                                <div className='error-msg'>
                                                    {formErrors.sh_date}
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </>

                            }


                        </div> */}


                        <div className='form-sec form-modal'>

                            <p className="case-privacy casepropri" style={{ marginTop: '41px  !important' }}>Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
                        </div>
                        <div className='form-sec form-modal' >


                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                            />
                            {
                                formErrors.recaptcha &&
                                <div className="invalid-feedback" style={{ display: 'block' }}>
                                    <div className='error-msg captch-error'>
                                        {formErrors.recaptcha}
                                    </div>
                                </div>
                            }
                        </div>



                    </div>
                    <div className="button-sec btn-align mdl-btn new-mdl-btn">
                        <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
                            <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}</span>
                            <span className="btn-icon"><EastIcon /></span>
                        </a>
                    </div>

                    <div className='validate-sec validate-sec-mode'>
                        {
                            successMessage &&
                            <div className="success-msg-sec">
                                <p className="succ-msg">{successMessage}
                                </p>
                            </div>
                        }
                        {
                            errorMessage &&
                            <div className="error-msg-sec">
                                <p className="error-msg">{errorMessage}
                                </p>
                            </div>
                        }
                    </div>

                </DialogContent >

            </BootstrapDialog >
            <BootstrapDialog
                onClose={handleCloseotp}
                aria-labelledby="customized-dialog-title"
                open={openotp} className="newotp-sec"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    OTP!


                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseotp}
                    className="closebew-icon"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    <div className="otp-content-sec">
                        <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
                        </p>
                    </div>
                    <div className="form-sec-main otp-main-secss">

                        <div className='form-sec form-modal'>
                            <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
                            </div>



                        </div>
                        <div className="button-sec btn-align mdl-btn btn-otp">
                            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}</span>
                                <span className="btn-icon"><EastIcon /></span>
                            </a>
                        </div>


                        <div className='validate-sec validate-sec-mode width-otp'>
                            {
                                successMessage &&
                                <div className="success-msg-sec">
                                    <p className="succ-msg">{successMessage}
                                    </p>
                                </div>
                            }
                            {
                                errorMessage &&
                                <div className="error-msg-sec">
                                    <p className="error-msg">{errorMessage}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </DialogContent >

            </BootstrapDialog >
            <BootstrapDialog
                onClose={handleClosethank}
                aria-labelledby="customized-dialog-title"
                open={openthank} className="newotp-sec thanksuou"
            >


                <DialogContent dividers>
                    <div class="success-animation">
                        <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <h2>Thank You !!</h2>
                    <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>


                </DialogContent >

            </BootstrapDialog >
        </>

    )
}

export default Productdetails;
