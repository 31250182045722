import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import visionimg from '../assets/img/timeline-img-icon/service-img-sec.png';
import serviceimg from '../assets/img/image-pic.png';
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import serveimgicon from '../assets/img/ai_vision_icon_blue.png';
import imgstrategic from '../assets/img/strategy_icon_blue.png';
import imgoptimize from '../assets/img/execution_and_optimization_icon_blue.png';
import imgai from '../assets/img/ongoing_ai_support_and_maintainance_icon_blue.png';
import customservimgone from '../assets/img/Strategic_safeguards_in_every_step.png';
import customservimgtwo from '../assets/img/robust-developmen-process.png';
import customservimgthree from '../assets/img/measuring_icon.png';
import customservimgfour from '../assets/img/dedicated_support_blue_icon.png';
import mobilityimgone from '../assets/img/digital_centric_icon_blue.png';
import mobilityimgtwo from '../assets/img/testing_sec_blue.png';
import mobilityimgthree from '../assets/img/Go_live_and_optimizing_iconss.png';
import mobilityimgfour from '../assets/img/comprehensive_mobility_support_blue_icon.png';
import supportimgone from '../assets/img/Deployment_icon_new_blue.png';
import supportimgtwo from '../assets/img/agile_work_modal_blue_icon.png';
import supportimgthree from '../assets/img/maintaince_upgrade_img.png';
import supportimgfour from '../assets/img/our_exclusive_support_icon_blue.png';
import teamimage from '../assets/img/service-team-image.png';
import EastIcon from '@mui/icons-material/East';
import bannerimg from '../assets/img/new-img-ivd/about-or-service-removebg-preview.webp';


const Services = () => {
  
  return (
    <>

      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">

                  <h1>Our Services </h1>
                  <p>Incubating a Culture of Innovation & Creativity in the School Community</p>

                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">

                  <img src={bannerimg} alt="homeimg" />


                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

      {/* secured develop section start here */}
      <div className="home-service-sec about-innov-main secure-main" id={`getservice`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-short-head about-innov-sec secure-sec">

                <p className="short-head-line">Secured Development Made Simple</p>
                <p className="about-innov-para secure-para serice-nee-fix">
                  We deliver solutions that fulfill traditional, transformational,
                  and future needs, turning them into tangible benefits for educational institutions.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* secured develop end  */}

      {/* service category section start here */}

      <div className="home-service-sec differ-service-sec" >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">

                <h2>AI-Enabled Development Services
                  for Schools</h2>
                <p> Leverage AI-powered development with our conventional AI initiative that triggers digital intelligence and minimizes vulnerability.
                </p>

              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-right">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>

                <div className="serv-img-inner">
                  <img src={visionimg} />
                </div>

              </div>
            </div>
          </div>
          <div className="service-inner-category">


            <div className="row">
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgstrategic} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Strategic Roadmap for Schools</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>A comprehensive roadmap for maximum impact
                      and  implementing strategies that require less user input.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgoptimize} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Execution and Optimization</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Our focus on vulnerability control throughout the development process mitigates risks before they can impact school operations</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin-catg-top">



              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={imgai} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Ongoing Support and Maintenance</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We provide continuous support through proactive maintenance, ensuring school systems adapt & remain secure over time.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={serveimgicon} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>The Vision for Schools with Uncompromising Security
                      </h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We prioritize vulnerability control to safeguard your applications against potential threats.
                    </p>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      {/* service category section end */}

      {/* service second section start */}
      <div className="home-service-sec differ-service-sec vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="service-img-right servce-img-left">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>


                <div className="serv-img-inner servleft-img-inner">
                  <img src={visionimg} />
                </div>

              </div>
            </div>
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">

                <h2>Tailored Custom Software Services  </h2>
                <p>Our strategic methodologies aim to optimize school processes while embedding essential security protocols
                </p>

              </div>
            </div>
          </div>


          <div className="service-inner-category">
            <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Measuring User Adoption and Satisfaction</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We measure software success through user adoption and satisfaction, continuously seeking feedback to make iterative changes.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Strategic Safeguards for Schools in Every
                        Step</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We prioritize security by implementing encrypted passwords, email validation, and OTP validation to protect sensitive user information.</p>
                  </div>
                </div>
              </div>

            </div>

            <div className="row margin-catg-top">
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Robust Development Process</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We follow best practices by utilizing API validation
                      and IP restriction throughout the software lifecycle.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={customservimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Dedicated Support For Schools</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Available for troubleshooting and enhancements,
                      our team ensures your software evolves securely.
                    </p>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      {/* service second section end */}


      {/* service third section start */}
      <div className="home-service-sec differ-service-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">

                <h2>Adaptive Mobility Solutions Services</h2>
                <p>Designed for users to work seamlessly from anywhere, facilitating adaptable and responsive workflows with Integrated Security.
                </p>

              </div>
            </div>
            <div className="col-md-6">
              <div className="service-img-right">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>

                <div className="serv-img-inner">
                  <img src={visionimg} />
                </div>

              </div>
            </div>
          </div>
          <div className="service-inner-category">
            <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Go Live and optimizing</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Once live, we assist in maintaining and optimizing performance, ensuring that security protocols remain effective and adaptable to new threats.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Secured Deployment Models</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We offer ongoing assistance with proactive measures like CI/CD pipelines using Bitbucket and Git pipeline to streamline updates.
                    </p>
                  </div>
                </div>
              </div>


            </div>
            <div className="row margin-catg-top">

              <div className="col-md-6">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Testing with Integrated Security Controls</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Our rigorous testing includes AWS RDS for reliable data storage and AWS SNS for secure notifications, ensuring a seamless user experience.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="serv-categ-box">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={mobilityimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Digital- Centric Development for Schools</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We develop incorporating cloud database solutions to safely store and manage sensitive data.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service third section end */}

      {/* service fourth section start */}
      <div className="home-service-sec differ-service-sec vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="service-img-right servce-img-left">
                <div className="vision-image-bg sev-main-bg">
                  <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg">
                    <img src={bgimagedots} alt="bg-icon" />
                  </div>
                </div>

                <div className="serv-img-inner servleft-img-inner">
                  <img src={visionimg} />
                </div>

              </div>
            </div>
            <div className="col-md-6">
              <div className="differ-service-inner left-side-head-before">

                <h2>Secured Infrastructure Rollout & Support for Educational Institutions </h2>
                <p>Our robust methodology services are fit for schools whether you want to improve performance or bolster security.</p>

              </div>

            </div>

          </div>
          <div className="service-inner-category">
            <div className="row">
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgone} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Enhanced Security & Deployment</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Prioritizing the protection of digital tools and sensitive information to prevent unauthorized access.  </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgthree} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Maintenance & Upgrades</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>We provide regular system updates and improvements that evolve alongside emerging threats and vulnerabilities.</p>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="row margin-catg-top">
            <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgtwo} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Agile Work Model</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>Ensures timely resolution of issues while maintaining a strong security posture through continuous monitoring and assessment.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="serv-categ-box serv-categ-box-grey">
                  <div className="serv-categ-main">
                    <div className="serv-categ-icon">
                      <img src={supportimgfour} alt="service-icon" />
                    </div>
                    <div className="serv-categ-head">
                      <h3>Our Exclusive Support</h3>
                    </div>
                  </div>
                  <div className="serv-categ-para">
                    <p>With proactive monitoring and troubleshooting, ensuring your infrastructure is fortified against potential risks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service fourth section end */}

      {/* proof of concept section start here */}


      <div className="proof-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="prrof-img">
                <div className="proof-content-sec">
                  <img src={teamimage} />
                  <div className="tem-img-overlay"></div>
                  <div className="team-content">
                    <h3>Got a challenge brewing?  </h3>
                    <h4>Get a free “Proof of Concept” delivered in 72 hrs</h4>
                    <div className="button-sec red-button-sec servicebtn-prof">

                      {/* <TriggerShadow /> */}
                      {/* <TriggerEdge /> */}
                      <a href="#" class="btn-inner-sec btn-red-sec">
                        <span className="btn-name">Send Your Requirements Here</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* proof of concept section end here */}

      <Footer />


    </>
  )
}

export default Services;
