import React from "react";
import Header from "./headerpre";
import Footer from "./footerpre";
import videoplayimg from "../assets/img/icon-play.png";
import bannerimg from "../assets/img/desktop-file-psd.png";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import Carousel from 'react-bootstrap/Carousel';
import projctimage from '../assets/img/eform-img.jpg';
import keyfeatureone from '../assets/img/automated_data_validation_blue.png';
import keyfeaturetwo from '../assets/img/efficoent.png';
import keyfeaturethree from '../assets/img/user-friendly_icon_blue.png';
import keyfeaturefour from '../assets/img/report_blue.png';
import EastIcon from '@mui/icons-material/East';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';


import keyfeatureoneWMS from '../assets/img/wms-details-icon/centralized_training_infor_blue.png';
import keyfeaturetwoWMS from '../assets/img/wms-details-icon/automated_reminder_blue.png';
import keyfeaturethreeWMS from '../assets/img/wms-details-icon/simplified_registration_blue.png';
import keyfeaturefourWMS from '../assets/img/wms-details-icon/session.png';
import keyfeatureoneABSENCE from '../assets/img/absence-report-details-icons/Integrated Leave Bank_blue.png';
import keyfeaturetwoABSENCE from '../assets/img/absence-report-details-icons/Digitized Leave Application_blue.png';
import keyfeaturethreeABSENCE from '../assets/img/absence-report-details-icons/Automated Approval Workflows blue.png';
import keyfeaturefourABSENCE from '../assets/img/absence-report-details-icons/Generate relevant reports_blue.png';
import keyfeatureoneINVENTORY from '../assets/img/inventory-details-icons/Real_Time_Asset_Tracking_blue.png';
import keyfeaturetwoINVENTORY from '../assets/img/inventory-details-icons/Increased Operational Efficiency_blue.png';
import keyfeaturethreeINVENTORY from '../assets/img/inventory-details-icons/Intuitive_Design_blue.png';
import keyfeaturefourINVENTORY from '../assets/img/inventory-details-icons/Real_Time_Asset_Tracking_blue.png';


import keyfeatureoneaibot from '../assets/img/ai-chatbot-details-icon/comprehensive.png';
import keyfeaturetwoaibot from '../assets/img/ai-chatbot-details-icon/Simplified_booking_process_blue.png';
import keyfeaturethreeaibot from '../assets/img/ai-chatbot-details-icon/Real-Time_Availability_Updates_blue.png';
import keyfeaturefouraibot from '../assets/img/ai-chatbot-details-icon/a_handy_interface_blue.png';

import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const Productdetails = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { slug } = useParams();
    return (
        <>
            <div className="main-bg-sec">
                <Header />
                {slug === 'enterprise-resource-planning' &&
                    <>
                        <div className="main-sec main-innerbanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>ERP for <span>Progressive Schools</span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">Watch the Product in Action</a>
                                                <div className="play-sec-prdt">
                                                    <a id="play-video" class="video-play-button" href="#">
                                                        <img src={videoplayimg} />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={bannerimg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {slug === 'ai-powered-chatbot' &&
                    <>
                        <div className="main-sec main-innerbanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>An Adaptable AI Chatbot  <span>Assistant for Your School Requirements</span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">Watch the Product in Action</a>
                                                <div className="play-sec-prdt">
                                                    <a id="play-video" class="video-play-button" href="#">
                                                        <img src={videoplayimg} />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={bannerimg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {slug === 'workshop-management-system' &&
                    <>
                        <div className="main-sec main-innerbanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>Dynamic Workshop <span>Management System for Your School</span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">Watch the Product in Action</a>
                                                <div className="play-sec-prdt">
                                                    <a id="play-video" class="video-play-button" href="#">
                                                        <img src={videoplayimg} />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={bannerimg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {slug === 'absence-reporting-portal' &&
                    <>
                        <div className="main-sec main-innerbanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>A Portal With Effortless  <span>Absent Reporting For Your School Staff</span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">Watch the Product in Action</a>
                                                <div className="play-sec-prdt">
                                                    <a id="play-video" class="video-play-button" href="#">
                                                        <img src={videoplayimg} />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={bannerimg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {slug === 'inventory-management-system' &&
                    <>
                        <div className="main-sec main-innerbanner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="banner-sec-content main-banner-sec prodt-details">
                                            <h1>A Centralized Hub for  <span>Managing School Inventory</span></h1>
                                            <div className="prodt-detail-main">
                                                <a className="watch-sec">Watch the Product in Action</a>
                                                <div className="play-sec-prdt">
                                                    <a id="play-video" class="video-play-button" href="#">
                                                        <img src={videoplayimg} />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prodtbannerimg">
                                            <img src={bannerimg} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            {slug === 'enterprise-resource-planning' &&
                <>
                    {/* key process section start */}
                    <div className="keyprocs-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-content">
                                        <h2>Speed Up Your Key Processes In Schools Digitally </h2>
                                        <p>  Our easy to use ERP System simplifies processes for more informed decision taking</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key process section end */}

                    {/* carousal sec start */}
                    <div className="product-sec detail-carousl-sec">
                        <div className="bg-icon-service">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="carousal-img">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item interval={500}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="carousl-details-content">
                                        <h2>Optimize and validate your tasks rightly with our progressive ERP</h2>
                                        <p>Tackle crucial school administrative tasks that could be payroll processes, budget approvals, or leave requests piling up; reduce the complexity by automating them with clear tracking of your workflows.    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-icon-belowservice">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                    </div>
                    {/* carousal sec end */}

                    {/* key feature section start */}
                    <div className="keyprocs-feturesec">
                        {/* <div className="keyfeatre-container"> */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-heading">
                                        <h2>Effective Key Features</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="keyfeature-inner">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeatureone} alt="keyfeature" />
                                                    <h3>Automated Data Validation</h3>
                                                    <p>Ensure accuracy with features that validate information, reducing manual input and errors.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturefour} alt="keyfeature" />
                                                    <h3>Management Information System</h3>
                                                    <p>Generate Insightful reports to understand how efficiently the your school is progressing.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturetwo} alt="keyfeature" />
                                                    <h3>Approvers with Stakeholders</h3>
                                                    <p>Instantly notifies approvers via automated emails, streamlining the approval process and minimizing delays.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturethree} alt="keyfeature" />
                                                    <h3>User-Friendly Interface</h3>
                                                    <p>Designed for ease of use, making it accessible for everyone in your school.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key feature section end */}

                    {/* impact section start */}
                    <div className="impact-sec">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Impacts That Are Tangible</h2>
                                </div>
                            </div>
                            <div className="imapct-inner-sec">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>0%</h3>
                                            <p>Error</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box impact-box-center">
                                            <h3>100%</h3>
                                            <p>Uptime</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>75%</h3>
                                            <p>Faster Approval Cycles</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* impact section end */}
                    {/* call to action section start */}
                    <div className="callactn-sec  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="callactn-content">
                                        <p>Schedule a demo to see how our product can enhance school key processes.</p>

                                        <div className="button-sec red-button-sec cal-btn-sec">
                                            <a href="#" class="btn-inner-sec btn-red-sec" >
                                                <span className="btn-name">  Book a Free Demo</span>
                                                <span className="btn-icon"><EastIcon /></span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {slug === 'ai-powered-chatbot' &&
                <>
                    {/* key process section start */}
                    <div className="keyprocs-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-content">
                                        <h2>A Chatbot That Fosters Relevant Communication Within Your School Community </h2>
                                        <p>  Our customizable chatbot enhances engagement and ensures that your school feels connected and well-informed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key process section end */}

                    {/* carousal sec start */}
                    <div className="product-sec detail-carousl-sec">
                        <div className="bg-icon-service">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="carousal-img">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item interval={500}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="carousl-details-content">
                                        <h2>Our AI bot is dedicated to delivering needed information when they interact.</h2>
                                        <p>Improve responsiveness and accessibility with our chatbot that can answer multiple queries by giving clarified answers accordingly.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-icon-belowservice">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                    </div>
                    {/* carousal sec end */}

                    {/* key feature section start */}
                    <div className="keyprocs-feturesec">
                        {/* <div className="keyfeatre-container"> */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-heading">
                                        <h2>Effective Key Features</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="keyfeature-inner">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeatureoneaibot} alt="keyfeature" />
                                                    <h3>Intent-Based Response with NLP</h3>
                                                    <p>Utilize powerful (NLP) Natural Language Processing to enable natural and effective interactions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturetwoaibot} alt="keyfeature" />
                                                    <h3>Voice Command Facilitation</h3>
                                                    <p>Enable advanced speech recognition capabilities, allowing users to engage through voice commands.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturethreeaibot} alt="keyfeature" />
                                                    <h3>Conversational AI with Personalized Responses</h3>
                                                    <p>avigate simple to complex questions fluidly enhancing personalized interactions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturefouraibot} alt="keyfeature" />
                                                    <h3>Insightful Consultation for Tailored Development</h3>
                                                    <p>Conduct a comprehensive review of your communication needs, to inform chatbot design.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key feature section end */}

                    {/* impact section start */}
                    <div className="impact-sec">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Impacts That Are Tangible</h2>
                                </div>
                            </div>
                            <div className="imapct-inner-sec">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>100%</h3>
                                            <p>Improved Communication Efficiency</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box impact-box-center">
                                            <h3>100%</h3>
                                            <p>Increased User Satisfaction</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>100%</h3>
                                            <p>Quick Accessibility to Information</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* impact section end */}
                    {/* call to action section start */}
                    <div className="callactn-sec  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="callactn-content">
                                        <p>See how our versatile chatbot assists within your school</p>

                                        <div className="button-sec red-button-sec cal-btn-sec">
                                            <a href="#" class="btn-inner-sec btn-red-sec">
                                                <span className="btn-name">  Book a Free Demo</span>
                                                <span className="btn-icon"><EastIcon /></span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {slug === 'workshop-management-system' &&
                <>
                    {/* key process section start */}
                    <div className="keyprocs-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-content">
                                        <h2>Streamline Your Training Announcements by Publishing All Workshops Timely </h2>
                                        <p> Transform your trainings with our structured digital system that seamlessly combines workshop publishing and timely announcements with an efficient notification system.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key process section end */}

                    {/* carousal sec start */}
                    <div className="product-sec detail-carousl-sec">
                        <div className="bg-icon-service">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="carousal-img">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item interval={500}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="carousl-details-content">
                                        <h2>Centralize All Your School Training Workshops Efficiently</h2>
                                        <p>Coordinate your workflows by centralizing all your granular details about each training session by fostering active participation and engagement.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-icon-belowservice">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                    </div>
                    {/* carousal sec end */}

                    {/* key feature section start */}
                    <div className="keyprocs-feturesec">
                        {/* <div className="keyfeatre-container"> */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-heading">
                                        <h2>Effective Key Features</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="keyfeature-inner">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeatureoneWMS} alt="keyfeature" />
                                                    <h3>Centralized Training Announcements</h3>
                                                    <p>Access all training materials & schedules from one unified platform, making it easy to find what you need.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturetwoWMS} alt="keyfeature" />
                                                    <h3>Automated Reminders</h3>
                                                    <p>Stay updated with timely email notifications, ensuring staff never miss important training sessions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturethreeWMS} alt="keyfeature" />
                                                    <h3>Simplified Registrations</h3>
                                                    <p>User-centric registration experience for easy enrollment in sessions, and managing your schedules.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturefourWMS} alt="keyfeature" />
                                                    <h3>Efficient Session Management</h3>
                                                    <p>Organize multiple sessions seamlessly with optimized participation by receiving the training you need.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key feature section end */}

                    {/* impact section start */}
                    <div className="impact-sec">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Impacts That Are Tangible</h2>
                                </div>
                            </div>
                            <div className="imapct-inner-sec">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>100%</h3>
                                            <p>Active Employee Participation</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box impact-box-center">
                                            <h3>100%</h3>
                                            <p>Trouble-Free Registration Processes</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>90%</h3>
                                            <p>Overall Internal Communication</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* impact section end */}
                    {/* call to action section start */}
                    <div className="callactn-sec  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="callactn-content">
                                        <p>Learn how you can optimize your workshops with our system</p>

                                        <div className="button-sec red-button-sec cal-btn-sec">
                                            <a href="#" class="btn-inner-sec btn-red-sec" >
                                                <span className="btn-name">  Book a Free Demo</span>
                                                <span className="btn-icon"><EastIcon /></span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {slug === 'absence-reporting-portal' &&
                <>
                    {/* key process section start */}
                    <div className="keyprocs-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-content">
                                        <h2>A Complete Tracking of Employee Absences With Leave Management </h2>
                                        <p>Within a single dashboard gain more insights of how you can manage staff absences, enable tracking of leave balances by simplifying the approval process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key process section end */}

                    {/* carousal sec start */}
                    <div className="product-sec detail-carousl-sec">
                        <div className="bg-icon-service">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="carousal-img">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item interval={500}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="carousl-details-content">
                                        <h2>Optimize all types of leaves with detail insights</h2>
                                        <p>With our portal staff can seamlessly indicate when they will be absent. They can track their leave balances, submit requests digitally, and receive timely approvals.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-icon-belowservice">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                    </div>
                    {/* carousal sec end */}

                    {/* key feature section start */}
                    <div className="keyprocs-feturesec">
                        {/* <div className="keyfeatre-container"> */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-heading">
                                        <h2>Effective Key Features</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="keyfeature-inner">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeatureoneABSENCE} alt="keyfeature" />
                                                    <h3>Integrated Leave Bank</h3>
                                                    <p>Staff can easily track their leave balances across various categories with informed entitlements.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturetwoABSENCE} alt="keyfeature" />
                                                    <h3>Digitized Leave Application</h3>
                                                    <p>Submit leave requests digitally, reducing paperwork with accurate submissions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturethreeABSENCE} alt="keyfeature" />
                                                    <h3>Automated Approval Workflows</h3>
                                                    <p>Leave requests are automatically assigned to appropriate supervisors to streamline approval process.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturefourABSENCE} alt="keyfeature" />
                                                    <h3>Generate Relevant Reports</h3>
                                                    <p>Enhanced Reporting on leave usage and trends to better manage staffing needs to prevent disruptions.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key feature section end */}

                    {/* impact section start */}
                    <div className="impact-sec">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Impacts That Are Tangible</h2>
                                </div>
                            </div>
                            <div className="imapct-inner-sec">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>90%</h3>
                                            <p>Reduced Administrative Workload</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box impact-box-center">
                                            <h3>100%</h3>
                                            <p>Faster Leavem Approval Times</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>80%</h3>
                                            <p>Transparency in Leave Balances</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* impact section end */}
                    {/* call to action section start */}
                    <div className="callactn-sec  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="callactn-content">
                                        <p>To experience our Absence Reporting Portal</p>

                                        <div className="button-sec red-button-sec cal-btn-sec">
                                            <a href="#" class="btn-inner-sec btn-red-sec" >
                                                <span className="btn-name">  Book a Free Demo</span>
                                                <span className="btn-icon"><EastIcon /></span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {slug === 'inventory-management-system' &&
                <>
                    {/* key process section start */}
                    <div className="keyprocs-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-content">
                                        <h2>Stay Ahead of Demand Changes & Ensure Accurate Stock Levels </h2>
                                        <p>A proficient inventory tracking system across your school community to monitor the movement & location of the inventory optimally</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key process section end */}

                    {/* carousal sec start */}
                    <div className="product-sec detail-carousl-sec">
                        <div className="bg-icon-service">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="carousal-img">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item interval={500}>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div>
                                                    <img src={projctimage} alt="project-image" />
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="carousl-details-content">
                                        <h2>Find the right inventory in your school and customize it with ease</h2>
                                        <p>With our unified platform manage multi-locational operations, tracking all stock and providing updates related to stock balances.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-icon-belowservice">
                            <img src={bgimagedots} alt="bg-icon" />
                        </div>
                    </div>
                    {/* carousal sec end */}

                    {/* key feature section start */}
                    <div className="keyprocs-feturesec">
                        {/* <div className="keyfeatre-container"> */}

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="keyprocs-heading">
                                        <h2>Effective Key Features</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="keyfeature-inner">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeatureoneINVENTORY} alt="keyfeature" />
                                                    <h3>Real-Time Asset Tracking</h3>
                                                    <p>Monitor the status and location of all assets, ensuring you always know where your resources are.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturetwoINVENTORY} alt="keyfeature" />
                                                    <h3>Increased Operational Efficiency</h3>
                                                    <p>Streamlined processes enhance resource management overall.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturethreeINVENTORY} alt="keyfeature" />
                                                    <h3>Intuitive Design</h3>
                                                    <p>Unified design that simplifies the tracking process for staff to manage inventory</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="keyfeature-box">
                                            <div className="keyfetur-inner">
                                                <div className="keyfeatre-inn-sec">
                                                    <img src={keyfeaturefourINVENTORY} alt="keyfeature" />
                                                    <h3>Proactive Alerts</h3>
                                                    <p>Receive automatic updates on stock levels and asset conditions, allowing for active management.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* key feature section end */}

                    {/* impact section start */}
                    <div className="impact-sec">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Impacts That Are Tangible</h2>
                                </div>
                            </div>
                            <div className="imapct-inner-sec">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>100%</h3>
                                            <p>Reduction in Asset Loss</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box impact-box-center">
                                            <h3>100%</h3>
                                            <p>Extended Lifespan of Key Assets</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="impact-box">
                                            <h3>100%</h3>
                                            <p>Structured decision-making</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* impact section end */}
                    {/* call to action section start */}
                    <div className="callactn-sec  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="callactn-content">
                                        <p>Streamline the asset tracking process and reduce asset loss in your school.</p>

                                        <div className="button-sec red-button-sec cal-btn-sec">
                                            <a href="#" class="btn-inner-sec btn-red-sec" >
                                                <span className="btn-name">  Book a Free Demo</span>
                                                <span className="btn-icon"><EastIcon /></span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {/* call to action section end */}
            <Footer />

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Modal title
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </Typography>
                    <Typography gutterBottom>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                        Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                    </Typography>
                    <Typography gutterBottom>
                        Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
                        magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
                        ullamcorper nulla non metus auctor fringilla.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </>

    )
}

export default Productdetails;
