import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import filtericon from '../assets/img/filter.png';
import SearchIcon from '@mui/icons-material/Search';
import blogimg from '../assets/img/blog-img.png';
import calngicon from '../assets/img/calendar.png';
import EastIcon from '@mui/icons-material/East';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";


import { useSelector } from "react-redux";



import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";


import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



function dateformat(datwene) {
  const inputDate = datwene;
  const date = new Date(inputDate);

  // Format the date as "Month DD, YYYY"
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC' // Ensures consistent output in UTC
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;

}
function truncateText(text, limit = 100) {
  console.log(text.length);
  return text.length > limit ? text.slice(0, limit) + "..." : text;
}

const Blog = () => {
  const { blogData } = useSelector((state) => state.pageContents);
  console.log(blogData);

  // Filter blogs based on the search query

  // State to store visible blogs, search query, and the number of visible blogs
  const [searchQuery, setSearchQuery] = useState('');
  const [visibleCount, setVisibleCount] = useState(4);
  const [filteredBlogs, setFilteredBlogs] = useState(blogData.blogs);

  // Filter blogs based on the search query
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredBlogs(blogData.blogs);
    } else {
      setFilteredBlogs(
        blogData.blogs.filter((blog) =>
          blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          blog.blog_category.category_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          blog.short_des.toLowerCase().includes(searchQuery.toLowerCase()) ||

          blog.blog_details.description.toLowerCase().includes(searchQuery.toLowerCase())

        )
      );
    }
  }, [searchQuery]);

  // Handle input change for search
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle load more
  const loadMore = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 4, filteredBlogs.length));
  };

  // Handle backspace to clear search
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && searchQuery === '') {
      setFilteredBlogs(blogData.blogs);
      setVisibleCount(4);
    }
  };
  const [isOpencat, setIsOpencat] = useState(false)

  const handleDialogChangecat = (isOpen) => {
    setIsOpencat(true);

  }

  const handleDialogChangecatclose = () => {
    setIsOpencat(false);

  }

  const [selectedCategories, setSelectedCategories] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    console.log(selectedCategories);
    // If checked, add to selectedCategories array, else remove it
    setSelectedCategories((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((category) => category !== value);
      }
    });
  };

  const checkcat = () => {
    if (selectedCategories.length == 0) {
      setFilteredBlogs(blogData.blogs);
      setIsOpencat(false);
    } else {
      setFilteredBlogs(
        blogData.blogs.filter((blog) =>
          selectedCategories.some(query =>

            blog.blog_category.category_name.toLowerCase().includes(query.toLowerCase())
          )
        )

      );
      setIsOpencat(false);
    }
  };


  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen)
  }




  const recaptchaRef = React.useRef();
  let navigate = useNavigate();
  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'getintouch',

    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);
 

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'getintouch';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);
 
  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          if (pageselect == 'getintouch') {
            // navigate('/thankyou/talktoleadership');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

  // // Determine what blogs to display
  // const displayBlogs = searchQuery ? filteredBlogs : blogData.blogs;
  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner innerbannerimg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-sec-content main-banner-sec new-img-baner">
                  {
                    blogData?.blogbanner &&
                    <>
                      <h1>{blogData.blogbanner.title} </h1>
                      <p>{blogData.blogbanner.subtitle}</p>
                    </>
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="banner-inner-img new-banr-img">
                  {
                    blogData?.blogbanner &&
                    <>
                      <img src={process.env.REACT_APP_IMAGE_URL + blogData.blogbanner.banner_image} alt="homeimg" />
                    </>
                  }

                </div>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec">
                {
                  blogData?.blogbanner &&
                  <>
                    <h1>{blogData.blogbanner.title} </h1>
                    <p>{blogData.blogbanner.subtitle}</p>
                  </>
                }
              </div>
            </div> */}

          </div>
        </div>
      </div>

      {/* blog section start */}
      <div className="home-service-sec about-innov-main blog-list">
        <div className="bg-icon-service">
          <img src={bgimagedots} alt="bg-icon" />
        </div>
        <div className="container-width-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-inner-sec">
                  <div className="blog-category">
                    <p>All Category
                      <img src={filtericon} onClick={handleDialogChangecat} />


                    </p>
                  </div>
                  <div className="blog-search">
                    <span><SearchIcon /></span><input type="text" placeholder="Search" className="input-srch"
                      value={searchQuery}

                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown} />
                  </div>
                </div>
              </div>
            </div>

            <div className="blog-list-inner">
              {filteredBlogs.slice(0, visibleCount).reduce((rows, item, index) => {
                // Create a new row every 2 items
                if (index % 2 === 0) {
                  rows.push([]); // Add a new row every two items
                }
                rows[rows.length - 1].push(item);
                return rows;
              }, []).map((rowItems, rowIndex) => (
                // Apply the 'margin cat top' class to every second row
                <div
                  className={`row ${rowIndex === 0 ? "" : "margin-blog-top"}`}
                  key={rowIndex}>
                  {rowItems.map((item, indexc) => (
                    <div className="col-md-6">
                      <div className="blog-list-sec">
                        <img src={process.env.REACT_APP_IMAGE_URL + item.image} />
                        <div className="blog-new-list">
                          <div className="blog-impor-sec">
                            <div className="blog-date">
                              <a>
                                <img src={calngicon} /><span>{dateformat(item.post_date)}</span>
                              </a>
                            </div>
                            <div className="blog-category">
                              <p>{item.blog_category.category_name} </p>
                            </div>
                          </div>
                          <div className="blog-list-content">
                            <h2>{item.title} </h2>
                            <p>
                              {/* {item.short_des} */}
                              {truncateText(item.short_des)}
                            </p>
                          </div>
                          {/* {item.blog_details != null  &&
                          <div className="button-sec btn-align btn-learn">
                          <Link  to={`/blogdetails/${item.slug}`} href="#" class="btn-inner-sec btn-inner-lern">
                            <span className="btn-name">Learn More</span>
                            <span className="btn-icon"><EastIcon /></span>
                          </Link >
                        </div>
                          } */}
                          {item.blog_details != null &&
                            <div className="button-sec btn-align btn-blog-blue btn-flex">
                              <Link to={`/blogdetails/${item.slug}`} href="#" class="btn-inner-sec">
                                <span className="btn-name">Read Post</span>
                                <span className="btn-icon"><EastIcon /></span>
                              </Link>
                              <div class="rating-time">
                              <p className="rating-clock"><AccessTimeIcon /><span>{item.time} min read</span></p>
                              </div>
                              <div class="rating">
                                {/* <p>Rate this Blog:</p> */}
                                <p className="like-count">
                                {item.bloglike}</p>
                                <div class="like grow">
                                  <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                                </div>

                                <div class="dislike grow">
                                  <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>

                          }
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              ))}

              {/* <div className="row">
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>AI-Driven Business Processes</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>AI interventions Should Be Central to Your Business</h2>
                        <p>
                          Through careful execution, it can offer smarter ways to run processes better and faster
                          through continuous learning and improvement, creating sustainable growth.
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Customer Journey Optimization</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Chatbots Today Enhance the Customer Journey</h2>
                        <p>
                          Businesses can escalate customer engagement with short resolution times and streamline interactive processes through the use of chatbots.
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row margin-blog-top">
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Business Process Transformation</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Technology Influence Can Foster Exponential Business Growth</h2>
                        <p>
                          The transformative role of technology in different parts of running a business brings streamlined operations and process optimization

                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blog-list-sec">
                    <img src={blogimg} />
                    <div className="blog-new-list">
                      <div className="blog-impor-sec">
                        <div className="blog-date">
                          <a>
                            <img src={calngicon} /><span>December 04, 2024</span>
                          </a>
                        </div>
                        <div className="blog-category">
                          <p>Business Process Transformation</p>
                        </div>
                      </div>
                      <div className="blog-list-content">
                        <h2>Technology Influence Can Foster Exponential Business Growth</h2>
                        <p>
                          The transformative role of technology in different parts of running a business brings streamlined operations and process optimization
                        </p>
                      </div>
                      <div className="button-sec btn-align btn-blog-blue">
                        <a href="#" class="btn-inner-sec">
                          <span className="btn-name">Read Post</span>
                          <span className="btn-icon"><EastIcon /></span>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
              {visibleCount < filteredBlogs.length && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="button-sec btn-align load-btn">
                      <a href="javascript:void(0)" class="btn-inner-sec" onClick={loadMore}>
                        <span className="btn-name">Load More</span>
                        <span className="btn-icon"><EastIcon /></span>
                      </a>
                    </div>
                  </div>
                </div>

              )}

            </div>

          </div>
        </div>

        <div className="bg-icon-belowservice">
          <img src={bgimagedots} alt="bg-icon" />
        </div>

      </div>
      {/* blog section end */}
      <Footer />


      {/* modal section start */}
      <BootstrapDialog
        onClose={handleDialogChangecatclose}
        aria-labelledby="customized-dialog-title"
        open={isOpencat} className="prodt-modl-news modl-blog-widh "
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogChangecatclose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>
          <div className=" ">
          <h2>All Category</h2>
          <div className="categfilt-sec">

            <Box className="category-box">
              {blogData.blogcategory.reduce((rows, item, index) => {
                // Create a new row every 2 items
                if (index % 2 === 0) {
                  rows.push([]); // Add a new row every two items
                }
                rows[rows.length - 1].push(item);
                return rows;
              }, []).map((rowItems, rowIndex) => (
                // Apply the 'margin cat top' class to every second row
                <>
                  <FormControl className={` ${rowIndex % 2 === 0 ? 'categy-left' : 'categy-right'}`} component="fieldset" variant="standard">
                    <FormGroup>

                      {rowItems.map(item => (
                        <FormControlLabel control={<Checkbox />} value={item.category_name} label={item.category_name}
                          onChange={handleCheckboxChange}
                          checked={selectedCategories.includes(item.category_name)}
                        />

                      ))}
                    </FormGroup>
                  </FormControl>
                </>

              ))}

            </Box>



          </div>
          <div className="button-sec btn-align btn-blog-blue mdl-btn modl-submit-cate">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={checkcat}>
              <span className="btn-name btn-sub">Submit</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>
          </div>
          </div>
        </DialogContent >

      </BootstrapDialog >
      {/* modal section end */}

      {/* modal section start */}
    
      {/* modal section end */}


      
    </>
  )
}

export default Blog;
