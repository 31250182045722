import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import blogimg from '../assets/img/blog-details-new.png';
import calngicon from '../assets/img/calendar.png';
import PersonIcon from '@mui/icons-material/Person';

import SearchIcon from '@mui/icons-material/Search';
import recentblogimg from '../assets/img/recent-blog-img.png';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import TextField from '@mui/material/TextField';
import secureLocalStorage from "react-secure-storage";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EastIcon from '@mui/icons-material/East';
import { isComingSoon, generateUniqueToken } from "../utils/helper";

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { setPageContent } from "../features/pageContentSlice";
import { useTransition, animated } from '@react-spring/web';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  addConnect,
  otpUpdate,
  blogupdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import filtericon from '../assets/img/filter.png';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function truncateText(text, limit = 1252) {
  console.log(text.length);
  return text.length > limit ? text.slice(0, limit) + "..." : text;
}

function dateformat(datwene) {
  const inputDate = datwene;
  const date = new Date(inputDate);

  // Format the date as "Month DD, YYYY"
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'UTC' // Ensures consistent output in UTC
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;

}


const Blogdetails = () => {
  const { blogData } = useSelector((state) => state.pageContents);
  const [allprodata, setAllprodata] = useState(null);
  const [slugname, setSlugnam] = useState(null);
  const [chcekallat, setChcekallat] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  // Handle input change for search
  const handleSearchChange = (e) => {
    localStorage.setItem("blogcat", e.target.value);
    setSearchQuery(e.target.value);
  };

  // console.log(productData);
  let navigate = useNavigate();
  const { slug } = useParams();
  // console.log(slug);
  useEffect(() => {
    console.log(slug);
    if (blogData.blogs.length > 0) {
      var proal = blogData.blogs;
      var res = proal.map((item, indexc) => {
        if (item.slug == slug) {
          console.log(item);
          setAllprodata(item);
        }


      });
    };



  }, []);
  useEffect(() => {
    console.log(slug);
    if (blogData.blogs.length > 0) {
      var proal = blogData.blogs;
      var res = proal.map((item, indexc) => {
        if (item.slug == slugname) {
          console.log(item);
          setAllprodata(item);
          navigate(`/blogdetails/${slugname}`);
        }


      });
    };



  }, [slugname]);
  const handleclickmenuback = (e, slug) => {
    setSlugnam(slug);
    // navigate(`/blogdetails/${slug}`);

  };
  const handleclickmenubackcat = (e, blogcat) => {
    localStorage.setItem("blogcat", blogcat);
    navigate(`/blogcategory`);

  };
  const handleclickmenubacksarcat = () => {

    navigate(`/blogcategory`);

  };
  const handlecheckart = () => {

    setChcekallat(true);

    // window.scrollTo(0, 1000);


  };

  // useEffect(() => {
  //   if (chcekallat && contentRef.current) {
  //     contentRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  //   }
  // }, [chcekallat]); 
  //
  const handlecheckartalt = () => {

    setChcekallat(false);

  };


  const [isOpen, setIsOpen] = useState(false)

  const handleDialogChange = (isOpen) => {
    setIsOpen(isOpen);
    var casedeat = { ...formValues };
    casedeat.casestudyname = allprodata.title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'bookdemoproject';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');



    var casedeat = { ...formValues };
    casedeat.casestudyname = allprodata.title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'blogdetails';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  }


  const transition = useTransition(isOpen, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  });




  const recaptchaRef = React.useRef();

  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'blogdetails',
    message: '',
    business_name: '',
    system: '',
    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);
  const transitionnew = useTransition(openotp, {
    from: {
      scale: 0,
      opacity: 0,
    },
    enter: {
      scale: 1,
      opacity: 1,
    },
    leave: {
      scale: 0,
      opacity: 0,
    },
  });

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenblogdetails = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = allprodata.title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.message = '';
    casedeat.business_name = '';
    casedeat.system = '';
    casedeat.pagename = 'blogdetails';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOtpin({
              otpIn_first: "",
              otpIn_secon: "",
              otpIn_thir: "",
              otpIn_forth: "",
              otpIn_fifth: "",
              otpIn_six: "",
              error_first: 1,
              error_secon: 1,
              error_thir: 1,
              error_forth: 1,
              error_fifth: 1,
              error_six: 1,
              signIn_err: '0',
              signIn_mesage: '',
              status: 'fail',
              id: ''
            });
            setIsOpen(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname'

        && key !== 'business_name' && key !== 'system'
      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      } else if (formValues.contact_us == 'enterprise' && key === 'business_name' && !formValues[key]) {
        isValid = false;
        errors[key] = 'This field is required';
      }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
      handleSubmitotp();
    }

  }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          // setOpen(true);
          navigate('/thankyou/blog');
          setTimeout(() => {

            // setOpen(false);
          }, 3000);

          if (pageselect == 'blogdetails') {
            // navigate('/thankyou/talktoleadership');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }




  const handleDialogOffChange = (isOpen) => {
    setIsOpen(false)
  }

  const formFieldsLike = {
    name: '',
    email: '',
    phone: '',
    blog_id: '',
    blog_name: '',
    reason: '',
    blogtype: '',



  }
  const [isOpenLike, setIsOpenLike] = useState(false)
  const [FormValuesLike, setFormValuesLike] = React.useState(formFieldsLike);
  const [formErrorsLike, setformErrorsLike] = React.useState(updateNullOfObjectValues(formFieldsLike));
  const [submittingLike, setSubmittingLike] = React.useState(false);
  const [successMessageLike, setSuccessMessageLike] = React.useState('');
  const [errorMessageLike, setErrorMessageLike] = React.useState('');
  const [isOpencat, setIsOpencat] = useState(false)
  const handleDialogChangeLike = (isOpen, blogtype) => {
    setIsOpenLike(isOpen);
    console.log(allprodata);
    var casedeat = { ...FormValuesLike };
    casedeat.blog_name = allprodata.title;
    casedeat.blog_id = allprodata._id;
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.name = '';
    casedeat.reason = '';
    casedeat.blogtype = blogtype;

    setFormValuesLike(casedeat);
    setformErrorsLike(updateNullOfObjectValues(formFieldsLike));
    setErrorMessageLike('');
    setSuccessMessageLike('');



    var casedeat = { ...FormValuesLike };
    casedeat.blog_name = allprodata.title;
    casedeat.blog_id = allprodata._id;
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.name = '';
    casedeat.reason = '';
    casedeat.blogtype = blogtype;
    setFormValuesLike(casedeat);
  }


  const handleInputChangeLike = (e) => {
    const { name, value } = e.target;
    console.log(FormValuesLike);
    setFormValuesLike({ ...FormValuesLike, [name]: value });
    setformErrorsLike({ ...formErrorsLike, [name]: null });
  }

  const handleSubmitLike = (e) => {
    e.preventDefault();

    setSubmittingLike(false);

    console.log(validateFormLike());
    if (validateFormLike()) {
      setSubmittingLike(true);
      setErrorMessageLike('');
      setSuccessMessageLike('');
      let formData = { ...FormValuesLike };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      blogupdate(formData)
        .then((response) => {

          if (response.data.status) {
            // setotpid(response.data.lastid);
            //setSuccessMessageLike(response.data.message);
            setFormValuesLike(formFieldsLike);

            recaptchaRef.current.reset();
            dispatch(
              setPageContent({
                key: "blog",
                data: response.data.blog,
              })
            );
            //  alert(response.data.blog.blogs.length);
            console.log(response.data.blog);
            if (response.data.blog.blogs.length > 0) {
              var proal = response.data.blog.blogs;
              var res = proal.map((item, indexc) => {
                if (item.slug == slug) {
                  console.log(item);
                  setAllprodata(item);
                }


              });
            };
            setSubmittingLike(false);

            setIsOpenLike(false);

            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessageLike(response.data.message);
          }
        })
        .catch((error) => {
          setSubmittingLike(false);
          setErrorMessageLike('Something went wrong. Please try again later.');
        });
    }
  }

  const handleCloseLike = () => {
    setIsOpenLike(false);
  };
  const validateFormLike = () => {
    let isValid = true;
    let errors = {};
    Object.keys(FormValuesLike).forEach((key) => {
      if (key === 'email' && FormValuesLike.email != '' && !isEmail(FormValuesLike[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      else if (key === 'phone' && FormValuesLike.phone != '' && !isPhoneNumber(FormValuesLike[key])) {
        isValid = false;
        errors[key] = 'Invalid phone number';
      }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setformErrorsLike(errors);
    return isValid;
  }




  const handleKeypressparent = e => {

    //it triggers by pressing the enter key
    if (e.which === 13) {
      handleclickmenubacksarcat();

    }
  };
  const handleDialogChangecat = (isOpen) => {
    setIsOpencat(true);

  }

  const handleDialogChangecatclose = () => {
    setIsOpencat(false);

  }

  return (
    <>
      <div className="main-bg-sec">
        <Header />
        <div className="main-sec main-innerbanner main-detsila-two">
          <div className="container">
            <div className="col-lg-12">
              <div className="banner-sec-content main-banner-sec main-bannerinner">
                {allprodata != null &&
                  <h1>{allprodata.blog_details.banner_title} </h1>
                }
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* blog details section start for responsive show */}
      <div className="container-width-blog respon-filter-sec  blog-list">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="blog-inner-sec">
                <div className="blog-category">
                  <p>All Category
                    <img src={filtericon} onClick={handleDialogChangecat} />


                  </p>
                </div>
                <div className="blog-search">
                  <span><SearchIcon onClick={(e) => handleclickmenubacksarcat()} /></span><input type="text" placeholder="Search" className="input-srch"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleKeypressparent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* blog details section end for responsive end */}

      {/* blog details section start */}
      <div className="blog-details-sec home-service-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details-inner">

                {allprodata != null &&
                  <img src={process.env.REACT_APP_IMAGE_URL + allprodata.blog_details.details_page_image} />
                }

                <div className="blog-tag-details">
                  <div className="blog-tag-para-new">
                    <div className="blog-tag-para">
                      {allprodata != null &&
                        <p>{allprodata.blog_category.category_name} </p>
                      }

                    </div>

                  </div>


                  <div className="blog-detal-date">


                    {allprodata != null &&
                      <>
                        <img src={calngicon} /><a>{dateformat(allprodata.post_date)}</a>
                      </>

                    }



                  </div>

                </div>

                <div className="blog-detal-content">


                  {allprodata != null &&
                    <>
                      {/* {truncateText(allprodata.blog_details.description)} */}
                      {/* {chcekallat == false &&
                        <>

                          <div dangerouslySetInnerHTML={{ __html: truncateText(allprodata.blog_details.description) }}></div>
                          <div className="read-main-secsss">
                            <div className="read-secss">
                           
                              <div className="read-secss-inner">
                                <div className="button-sec btn-align">
                                  <Link class="btn-inner-sec" onClick={(e) => handlecheckart()}>
                                    <span className="btn-name">Read Full Article</span>
                                    <span className="btn-icon"><EastIcon /></span>
                                  </Link>
                                </div>

                              </div>
                            </div>
                          </div>
                        </>
                      } */}
                      {/* {chcekallat == true &&
                        <>
                          <div ref={contentRef} dangerouslySetInnerHTML={{ __html: (allprodata.blog_details.description) }}></div>
                         

                        </>
                      } */}



                      <div dangerouslySetInnerHTML={{ __html: chcekallat ? allprodata.blog_details.description : truncateText(allprodata.blog_details.description) }}></div>

                      <div ref={contentRef}></div> {/* Invisible element for scroll targeting */}


                      {!chcekallat && (
                        <div className="read-main-secsss">
                          <div className="read-secss">
                            {/* <a  onClick={(e) => handlecheckart()}>Read Full Article</a> */}
                            <div className="read-secss-inner">
                              <div className="button-sec btn-align">
                                <Link class="btn-inner-sec" onClick={(e) => handlecheckart()}>
                                  <span className="btn-name">Read Full Article</span>
                                  <span className="btn-icon"><EastIcon /></span>
                                </Link>
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </>

                  }


                  <div className="rate-admin-details-sec">

                    <div class="rating rating-blgdetals">
                      <p >Rate this Blog:</p>
                      <p className="like-count-details">
                        {allprodata != null &&
                          <>
                            {allprodata.bloglike}

                          </>

                        }
                      </p>

                      <div class="like grow" onClick={(e) => handleDialogChangeLike(true, 'like')}>
                        <i class="fa fa-thumbs-up fa-3x like" aria-hidden="true"></i>
                      </div>

                      <div class="dislike grow" onClick={(e) => handleDialogChangeLike(true, 'dislike')}>
                        <i class="fa fa-thumbs-down fa-3x dislike" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div className="admin-sec">

                      <p><PersonIcon />
                        <span>By: Admin</span>
                      </p>
                    </div>
                  </div>

                </div>


              </div>

              {/* call to action section start */}
              <div className="callactn-sec blog-detl-call ">
                <div className="callactn-content blog-detl-sec">
                  {allprodata != null &&
                    <>
                      <p>{allprodata.blog_details.quote}</p>

                    </>

                  }



                  <div className="button-sec red-button-sec cal-btn-sec blg-detl-btn">

                    <a href="#" class="btn-inner-sec btn-red-sec" onClick={handleDialogChange}>
                      <span className="btn-name">  Consult free</span>
                      <span className="btn-icon"><EastIcon /></span>
                    </a>
                  </div>

                </div>
              </div>
              {/* call to action section end */}


            </div>
            <div className="col-md-4">
              <div className="blog-detail-rights">
                <div className="blog-search">
                  <p>Search</p>
                  <div className="blog-search-box">
                    <input type="text" placeholder="Search" className="input-srch" value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeypressparent}
                    />
                    <span className="search-icon" onClick={(e) => handleclickmenubacksarcat()}><SearchIcon /></span>
                  </div>
                </div>
                <div className="detail-categ">
                  <p>Category</p>
                  <div className="categ-list">
                    <ul>
                      {
                        blogData?.blogcategory.length &&
                        <>
                          <ul>
                            {allprodata != null &&
                              <>  {blogData.blogcategory.map((category, index) => (
                                <li className={`${allprodata.blog_category.category_name == category.category_name && 'active'}`}>

                                  <a onClick={(e) => handleclickmenubackcat(e, (category.category_name))}>{category.category_name}</a>
                                </li>

                              ))}
                              </>
                            }

                          </ul>
                        </>
                      }
                    </ul>
                  </div>
                </div>
                <div className="recent-post">
                  <p>Recent Post</p>

                  <div className="recent-post-list">
                    {
                      blogData?.blogrecentdate.length &&
                      <>
                        <ul>
                          {blogData.blogrecentdate.map((recent, index) => (
                            <li >
                              <a onClick={(e) => handleclickmenuback(e, (recent.slug))}>
                                <img src={process.env.REACT_APP_IMAGE_URL + recent.image} />
                                <p>{recent.title}</p>
                              </a>


                            </li>
                          ))}
                        </ul>
                      </>
                    }




                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blog etails section end */}
      <Footer />

      {/* modal start here  */}


      <BootstrapDialog
        onClose={handleDialogOffChange}
        aria-labelledby="customized-dialog-title"
        open={isOpen} className="prodt-modl-news new-respon-case "
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogOffChange}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <h2>Consult Free</h2>
          <div className="form-sec-main">
            {/* <h2>Book a Free Demo</h2> */}
            <div className='form-sec form-modal form-radio-sec'>
              <FormControl>
                <FormLabel className="radio-sec-label">Are you an </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="individual" control={<Radio />} label="individual"
                    name="contact_us"
                    checked={formValues.contact_us == 'individual'}
                    onChange={handleInputChange}
                  />
                  <FormControlLabel value="enterprise" control={<Radio />} label="enterprise"
                    name="contact_us"
                    checked={formValues.contact_us == 'enterprise'}
                    onChange={handleInputChange}
                  />
                </RadioGroup>
              </FormControl>
              {
                formErrors.contact_us &&
                <div className="invalid-feedbackcontact">
                  <div className='error-msg'>
                    {formErrors.contact_us}
                  </div>
                </div>
              }
            </div>
            {formValues.contact_us == 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Business Name " variant="outlined"

                      name="business_name"
                      className={classNames("form-control", { 'is-invalid': formErrors.business_name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.business_name}


                    />
                    {
                      formErrors.business_name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.business_name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Work Email" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }

            {formValues.contact_us != 'enterprise' &&

              <>


                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                      name="name"
                      className={classNames("form-control", { 'is-invalid': formErrors.name })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.name}


                    />
                    {
                      formErrors.name &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.name}
                        </div>
                      </div>
                    }
                  </Box>
                </div>



                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                      name="email"
                      className={classNames("form-control", { 'is-invalid': formErrors.email })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.email}

                    />
                    {
                      formErrors.email &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.email}
                        </div>
                      </div>
                    }
                  </Box>
                  <div className="email-verify-sec">
                    <p>This email will be verified</p>
                  </div>


                </div>
                <div className='form-sec form-modal'>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                      name="phone"
                      className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                      onChange={handleInputChange}
                      style={{ backgroundImage: 'none' }}
                      value={formValues.phone}
                    />
                    {
                      formErrors.phone &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrors.phone}
                        </div>
                      </div>
                    }
                    {/* <div className="email-verify-sec contact-veri">
                      <p>This number will be verified</p>
                    </div> */}
                  </Box>



                  <div>
                    <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                  </div>
                </div>

              </>

            }


            <div className='form-sec form-modal'>

              <Box
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                noValidate
                autoComplete="off"
              >

                <TextField
                  id="outlined-multiline-static"
                  label="Your Requirement in Brief"
                  multiline
                  rows={2}
                  placeholder=""
                  name="message"
                  className={classNames("form-control", { 'is-invalid': formErrors.message })}
                  onChange={handleInputChange}
                  style={{ backgroundImage: 'none' }}
                  value={formValues.message}
                />
                {
                  formErrors.message &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrors.message}
                    </div>
                  </div>
                }
              </Box>

            </div>








            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrors.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrors.recaptcha}
                  </div>
                </div>
              }
            </div>



          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
              <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>

          <div className='validate-sec validate-sec-mode'>
            {
              successMessage &&
              <div className="success-msg-sec">
                <p className="succ-msg">{successMessage}
                </p>
              </div>
            }
            {
              errorMessage &&
              <div className="error-msg-sec">
                <p className="error-msg">{errorMessage}
                </p>
              </div>
            }
          </div>

        </DialogContent >

      </BootstrapDialog >
      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="newotp-sec"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          OTP!


        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseotp}
          className="closebew-icon"
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          <div className="otp-content-sec">
            <p className="otp-head otp-bottom">Please check your spam or junk folder, </p><p className="otp-head">if your authentication code is not visible in your inbox
            </p>
          </div>
          <div className="form-sec-main otp-main-secss">

            <div className='form-sec form-modal'>
              <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
              </div>



            </div>
            <div className="button-sec btn-align mdl-btn btn-otp">
              <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitotp}>
                <span className="btn-name btn-sub">Submit     {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}</span>
                <span className="btn-icon"><EastIcon /></span>
              </a>
            </div>


            <div className='validate-sec validate-sec-mode width-otp'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >



      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="newotp-sec thanksuou"
      >



        <DialogContent dividers>
          <div class="success-animation">
            <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          </div>
          <h2>Thank You !!</h2>
          <p className="thanks-para">Your message is successfully delivered to the concerned team, they will do the needful soon</p>



        </DialogContent >

      </BootstrapDialog >

      <BootstrapDialog
        onClose={handleCloseLike}
        aria-labelledby="customized-dialog-title"
        open={isOpenLike} className="prodt-modl-news"
      >

        <IconButton
          aria-label="close"
          onClick={handleCloseLike}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <h2>Rate This Blog</h2>
          <div className="form-sec-main">






            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                  name="name"
                  className={classNames("form-control", { 'is-invalid': formErrorsLike.name })}
                  onChange={handleInputChangeLike}
                  style={{ backgroundImage: 'none' }}
                  value={FormValuesLike.name}


                />
                {
                  formErrorsLike.name &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrorsLike.name}
                    </div>
                  </div>
                }
              </Box>
            </div>



            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                  name="email"
                  className={classNames("form-control", { 'is-invalid': formErrorsLike.email })}
                  onChange={handleInputChangeLike}
                  style={{ backgroundImage: 'none' }}
                  value={FormValuesLike.email}

                />
                {
                  formErrorsLike.email &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrorsLike.email}
                    </div>
                  </div>
                }
              </Box>
              <div className="email-verify-sec">
                <p>This email will be verified</p>
              </div>


            </div>
            <div className='form-sec form-modal'>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >

                <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                  name="phone"
                  className={classNames("form-control", { 'is-invalid': formErrorsLike.phone })}
                  onChange={handleInputChangeLike}
                  style={{ backgroundImage: 'none' }}
                  value={FormValuesLike.phone}
                />
                {
                  formErrorsLike.phone &&
                  <div className="invalid-feedback">
                    <div className='error-msg'>
                      {formErrorsLike.phone}
                    </div>
                  </div>
                }
                {/* <div className="email-verify-sec contact-veri">
              <p>This number will be verified</p>
            </div> */}
              </Box>



              <div>
                <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
              </div>
            </div>



            {FormValuesLike.blogtype != 'like' &&

              <>


                <div className='form-sec form-modal'>

                  <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }}
                    noValidate
                    autoComplete="off"
                  >

                    <TextField
                      id="outlined-multiline-static"
                      label="Your Requirement in Brief"
                      multiline
                      rows={2}
                      placeholder=""
                      name="reason"
                      className={classNames("form-control", { 'is-invalid': formErrorsLike.reason })}
                      onChange={handleInputChangeLike}
                      style={{ backgroundImage: 'none' }}
                      value={FormValuesLike.reason}
                    />
                    {
                      formErrorsLike.reason &&
                      <div className="invalid-feedback">
                        <div className='error-msg'>
                          {formErrorsLike.reason}
                        </div>
                      </div>
                    }
                  </Box>

                </div>



              </>

            }









            <div className='form-sec form-modal'>

              <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
            </div>
            <div className='form-sec form-modal' >


              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
              />
              {
                formErrorsLike.recaptcha &&
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <div className='error-msg captch-error'>
                    {formErrorsLike.recaptcha}
                  </div>
                </div>
              }
            </div>



          </div>
          <div className="button-sec btn-align mdl-btn new-mdl-btn">
            <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmitLike}>
              <span className="btn-name btn-sub">Submit   {submittingLike && <div className="spinner-border spinner-border-sm loader-left" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>}</span>
              <span className="btn-icon"><EastIcon /></span>
            </a>
          </div>

          <div className='validate-sec validate-sec-mode'>
            {
              successMessageLike &&
              <div className="success-msg-sec">
                <p className="succ-msg">{successMessageLike}
                </p>
              </div>
            }
            {
              errorMessageLike &&
              <div className="error-msg-sec">
                <p className="error-msg">{errorMessageLike}
                </p>
              </div>
            }
          </div>

        </DialogContent >

      </BootstrapDialog >

      <BootstrapDialog
        onClose={handleDialogChangecatclose}
        aria-labelledby="customized-dialog-title"
        open={isOpencat} className="prodt-modl-news modl-blog-widh res-modl-blog"
      >

        <IconButton
          aria-label="close"
          onClick={handleDialogChangecatclose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          className="closebew-icon"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="blog-details-sec home-service-sec modalblog-categ">
            <div className="blog-detail-rights">
              <div className="detail-categ">
                <p>Category</p>
                <div className="categ-list">
                  <ul>
                    {
                      blogData?.blogcategory.length &&
                      <>
                        <ul>
                          {allprodata != null &&
                            <>  {blogData.blogcategory.map((category, index) => (
                              <li className={`${allprodata.blog_category.category_name == category.category_name && 'active'}`}>

                                <a onClick={(e) => handleclickmenubackcat(e, (category.category_name))}>{category.category_name}</a>
                              </li>

                            ))}
                            </>
                          }

                        </ul>
                      </>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </DialogContent >

      </BootstrapDialog >

      {/* modal end here */}

    </>
  )
}

export default Blogdetails;
